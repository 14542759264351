import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-regolamento',
  templateUrl: './regolamento.component.html',
  styleUrls: ['./regolamento.component.css']
})
export class RegolamentoComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private titleService : Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Accettazione Regolamento delle Iscrizioni')
  }

  onConferma() {
    this.router.navigate(['/iscrizioni'], {relativeTo: this.route});
  }

}
