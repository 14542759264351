import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Comunicazioni, Destinatario } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';


@Component({
  selector: 'app-edit-comunicazione-backoffice',
  templateUrl: './edit-comunicazione-backoffice.component.html',
  styleUrls: ['./edit-comunicazione-backoffice.component.css']
})
export class EditComunicazioneBackofficeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router : Router, private apiBackofficeService: ApiBackofficeService, private titleService: Title, private dialog: MatDialog) { }    
  commsForm!: FormGroup;

  repData! : Comunicazioni;  
  
  id!: number;  
  
  html!: string;
  tipo!: string;

  panelOpenState: boolean = false; 

  id_destinatari!: Array<string>;
  destinatari: Destinatario[] = [];

  ngOnInit(): void {

    this.titleService.setTitle('Alternativa Backoffice - Gestione Comunicazione');

    var params = this.route.params.subscribe(params => {      
      this.id = params['id'];
    });       

    this.commsForm = new FormGroup({      
      username: new FormControl(null),      
      html: new FormControl(null),
      oggetto: new FormControl(null),
      messaggio: new FormControl(null),        
    })

    this.apiBackofficeService.getComunicazione(this.id).subscribe(data => {
      this.repData = data;                   
               
      if (this.repData) {       
        
        this.html = this.repData.html;
        this.tipo = this.repData.tipo;       
                                           
        this.id_destinatari = this.repData.destinatari.split(';');
        
        this.id_destinatari.forEach((id, i) => {          
          this.apiBackofficeService.getIscritto(+id).subscribe(data => {            
            this.destinatari.push({id: data.id, nome: data.nome, cognome: data.cognome, email: data.email, cell: data.cell});            
          })          
        })

        this.commsForm.setValue({          
          username: this.repData.username,                        
          html: this.repData.html,
          oggetto: this.repData.oggetto,
          messaggio: this.repData.messaggio,                        
        })                               
                                        
      }

    })
  }

  onIscritto(id: number) {
    this.router.navigate(['../../iscritto/' + id], {relativeTo: this.route})
  }

  onHtml() {
    let child = window.open("about:blank","email", 'popup');    
    child?.document.write(this.html)
    child?.document.close();
  }

  onGoBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  openDialog(type: string) {
    let dialogRef;    
    
    if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);    
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }
}
