import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { AuthService } from 'src/app/shared/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {    

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private apiService: ApiService) { }

  repData? : {token: string};  
  repDataCheck! : {stato: string};
  repDataCheckEmail! : {id: number};

  success = true;
  error?: string;

  loginForm!: FormGroup;

  error_messages = {
    'email': [
      { type: 'required', message: 'Inserire una email per procedere con il login' },
      { type: 'minlength', message: 'Inserire minimo 6 caratteri.' },      
      { type: 'email', message: 'Inserire una email valida (ex. test@gmail.com)' }
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con il login' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.maxLength(30)])
    }) ;
  }
  
  onSubmit() {
    var email = this.loginForm.get('email')?.value;
    var password = this.loginForm.get('password')?.value;

    this.error = ''; 
    
    // Se non sono selezionati ne email e password non procedo
    if (email && password) {  

      this.apiService.checkEmailIscritto(email).subscribe(data => {     
        
        this.repDataCheckEmail = data;

        if(this.repDataCheckEmail) {
        
          // Recupero lo stato dell'iscritto
          this.apiService.checkStatoIscritto(email, password).subscribe(data => {        
            this.repDataCheck = data;

              if (this.repDataCheck) {

                  // Se lo stato è in attesa di conferma email non procedo
                if (this.repDataCheck.stato !== 'attesa_conferma') {

                    // Attuo la richiesta di login
                  this.authService.login(email, password).subscribe(data => {
                    this.repData = data;                    
                      // Se success redirect to pagina personale
                    if (this.repData.token) {                  
                      this.router.navigate(['area-personale'], {relativeTo: this.route});
                    } else {                  
                      this.error= 'Email o Password errati';
                    }
                  }, errorRes => {                         
                    this.error = errorRes.message;
                  })
                } else {              
                  this.error = "Per accedere all'Area Personale, completare l`ìscrizione tramite il link inviato via emaill durante la procedura d'iscrizione. (se non l'hai ricevuta controlla nello spam o nelle cartelle della tua casella di posta).";
                }
              
            } else {          
              this.error= 'Email o Password errati';
            }
          }, err => {
            this.error = err.message;
          })    
        } else {
          this.error = 'Non risulta nessuna iscrizione con questo indirizzo email, esegui la procedura di iscrizione tramite il bottone sottostante!';
        }
      }, err => {
        this.error = err.message;
      })  
    } else {      
      this.error = 'Inserire dei dati per accedere all`Area Personale';
    }
  }

  onIscrizione() {
    this.router.navigate(['/regolamento'], {relativeTo: this.route});
  }

}
