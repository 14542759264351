<div class="body">
    <div class="container-iscritti-front">
        <div class="row">
            <div class="regolamento col-xs-12">
                <h1>REGOLAMENTO<br><span style="color: #ef6c00;">ISCRIZIONE</span></h1>
                <br>
                <h4>Prima di iscriverti ti invitiamo a leggere ed accettare il regolamento</h4>
                <ul style="list-style: auto">
                    <li>La richiesta di iscrizione all'Associazione si effettua collegandosi al sito <a href="https://www.alternativa.it/">www.alternativa.it</a> ed entrando nell’apposita sezione “Iscrizioni”, ove si compila il modulo con le informazioni
                        necessarie.
                    </li>
                    <li>L'iscrizione è gratuita e ha durata annuale.</li>
                    <li>La richiesta di iscrizione è subordinata all’adesione ai principi e alle finalità dell’Associazione e alla conoscenza delle regole dello Statuto e dei Regolamenti.</li>
                    <li>Possono iscriversi ad Alternativa tutti i cittadini italiani, i cittadini di altro Stato membro dell’Unione europea e gli stranieri in regola con il permesso di soggiorno UE per soggiornanti di lungo periodo in corso di validità all’atto
                        dell’iscrizione residenti in Italia; possono essere iscritti coloro che abbiano compiuto il sedicesimo anno di età e che non abbiano interessi contrastanti con le finalità dell’Associazione al momento dell'iscrizione.</li>
                    <li>Con la richiesta di adesione, l’aspirante Associato accetta di essere registrato nell’anagrafe degli Associati, cioè il registro ove sono conservati i dati personali degli iscritti all’Associazione, e autorizza l’Associazione al trattamento
                        dei dati personali secondo quanto previsto dall’informativa presente sul sito e dalla normativa vigente in materia. I responsabili del trattamento dei suddetti dati sono il Comitato iscrizioni e i collaboratori dell’Associazione
                        delegati a tal fine.</li>
                    <li>L’Associato è consapevole e accetta che le comunicazioni di cui all’informativa privacy, inerenti le attività dell’Associazione, vengano recapitate all’indirizzo di posta elettronica fornito al momento dell’iscrizione oppure alla propria
                        utenza cellulare. L’indirizzo fornito all’Associazione non verrà utilizzato per comunicazioni relative ad altre finalità, es. attività dei singoli parlamentari.</li>
                    <li>Per iscriversi l’aspirante Associato dovrà indicare:</li>
                    <ul style="list-style: lower-alpha;">
                        <li>Nome e cognome;</li>
                        <li>Indirizzo di residenza e domicilio;</li>
                        <li>Professione;</li>
                        <li>Indirizzo Email;</li>
                        <li>Numero di telefono cellulare; </li>
                        <li>Eventuale numero di telefono fisso;</li>
                        <li>Codice fiscale;</li>
                        <li>Caricamento della Carta di Identità fronte/retro;</li>
                        <li>Auto-dichiarazione di precedenti iscrizioni a partiti, movimenti o associazioni politiche;</li>
                        <li>Auto-dichiarazione di precedenti mandati elettorali o nomine in assessorati o ministeri;</li>
                        <li>Auto-dichiarazione di non essere iscritto a società od associazioni segrete;</li>
                    </ul>
                    <li>Per revocare la propria iscrizione sarà necessario entrare nella sezione “iscrizioni” e seguire le istruzioni alla voce “revoca” oppure inviare una mail a <a href="mailto:iscrizioni@alternativa.it">iscrizioni@alternativa.it</a></li>
                    <li>I dati degli aspiranti associati, la cui domanda di iscrizione non è accettata dal Comitato iscrizioni, saranno immediatamente cancellati.</li>
                </ul>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="button-column">
                <div class="button-overlay">
                    <div class="button-container">
                        <button type="button" id="conferma" name="conferma" (click)="onConferma()" class="alternativa-css">CONFERMA</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>