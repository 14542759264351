import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-conferma-iscrizione-dialog',
  templateUrl: './conferma-iscrizione-dialog.component.html',
  styleUrls: ['./conferma-iscrizione-dialog.component.css']
})
export class ConfermaIscrizioneDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ConfermaIscrizioneDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  onCloseClick() {
    this.dialogRef.close(false)
  }

}
