import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Iscritto } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { AnnulloSendSmsBackofficeDialogComponent } from './annullo-send-sms-backoffice-dialog/annullo-send-sms-backoffice-dialog.component';
import { FormSendSmsBackofficeDialogComponent } from './form-send-sms-backoffice-dialog/form-send-sms-backoffice-dialog.component';
import { SmsIscrittoSendSmsBackofficeDialogComponent } from './sms-iscritto-send-sms-backoffice-dialog/sms-iscritto-send-sms-backoffice-dialog.component';


@Component({
  selector: 'app-send-sms-backoffice',
  templateUrl: './send-sms-backoffice.component.html',
  styleUrls: ['./send-sms-backoffice.component.css']
})
export class SendSmsBackofficeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private apiBackofficeService: ApiBackofficeService, private titleService: Title, private dialog : MatDialog) { }

  private dialogRef!: MatDialogRef<SmsIscrittoSendSmsBackofficeDialogComponent>

  formSMS!: FormGroup;
  iscrittiSelezionati: Array<number> = [];

  stop: boolean = false;
  stop_primo: boolean = false;    

  repData!: Iscritto;

  id!: number;

  numIscritti!: number;
  id_utente!: number;

  error_messages = {
    'messaggio': [
      { type: 'required', message: 'Inserire un messaggio per procedere con l"invio delle comunicazione via sms' },
    ]
  }

  ngOnInit(): void {
    this.titleService.setTitle('Alternativa Backoffice - Invio Comunicazioni SMS');


    var params = this.route.params.subscribe(params => {      
      this.id = params['id'];
      
      this.apiBackofficeService.getUserData().subscribe(user => {

        this.id_utente = user.id;

        if (!this.id) {

          this.iscrittiSelezionati = this.apiBackofficeService.retrieveIDs();      
        
          if (this.iscrittiSelezionati.length <= 0) {
            this.router.navigate(['..'], {relativeTo: this.route});
          }
        }
      })

    })


    this.formSMS = new FormGroup({
      messaggio: new FormControl(null, Validators.required)
    })
  }

  onGoBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  openDialog(type: string) {
    let dialogRef;

    this.dialog.closeAll(); 
    
    if (type === 'sms') {
      dialogRef = this.dialog.open(FormSendSmsBackofficeDialogComponent);
    } else if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }


  onSubmit() {
    let dialogRef; 
    var messaggio = this.formSMS.get('messaggio')?.value;

    this.numIscritti = this.iscrittiSelezionati.length;
    var destinatari: string = '';

    dialogRef = this.dialog.open(FormSendSmsBackofficeDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.dialog.closeAll();
        this.dialogRef = this.dialog.open(SmsIscrittoSendSmsBackofficeDialogComponent, {data: {cell: '', numero: 0, tot: 0}});              
        this.dialogRef?.afterClosed().subscribe((confirmed: boolean) => {            
          if (!confirmed) {  
            this.stop = true;     
            this.stop_primo = true;          
          }
        })       

        this.iscrittiSelezionati.forEach((id, i) => {      
          
          setTimeout(() => {        
            
            if (this.stop) { 

              if (this.stop_primo) {                           
                this.stop_primo = false;

                this.apiBackofficeService.addComunicazione('Sms', '', '', messaggio, destinatari, this.id_utente).subscribe(comms => {

                  this.dialog.closeAll();
                  dialogRef = this.dialog.open(AnnulloSendSmsBackofficeDialogComponent, {data: {numero: i + 1, totale: this.numIscritti }});
                  dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
                    if (confirmed) {
                      this.router.navigate(['..'], {relativeTo: this.route});
                      return;
                    }                    
                  })

                }, err => {
                  this.openDialog('err');
                })
                
                return;
                
              } else {
                return;
              }  
                                        
            }
                    
            this.apiBackofficeService.getIscritto(id).subscribe(iscritto => {

              this.dialogRef.componentInstance.data = {cell: iscritto.cell, numero: i + 1, tot: this.iscrittiSelezionati.length }

              this.apiBackofficeService.addLog('Invio comunicazione sms', id).subscribe(log => {

                this.apiBackofficeService.sendSms(iscritto.cell, messaggio.trim()).subscribe(email => {
                  
                  i++;
                  if (i == this.numIscritti) {                    
                    destinatari += id;                    

                    this.apiBackofficeService.addComunicazione('Sms', '', '', messaggio, destinatari, this.id_utente).subscribe(comms => {

                      this.dialog.closeAll();
                      dialogRef = this.dialog.open(SuccessDialogComponent);
                      dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
                        if (confirmed) {
                          this.router.navigate(['..'], {relativeTo: this.route});
                        }
                      })

                    }, err => {
                      this.openDialog('err');
                    })

                  } else {
                    destinatari += id  + ';';
                  }                  

                }, err => {
                  this.openDialog('err');
                })

              }, err => {
                this.openDialog('err');
              })

            }, err => {
              this.openDialog('err');
            })

          }, i * 1000);       

        })
                                      
      }
    });

  }

}
