<div class="container-backoffice" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar" fxLayout="row" fxLayoutAlign="start center">
        <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
        <h1><a class="link" routerLink="/backoffice/homepage">HOME</a></h1>
        <span class="logout">
            <span class="bell-icon">
                <button mat-icon-button (click)="onLogout()">
                    <mat-icon>logout</mat-icon>
                </button>              
            </span>
        </span>

    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list>
                <a routerLink="/backoffice/homepage">Gestione Iscritti</a>
                <a routerLink="/backoffice/homepage/utenti" *ngIf="visible">Gestione Utenti</a>
                <a routerLink="/backoffice/homepage/log" *ngIf="visible">Gestione Log</a>
                <a routerLink="/backoffice/homepage/comunicazioni">Gestione Comunicazioni</a>
                <!-- INSERIRE QUA I LINK PER LE VARIE PAGINE -->
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <!-- BODY -->
            <router-outlet></router-outlet>
            <!-- BODY -->
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>