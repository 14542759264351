import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-email-iscritto-send-email-backoffice-dialog',
  templateUrl: './email-iscritto-send-email-backoffice-dialog.component.html',
  styleUrls: ['./email-iscritto-send-email-backoffice-dialog.component.css']
})
export class EmailIscrittoSendEmailBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EmailIscrittoSendEmailBackofficeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { email: string, numero: number, tot: number }) { }

  ngOnInit(): void {
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }
}
