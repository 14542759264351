<mat-dialog-content>
    <p>Stai per eliminare i documenti legati all'iscritto. <br>Sei sicuro di voler procedere?</p>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onConfirmClick()" class="alternativa-css">Procedi</button>
            </div>
        </div>
    </div>
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onCloseClick()" class="alternativa-css">Chiudi</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>