import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AuthResponse } from './interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url_database: string = environment.apiHost;
  token = new BehaviorSubject<string | null>(null);  
  
  private tokenExpirationTime: any;

  constructor(private http: HttpClient, private router: Router, private apiService: ApiService) { }

  login(email: string, password: string) { 
    return this.http.post<AuthResponse>(this.url_database + 'api/v1.0/login', {email: email, password: password, expires: new Date(new Date().getTime() + 3500 * 1000) }).pipe(
      tap(
        resposeData => {
          if (resposeData) {   
            this.handleAuth(resposeData.token)
          }
        }
      )
    );
  }

  AutoLogin() {        
    var token: any = localStorage.getItem('Auth');        
    this.token.next(token);

    this.apiService.getUserData().subscribe(data => {          
      
      const expirationDuration = new Date(data.expires).getTime() - new Date().getTime();      
      this.autoLogout(expirationDuration);

      if (data.message) {
        this.Logout();
      }
    }, err => {
      this.Logout();
    })  
  }

  Logout() {
    localStorage.removeItem('Auth');
    this.token.next(null);
    this.router.navigate(['/']);
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTime = setTimeout(() => {
      this.Logout();
    }, expirationDuration);
  }

  private handleAuth(token: string,) {    
    this.token.next(token);
    localStorage.setItem('Auth', token);
  }
}
