<div class="container-iscritti">
    <div class="col-xs-12">

        <mat-toolbar>
            <mat-toolbar-row>
                <span style="font-weight: 900;">COMUNICAZIONI EMAIL</span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="onGoBack()">
                    <mat-icon>backspace</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="send-email">
            <form [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                <div class="col-xs-12 form">
                    <div class="row">
                        <div class="col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Oggetto Email</mat-label>
                                <input matInput placeholder="Ex. Mario" formControlName="oggetto" required>
                            </mat-form-field>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.oggetto">
                                    <div class="error-message" *ngIf="emailForm.get('oggetto')?.hasError(error.type) && (emailForm.get('oggetto')?.dirty || emailForm.get('oggetto')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Titolo</mat-label>
                                <input matInput formControlName="titolo" required>
                            </mat-form-field>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.titolo">
                                    <div class="error-message" *ngIf="emailForm.get('titolo')?.hasError(error.type) && (emailForm.get('titolo')?.dirty || emailForm.get('titolo')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Indirizzo email di spedizione</mat-label>                                
                                <mat-select formControlName="email" required [disabled]="block_email_to">
                                    <mat-option [value]="'iscrizioni@alternativa.it'">iscrizioni@alternativa.it</mat-option>
                                    <mat-option [value]="'presidente@alternativa.it'">presidente@alternativa.it</mat-option>
                                    <mat-option [value]="'segreteria@alternativa.it'">segreteria@alternativa.it</mat-option>
                                    <mat-option [value]="'abruzzo@alternativa.it'">abruzzo@alternativa.it</mat-option>
                                    <mat-option [value]="'basilicata@alternativa.it'">basilicata@alternativa.it</mat-option>
                                    <mat-option [value]="'calabria@alternativa.it'">calabria@alternativa.it</mat-option>
                                    <mat-option [value]="'campania@alternativa.it'">campania@alternativa.it</mat-option>
                                    <mat-option [value]="'emiliaromagna@alternativa.it'">emiliaromagna@alternativa.it</mat-option>
                                    <mat-option [value]="'friuliveneziagiulia@alternativa.it'">friuliveneziagiulia@alternativa.it</mat-option>
                                    <mat-option [value]="'lazio@alternativa.it'">lazio@alternativa.it</mat-option>
                                    <mat-option [value]="'liguria@alternativa.it'">liguria@alternativa.it</mat-option>
                                    <mat-option [value]="'lombardia@alternativa.it'">lombardia@alternativa.it</mat-option>
                                    <mat-option [value]="'marche@alternativa.it'">marche@alternativa.it</mat-option>
                                    <mat-option [value]="'molise@alternativa.it'">molise@alternativa.it</mat-option>
                                    <mat-option [value]="'piemonte@alternativa.it'">piemonte@alternativa.it</mat-option>
                                    <mat-option [value]="'puglia@alternativa.it'">puglia@alternativa.it</mat-option>
                                    <mat-option [value]="'sardegna@alternativa.it'">sardegna@alternativa.it</mat-option>
                                    <mat-option [value]="'sicilia@alternativa.it'">sicilia@alternativa.it</mat-option>
                                    <mat-option [value]="'toscana@alternativa.it'">toscana@alternativa.it</mat-option>
                                    <mat-option [value]="'trentinoaltoadige@alternativa.it'">trentinoaltoadige@alternativa.it</mat-option>
                                    <mat-option [value]="'umbria@alternativa.it'">umbria@alternativa.it</mat-option>
                                    <mat-option [value]="'valledaosta@alternativa.it'">valledaosta@alternativa.it</mat-option>
                                    <mat-option [value]="'veneto@alternativa.it'">veneto@alternativa.it</mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.oggetto">
                                    <div class="error-message" *ngIf="emailForm.get('oggetto')?.hasError(error.type) && (emailForm.get('oggetto')?.dirty || emailForm.get('oggetto')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12">
                            <label>Contenuto Email</label>
                            <angular-editor formControlName="html" [config]="config"></angular-editor>
                        </div>
                    </div>

                </div>
                <div class="col xs-12">
                    <div class="button-row text-center">
                        <button mat-raised-button type="submit" color="success" [disabled]="!emailForm.valid">Invia email</button>
                    </div>
                </div>
            </form>


        </div>
    </div>
</div>