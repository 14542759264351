<div class="container-iscritti">
    <div class="col-xs-12">
        <div class="row">

            <mat-toolbar>
                <mat-toolbar-row>
                    <span style="text-transform: uppercase; font-weight: 900;">Gestione Iscritti</span>
                    <span class="spacer"></span>
                    <button mat-icon-button class="icon" aria-label="icon-button with menu icon">
                    <mat-icon [matMenuTriggerFor]="menuIscritti">menu</mat-icon>
                    <mat-menu #menuIscritti="matMenu">
                    <button mat-menu-item (click)="onToggleShowNonConfermati()">                        
                        <mat-icon>{{showNonConfermati ? 'visibility_off': 'visibility'}}</mat-icon>
                        <span>{{showNonConfermati ? 'Nascondi' : 'Mostra'}} <strong>non</strong> confermati </span>                            
                    </button>
                    <button mat-menu-item (click)="onToggleShowDonatori()">                        
                        <mat-icon>{{showDonatori ? 'visibility_off': 'visibility'}}</mat-icon>
                        <span>{{showDonatori ? 'Mostra tutti gli iscritti' : 'Mostra solo i Donatori'}}</span>                            
                    </button>
                    <button mat-menu-item (click)="onExport()" [disabled]="this.smsComunication || this.emailComunication">
                        <mat-icon>cloud_download</mat-icon>
                        <span>Esporta iscritti </span>
                    </button>
                    <button mat-menu-item (click)="onExportDonatori()" [disabled]="this.smsComunication || this.emailComunication">
                        <mat-icon>cloud_download</mat-icon>
                        <span>Esporta iscritti che hanno donato </span>
                    </button>
                    <button mat-menu-item (click)="onSendComunication('email')">
                        <mat-icon>email</mat-icon>
                        <span>Invia Email massiva</span>
                    </button>
                    <button mat-menu-item (click)="onSendComunication('sms')">
                        <mat-icon>sms</mat-icon>
                        <span>Invia SMS massivo</span>
                    </button>
                    <button mat-menu-item (click)="onReload()">
                        <mat-icon>refresh</mat-icon>
                        <span>Ricarica elenco</span>
                    </button>
                    </mat-menu>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>

        </div>
        <div class="filtri-iscritto row">
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput class="form-field" [formControl]="anagraficaFilter" placeholder="Cerca Nome o Cognome">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput class="form-field" [formControl]="emailFilter" placeholder="Cerca Email">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Cerca Stato Iscritto</mat-label>
                    <mat-select [formControl]="statoFilter">
                        <mat-option [value]="'attesa_conferma'" *ngIf="showNonConfermati">Attesa conferma email</mat-option>
                        <mat-option [value]="'attesa_verifica'">Attesa di verifica</mat-option>
                        <mat-option [value]="'attivo'">Attivo</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
        </div>
        <div class="row filtri-iscritto">
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Cerca Nazione</mat-label>
                    <mat-select [formControl]="nazioneFilter">
                        <mat-option [value]="'IT'">Italia</mat-option>
                        <mat-option [value]="'EU'">Estero</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput class="form-field" [formControl]="regioneFilter" placeholder="Cerca Regione">
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <input matInput class="form-field" [formControl]="provinciaFilter" placeholder="Cerca Provincia">
                </mat-form-field>
            </div>
            
        </div>
        <div class="row">
            <div class="col text-right buttons">
                <button mat-raised-button color="warn" (click)="onClearFilter()">Pulisci Ricerca</button>
            </div>   
        </div>
        <div class="elenco-iscritti row">
            <mat-table #table [dataSource]="dataSource" matSort>

                <!-- CheckBox Column -->
                <ng-container matColumnDef="checkbox" *ngIf="visible">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <!-- Nome Column -->
                <ng-container matColumnDef="anagrafica">
                    <mat-header-cell *matHeaderCellDef> Nome e Cognome</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="anagrafica"> {{element.nome}} {{element.cognome}}
                    </mat-cell>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"> Email
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="email"> {{element.email}} </mat-cell>
                </ng-container>

                <!-- Cell Column -->
                <ng-container matColumnDef="cell">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Cellulare</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.cell}}</mat-cell>
                </ng-container>

                <!-- Cell Fisso Column -->
                <ng-container matColumnDef="cell_fisso">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Numero di telefono fisso</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.cell_fisso}}</mat-cell>
                </ng-container>

                <!-- Codice Fiscale Column -->
                <ng-container matColumnDef="codice_fiscale">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Codice Fiscale</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.codice_fiscale}}</mat-cell>
                </ng-container>

                <!-- Data Nascita Column -->
                <ng-container matColumnDef="data_nascita">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Data di nascita">
                        Data di nascita</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="data_nascita">{{element.data_nascita | date: 'dd-MM-yyyy'}}
                    </mat-cell>
                </ng-container>

                <!-- Indirizzo Column -->
                <ng-container matColumnDef="indirizzo">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Indirizzo</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.indirizzo}}</mat-cell>
                </ng-container>

                <!-- Professione Column -->
                <ng-container matColumnDef="professione">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Professione</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.professione}}</mat-cell>
                </ng-container>

                <!-- Nazione Column -->
                <ng-container matColumnDef="nazione">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Nazione</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element" data-label="nazione">{{element.nazione}}
                    </mat-cell>
                </ng-container>

                <!-- Regione Column -->
                <ng-container matColumnDef="regione">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Regione">Regione
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="regione">{{ element.regione }}</mat-cell>
                </ng-container>

                <!-- Provincia Column -->
                <ng-container matColumnDef="provincia">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Provincia">
                        Provincia</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="provincia">{{ element.provincia }}</mat-cell>
                </ng-container>

                <!-- Comune Column -->
                <ng-container matColumnDef="comune">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Comune</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.comune}}</mat-cell>
                </ng-container>

                <!-- Precedenti Iscrizioni Column -->
                <ng-container matColumnDef="precedenti_iscrizioni">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Precedenti Iscrizioni</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.precedenti_iscrizioni}}</mat-cell>
                </ng-container>

                <!-- Precedenti Mandati Column -->
                <ng-container matColumnDef="precedenti_mandati">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Precedenti Mandati</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element">{{element.precedenti_mandati}}</mat-cell>
                </ng-container>

                <!-- Stato Column -->
                <ng-container matColumnDef="stato">
                    <mat-header-cell class="hidden" *matHeaderCellDef>Stato</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element" data-label="stato">{{element.stato}}</mat-cell>
                </ng-container>

                <!-- NewsLetter Column -->
                <ng-container matColumnDef="newsletter">
                    <mat-header-cell class="hidden" *matHeaderCellDef>NewsLetter</mat-header-cell>
                    <mat-cell class="hidden" *matCellDef="let element" data-label="newsletter">{{element.newsletter}}</mat-cell>
                </ng-container>

                <!-- Created On Column-->
                <ng-container matColumnDef="created_on">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Data Iscrizione">Data Iscrizione</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="created_on">{{element.created_on | date: 'dd-MM-yyyy' }}</mat-cell>
                </ng-container>

                <!-- Modifica Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="actions">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="onEdit(element.id)">
                                <mat-icon>edit</mat-icon>
                                <span>Gestione Iscritto</span>
                            </button>
                            <button mat-menu-item (click)="onDocs(element.id)" [disabled]="element.stato === 'attesa_conferma'">
                                <mat-icon>folder</mat-icon>
                                <span>Gestione Documenti</span>
                            </button>
                            <button mat-menu-item (click)="onEmail(element.id)">
                                <mat-icon>mail</mat-icon>
                                <span>Invia Email</span>
                            </button>
                            <button mat-menu-item (click)="onSms(element.id)">
                                <mat-icon>sms</mat-icon>
                                <span>Invia SMS</span>
                            </button>
                            <button mat-menu-item (click)="onSendConfermaEmail(element.id, element.email, element.cell)" *ngIf="showNonConfermati && element.stato === 'attesa_conferma'">
                                <mat-icon>verified_user</mat-icon>
                                <span>Re-invia conferma</span>
                            </button>
                            <button mat-menu-item (click)="onLog(element.id)" *ngIf="amminstratore">
                                <mat-icon>info</mat-icon>
                                <span>Log iscritto</span>
                            </button>
                            <button mat-menu-item (click)="onDelete(element.id, element.email, element.nome, element.cognome)">
                                <mat-icon>delete</mat-icon>
                                <span>Revoca Iscrizione</span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'danger': row.stato === 'attesa_conferma', 'warning' : row.stato === 'attesa_verifica', 'success' :row.stato === 'attivo', 'hidden': !showNonConfermati && row.stato === 'attesa_conferma'}"></mat-row>
                                  
                <tr class="mat-row" style="text-align: center;" *matNoDataRow>
                    <td class="mat-cell" style="width: 100vw; vertical-align: middle;" colspan="4">Nessuna iscrizione trovata</td>
                </tr>
            </mat-table>

            <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>

            <br>
            <div class="buttons row" style="padding-bottom: 0px;" *ngIf="emailComunication">
                <div class="col-xs-12 text-left">
                    <button mat-raised-button (click)="onSendEmail()" [disabled]="this.selection.selected.length <= 0">Invia cominicazioni via email</button>
                    <button mat-raised-button class="button-email" color="warn" (click)="onDeleteCommunication()">Annulla</button>
                </div>
            </div>
            <div class="buttons row" *ngIf="smsComunication">
                <div class="col-xs-12 text-left">
                    <button mat-raised-button (click)="onSendSms()" [disabled]="this.selection.selected.length <= 0">Invia comunicazioni via sms</button>
                    <button mat-raised-button class="button-sms" color="warn" (click)="onDeleteCommunication()">Annulla</button>
                </div>
            </div>


        </div>

    </div>

</div>