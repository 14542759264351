import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/shared/api.service';
import { requiredFileType, requiredMaxFileSize, requiredMinYearType } from '../shared/customValidation';
import { ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
import { Comune, Provincia, Regione } from '../shared/interface';
import { LoadingDialogComponent } from '../shared/loading-dialog/loading-dialog.component';
import { DoubleIscrittoDialogComponent } from './double-iscritto-dialog/double-iscritto-dialog.component';
import { NewIscrittoDialogComponent } from './new-iscritto-dialog/new-iscritto-dialog.component';

@Component({
  selector: 'app-iscrizioni',
  templateUrl: './iscrizioni.component.html',
  styleUrls: ['./iscrizioni.component.css']
})
export class IscrizioniComponent implements OnInit{

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private dialog: MatDialog, private titleService: Title) { }    

  nazione?: string;
  regioni?: Regione[];
  province?: Provincia[];
  comuni?: Comune[];

  repData?: {id_nazione: number};
  repDataCheck?: {id: number};  
  repDataCheckValidita!: {id: number};
  repDataAdd!: {id: number};

  fronteCartaPath?: string;
  retroCartaPath?: string;  

  password?: boolean;
  estero?: boolean;  

  iscrizioneForm!: FormGroup;  

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con l`iscrizione' },              
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con l`iscrizione' },      
    ],
    'email': [
      { type: 'required', message: 'Inserire una email per procedere con l`iscrizione' },
      { type: 'minlength', message: 'Inserire minimo 6 caratteri.' },      
      { type: 'email', message: 'Inserire una email valida (ex. test@gmail.com)' }
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con l`iscrizione' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],
    'cell': [
      { type: 'required', message: 'Inserire un numero di cellulare per procedere con l`iscrizione' },
      { type: 'validatePhoneNumber', message: 'Inserire un numero valido' },              
    ],
    'cell_fisso': [    
      { type: 'validatePhoneNumber', message: 'Inserire un numero valido' },                          
    ],
    'codice_fiscale': [
      { type: 'required', message: 'Inserire un codice fiscale per procedere con l`iscrizione' },
      { type: 'pattern', message: 'Inserire un codice fiscale valido.' },        
      { type: 'maxlength', message: 'Inserire massimo 16 cifre'}                
    ],
    'data_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con l`iscrizione' },
      { type: 'requiredMinYearType', message: 'Per iscriversi bisogna avere almeno 16 anni'}
    ],
    'indirizzo': [
      { type: 'required', message: 'Inserire un indirizzo per procedere con l`iscrizione' },            
    ],
    'nazione': [
      { type: 'required', message: 'Inserire una nazione per procedere con l`iscrizione' },            
    ],
    'stato': [
      { type: 'required', message: 'Inserire uno stato per procedere con l`iscrizione' },            
    ],
    'regione': [
      { type: 'required', message: 'Inserire una regione per procedere con l`iscrizione' },      
    ],
    'provincia': [
      { type: 'required', message: 'Inserire una provincia per procedere con l`iscrizione' },
    ],
    'comune': [
      { type: 'required', message: 'Inserire una comune per procedere con l`iscrizione' },
    ],
    'cap': [
      { type: 'required', message: 'Inserire un cap per procedere con l`iscrizione' },
    ],
    'fronte_carta': [
      { type: 'requiredFileType', message: 'Inserire solo file di tipo JPG, PNG o JPEG'},  
      { type: 'requiredMaxFileSize', message: 'Sono accettati solo file con dimensioni minori di 20MB'},
    ],
    'retro_carta': [
      { type: 'requiredFileType', message: 'Inserire solo file di tipo JPG, PNG o JPEG'},
      { type: 'requiredMaxFileSize', message: 'Sono accettati solo file con dimensioni minori di 20MB'},
    ],
    'precedenti_iscrizioni_text': [
      { type: 'required', message: 'Inserire dei dati per procedere con l`iscrizione' },
    ],
    'precedenti_mandati_text': [
      { type: 'required', message: 'Inserire dei dati per procedere con l`iscrizione' },
    ],
    
  }

  ngOnInit(): void {

    this.titleService.setTitle('Richiesta di Iscrizione');

    this.iscrizioneForm = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email : new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(6)]),
      indirizzo: new FormControl(null, Validators.required),
      professione: new FormControl(null),
      cell: new FormControl(null, Validators.required),
      cell_fisso: new FormControl(null),
      codice_fiscale: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
      data_nascita: new FormControl(null, [Validators.required, requiredMinYearType()]),
      nazione: new FormControl('', Validators.required),
      stato: new FormControl(null, Validators.required),
      id_regione: new FormControl('', Validators.required),
      regione: new FormControl(null, Validators.required),
      id_provincia: new FormControl('', Validators.required),
      id_comune: new FormControl('', Validators.required),
      comune: new FormControl(null, Validators.required),
      cap: new FormControl(null),      
      fronte_carta: new FormControl(null, [Validators.nullValidator, requiredFileType(), requiredMaxFileSize()]),
      retro_carta: new FormControl(null, [Validators.nullValidator, requiredFileType(), requiredMaxFileSize()]),
      precedenti_iscrizioni: new FormControl(null, Validators.required),
      precedenti_iscrizioni_text: new FormControl(null),
      precedenti_mandati: new FormControl(null),
      precedenti_mandati_text: new FormControl(null),
      flag_no_iscrizione_societa: new FormControl(null, Validators.required),
      flag_privacy: new FormControl(null, Validators.required),
      flag_newsletter: new FormControl(null)
    })    
  }

  openDialog(type: string) {  
    let dialogRef;    

    this.dialog.closeAll();

    if (type === 'duplicato') {
      dialogRef = this.dialog.open(DoubleIscrittoDialogComponent);
    } else if (type === 'new') {
      dialogRef = this.dialog.open(NewIscrittoDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }
  }

  onRevoca() {
    this.router.navigate(['/'])
  }
  
  setEstero(codice_nazione: string) {

    this.iscrizioneForm.patchValue({
      id_regione: '',
      id_provincia: '',
      id_comune: '',
      stato: '',
      cap: '',
      regione: '',
      comune: '',
      cell: '',
      cell_fisso: ''
    })

    if (codice_nazione == 'IT') {
      this.estero = false;

      this.iscrizioneForm.get('id_regione')?.setValidators(Validators.required);
      this.iscrizioneForm.get('id_provincia')?.setValidators(Validators.required);
      this.iscrizioneForm.get('id_comune')?.setValidators(Validators.required);
      this.iscrizioneForm.get('stato')?.clearValidators();      
      this.iscrizioneForm.get('regione')?.clearValidators();
      this.iscrizioneForm.get('comune')?.clearValidators();
    } else {
      this.estero = true;

      this.iscrizioneForm.get('id_regione')?.clearValidators();
      this.iscrizioneForm.get('id_provincia')?.clearValidators();
      this.iscrizioneForm.get('id_comune')?.clearValidators();      
      this.iscrizioneForm.get('stato')?.setValidators(Validators.required);      
      this.iscrizioneForm.get('regione')?.setValidators(Validators.required);
      this.iscrizioneForm.get('comune')?.setValidators(Validators.required);      
    }

    this.iscrizioneForm.get('id_regione')?.updateValueAndValidity();
    this.iscrizioneForm.get('id_provincia')?.updateValueAndValidity();
    this.iscrizioneForm.get('id_comune')?.updateValueAndValidity();
    this.iscrizioneForm.get('stato')?.updateValueAndValidity();
    this.iscrizioneForm.get('regione')?.updateValueAndValidity();
    this.iscrizioneForm.get('cap')?.updateValueAndValidity();
    this.iscrizioneForm.get('comune')?.updateValueAndValidity();
    this.iscrizioneForm.get('cell')?.updateValueAndValidity();
    this.iscrizioneForm.get('cell_fisso')?.updateValueAndValidity();
  }
  
  setRegione(codice_nazione: string) {     
    this.setEstero(codice_nazione);      
    this.apiService.getNazione(codice_nazione).subscribe(data => {      
      this.repData = data;
      if (this.repData) {                
        this.apiService.getRegioni(this.repData.id_nazione).subscribe(data => {      
          this.regioni = data;            
        })       
      }
    });
  }

  setProvincie(id_regione: number) {    
    this.apiService.getProvince(id_regione).subscribe(data => {
      this.province = data;
    })
  }

  setComuni(id_provincia: number) {    
    this.apiService.getComuni(id_provincia).subscribe(data => {      
      this.comuni = data;
    })
  }

  saveFronteCarta(e: any) {
    const file: any = (e.target as HTMLInputElement)?.files?.[0];                           

    if (file) {
      this.iscrizioneForm.patchValue({
        fronte_carta: file
      });
    
      this.iscrizioneForm.get('fronte_carta')?.updateValueAndValidity();
    
      const reader = new FileReader();
      reader.onload = () => {
        this.fronteCartaPath = reader.result as string;
      }
      reader.readAsDataURL(file); 
    }   
  }

  saveRetroCarta(e: any) {
    const file: any = (e.target as HTMLInputElement)?.files?.[0];

    if (file) {
      this.iscrizioneForm.patchValue({
        retro_carta: file
      });

      this.iscrizioneForm.get('retro_carta')?.updateValueAndValidity()

      const reader = new FileReader();
      reader.onload = () => {
        this.retroCartaPath = reader.result as string;
      }
      reader.readAsDataURL(file);
    }
  }

  onRadio(radio: boolean, type: string) {
    var field = type === 'iscrizioni' ? 'precedenti_iscrizioni_text' : 'precedenti_mandati_text';

    if(radio) {
      this.iscrizioneForm.get(field)?.setValidators(Validators.required)
    } else {
      this.iscrizioneForm.get(field)?.clearValidators();
    }
    
    this.iscrizioneForm.get(field)?.updateValueAndValidity();
  }

  onSubmit() {    

    var nome = this.iscrizioneForm.get('nome')?.value;
    var cognome = this.iscrizioneForm.get('cognome')?.value;
    var email = this.iscrizioneForm.get('email')?.value;
    var password = this.iscrizioneForm.get('password')?.value;
    var indirizzo = this.iscrizioneForm.get('indirizzo')?.value;
    var professione = this.iscrizioneForm.get('professione')?.value;
    var cell = this.iscrizioneForm.get('cell')?.value;
    var cell_fisso = this.iscrizioneForm.get('cell_fisso')?.value;
    var codice_fiscale = this.iscrizioneForm.get('codice_fiscale')?.value;
    var data_nascita = this.iscrizioneForm.get('data_nascita')?.value;
    var nazione = this.iscrizioneForm.get('nazione')?.value;
    var stato = this.iscrizioneForm.get('stato')?.value;
    var id_regione = this.iscrizioneForm.get('id_regione')?.value;
    var regione = this.iscrizioneForm.get('regione')?.value;
    var id_provincia = this.iscrizioneForm.get('id_provincia')?.value;
    var id_comune = this.iscrizioneForm.get('id_comune')?.value;
    var comune = this.iscrizioneForm.get('comune')?.value;    
    var cap = this.iscrizioneForm.get('cap')?.value;
    var fronte_carta_file: string | undefined= this.fronteCartaPath;
    var retro_carta_file: string | undefined = this.retroCartaPath;    
    var retro_carta_extension = this.iscrizioneForm.get('retro_carta')?.value.name;
    var retro_carta = retro_carta_extension.split('.').pop();        
    var fronte_carta_extension = this.iscrizioneForm.get('fronte_carta')?.value.name;
    var fronte_carta = fronte_carta_extension.split('.').pop();        
    var precedenti_iscrizioni = this.iscrizioneForm.get('precedenti_iscrizioni')?.value === true ? this.iscrizioneForm.get('precedenti_iscrizioni_text')?.value : '';
    var precedenti_mandati = this.iscrizioneForm.get('precedenti_mandati')?.value === true ? this.iscrizioneForm.get('precedenti_mandati_text')?.value : '';
    var flag_no_iscrizione_societa = this.iscrizioneForm.get('flag_no_iscrizione_societa')?.value;
    var flag_newsletter = this.iscrizioneForm.get('flag_newsletter')?.value;      

    this.dialog.open(LoadingDialogComponent);
    // Controllo se si era già iscritto ma non aveva confermato l'account
    this.apiService.checkValiditaIscritto(email).subscribe(data => {
      this.repDataCheckValidita = data;

      if (!this.repDataCheckValidita) {

        // Controllo se è già presente una mail di un account confermato
        this.apiService.checkEsistenzaUtente(email).subscribe(data => {
          this.repDataCheck = data;

          if (!this.repDataCheck) {               

            // Recupero l'ultimo iscritto e incremento di uno
            this.apiService.checkLastIscritto().subscribe(dataIscritto => {
              var id = dataIscritto.id + 1;
                              
              // Salvo i documenti
              this.apiService.saveDocuments(nome, cognome, fronte_carta, fronte_carta_file, retro_carta, retro_carta_file, id).subscribe(dataDocuments => {                      
                
                // Controllo se è stato selezionato estero
                if (!this.estero) {
                  // Recupero il nome delle localita selezionate
                  this.apiService.getLocalita(id_regione, id_provincia, id_comune).subscribe(data => { 
                  
                    // Aggiungo il record dell'iscritto
                    this.apiService.addIscrizione(nome, cognome, email, password, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, null, id_regione, data.regione, id_provincia, data.provincia, id_comune, data.comune, null, fronte_carta, retro_carta, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter).subscribe(data => {                                
                      this.repDataAdd = data;                    
                      this.openDialog('new');
                      this.iscrizioneForm.reset();   
                      window.open('https://www.paypal.com/donate/?hosted_button_id=KB225CW3AHZUQ&nbsp;', "_blank");                 
                    }, errorRes => {
                      this.openDialog('err');
                    });   

                  }, errLoc => {
                    this.openDialog('err');
                  })     
                } else {
                  // Aggiungo il record dell'iscritto
                  this.apiService.addIscrizione(nome, cognome, email, password, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, stato, null, regione, null, null, null, comune, cap, fronte_carta, retro_carta, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter).subscribe(data => {                                
                    this.repDataAdd = data;                    
                    this.openDialog('new');
                    this.iscrizioneForm.reset();                    
                    window.open('https://www.paypal.com/donate/?hosted_button_id=KB225CW3AHZUQ&nbsp;', "_blank");
                  }, errorRes => {
                    this.openDialog('err');
                  });   
                }
              }, errDoc => {
                this.openDialog('err');
              })    
            }, err => {
              this.openDialog('err');
            })                                        
          } else {
              this.openDialog('duplicato');         
          }
        }, error => {      
          this.openDialog('err');
        })
      } else {
        // Se era già iscritto ma non aveva confermato l'account salvo prima i documenti
        this.apiService.saveDocuments(nome, cognome, fronte_carta, fronte_carta_file, retro_carta, retro_carta_file, this.repDataCheckValidita.id).subscribe(dataDocuments => {          

          // Controllo se è stato selezionato estero
          if (!this.estero) {
            // Recupero il nome delle localita selezionate
            this.apiService.getLocalita(id_regione, id_provincia, id_comune).subscribe(data => {  
                         
              // Aggiorno il record con i nuovi dati              
              this.apiService.updateIscrittoInvalido(nome, cognome, email, password, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, null, id_regione, data.regione, id_provincia, data.provincia, id_comune, data.comune, null, fronte_carta, retro_carta, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.repDataCheckValidita.id).subscribe(data => {
                this.openDialog('new');
                this.iscrizioneForm.reset();                
                window.open('https://www.paypal.com/donate/?hosted_button_id=KB225CW3AHZUQ&nbsp;', "_blank");
              }, errorUp => {
                this.openDialog('err');
              }) 
            }, errLoc => {
              this.openDialog('err');
            })
          } else {

            // Aggiorno il record con i nuovi dati              
            this.apiService.updateIscrittoInvalido(nome, cognome, email, password, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, stato, null, regione, null, null, null, comune, cap, fronte_carta, retro_carta, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.repDataCheckValidita.id).subscribe(data => {
              this.openDialog('new');
              this.iscrizioneForm.reset();                
              window.open('https://www.paypal.com/donate/?hosted_button_id=KB225CW3AHZUQ&nbsp;', "_blank");
            }, errorUp => {
              this.openDialog('err');
            }) 
          }                   
        }, errDoc => {
          this.openDialog('err');
        })
      }
    })      
  
  }

}

