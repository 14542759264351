import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Iscritto } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { DeleteIscrittoBackofficeDialogComponent } from '../elenco-iscritti-backoffice/delete-iscritto-backoffice-dialog/delete-iscritto-backoffice-dialog.component';
import { AttivazioneDocumentiIscrittiBackofficeDialogComponent } from './attivazione-documenti-iscritti-backoffice-dialog/attivazione-documenti-iscritti-backoffice-dialog.component';
import { EliminazioneDocumentiIscrittiBackofficeDialogComponent } from './eliminazione-documenti-iscritti-backoffice-dialog/eliminazione-documenti-iscritti-backoffice-dialog.component';

@Component({
  selector: 'app-documenti-iscritti-backoffice',
  templateUrl: './documenti-iscritti-backoffice.component.html',
  styleUrls: ['./documenti-iscritti-backoffice.component.css']
})
export class DocumentiIscrittiBackofficeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router : Router, private apiBackofficeService: ApiBackofficeService, private titleService: Title, private dialog: MatDialog) { }    
  docForm!: FormGroup;

  repDataIscritto! : Iscritto;  
  
  id!: number;  

  fileFronte!: string;
  fileRetro!: string;

  attivo: boolean = false;
  estero: boolean = false;

  ngOnInit(): void {

    this.titleService.setTitle('Alternativa Backoffice - Gestione Documenti Iscritto');

    var params = this.route.params.subscribe(params => {      
      this.id = params['id'];
    });       

    this.docForm = new FormGroup({
      nome: new FormControl(null),
      cognome: new FormControl(null),
      codice_fiscale: new FormControl(null),
      data_nascita: new FormControl(null),
      indirizzo: new FormControl(null),
      professione: new FormControl(null),
      nazione: new FormControl(null),
      stato: new FormControl(null),
      regione: new FormControl(null),
      provincia: new FormControl(null),
      comune: new FormControl(null),
      cap: new FormControl(null),
      fronte_doc: new FormControl(null),
      retro_doc: new FormControl(null)
    })

    this.apiBackofficeService.getIscritto(this.id).subscribe(data => {
      this.repDataIscritto = data;                   
               
      if (this.repDataIscritto) {        
        this.attivo = this.repDataIscritto.stato === 'attivo' ? true : false;
              
        this.apiBackofficeService.getUserData().subscribe(data => {
          if (data.tipo === 'Coordinatore') {
            if (data.id_regione !== this.repDataIscritto.id_regione) {
              this.router.navigate(['/backoffice/homepage']);
            }
          } else if (data.tipo === 'Operatore') {
            if (this.repDataIscritto.stato === 'attivo') {
              this.router.navigate(['/backoffice/homepage']);
            }
          }
        })                    
        
        this.apiBackofficeService.getDocuments(this. repDataIscritto.id, this.repDataIscritto.fronte_carta, this.repDataIscritto.retro_carta).subscribe(data => {
          this.fileFronte = data.fronte;
          this.fileRetro = data.retro;

          this.estero = this.repDataIscritto.nazione == 'EU' ? true : false;

          var datePipe = new DatePipe('en-US');
          
          this.docForm.setValue({
            nome: this.repDataIscritto.nome,
            cognome: this.repDataIscritto.cognome,
            codice_fiscale: this.repDataIscritto.codice_fiscale,
            data_nascita: datePipe.transform(this.repDataIscritto.data_nascita, 'yyyy-MM-dd'),
            indirizzo: this.repDataIscritto.indirizzo,
            professione: this.repDataIscritto.professione,
            nazione: this.repDataIscritto.nazione == 'IT' ? 'Italia': 'Estero',
            stato: this.repDataIscritto.state,
            regione: this.repDataIscritto.regione,
            provincia: this.repDataIscritto.provincia,
            comune: this.repDataIscritto.comune,
            cap: this.repDataIscritto.cap,
            fronte_doc: this.fileFronte,
            retro_doc: this.fileRetro
          })
        })
      }

    })
  }

  onRevoca() {
    let dialogRef;
    dialogRef = this.dialog.open(DeleteIscrittoBackofficeDialogComponent, { data: { nome: this.repDataIscritto.nome, cognome: this.repDataIscritto.cognome, email: this.repDataIscritto.email } }); 
    dialogRef?.afterClosed().subscribe((confirmed: {confirm: boolean, message: string}) => {
      if (confirmed.confirm) {
        this.apiBackofficeService.addLog('Revoca Iscrizione', this.repDataIscritto.id).subscribe(log => {
          this.apiBackofficeService.deleteIscritto(this.repDataIscritto.id, this.repDataIscritto.email, confirmed.message).subscribe(data => {          
            this.apiBackofficeService.setIscritti();
            this.router.navigate(['../..'], {relativeTo: this.route});    
          }, err => {
            this.openDialog('err');
          });      
        }, err => {
          this.openDialog('err');
        })            
      }
    });
  }

  onAttivazione() {
    let dialogRef;
    dialogRef = this.dialog.open(AttivazioneDocumentiIscrittiBackofficeDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.apiBackofficeService.addLog('Attivazione iscritto', this.repDataIscritto.id).subscribe(log => {   

          this.apiBackofficeService.attivaIscritto(this.repDataIscritto.id, this.repDataIscritto.email).subscribe(data => {

            this.apiBackofficeService.addLog('Eliminazione Documenti', this.repDataIscritto.id).subscribe(log => {

              this.apiBackofficeService.deleteDocuments(this.repDataIscritto.id).subscribe(data => {    

                dialogRef = this.dialog.open(SuccessDialogComponent);
                dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
                  if (confirmed) {
                    let currentUrl = this.router.url;
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                      this.router.navigate([currentUrl]);
                    });              
                  }
                })
              }, error => {
                this.openDialog('err');
              })  
            }, error => {
              this.openDialog('err');
            })                  
          }, error => {
            this.openDialog('err');
          })
        }, err => {
          this.openDialog('err');
        })
      }
    });

  }

  onEliminaDocs() {
    let dialogRef;
    dialogRef = this.dialog.open(EliminazioneDocumentiIscrittiBackofficeDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.apiBackofficeService.addLog('Eliminazione Documenti', this.repDataIscritto.id).subscribe(log => {

          this.apiBackofficeService.deleteDocuments(this.repDataIscritto.id).subscribe(data => {

            dialogRef = this.dialog.open(SuccessDialogComponent);
            dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                let currentUrl = this.router.url;
                this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                  this.router.navigate([currentUrl]);
                });              
              }
            })
          }, error => {
            this.openDialog('err');
          }) 
        }, error => {
          this.openDialog('err');
        })           
      }
    });
  }

  onGoBack() {
    this.router.navigate(['../..'], {relativeTo: this.route});
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'attivazione') {
      dialogRef = this.dialog.open(AttivazioneDocumentiIscrittiBackofficeDialogComponent);
    } else if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else if (type === 'eliminazione') {
      dialogRef = this.dialog.open(EliminazioneDocumentiIscrittiBackofficeDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }
}
