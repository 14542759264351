<div class="container">
    <div class="login-form">
        <div class="row">
            <div class="col-xs-12">
                <div class="title text-center">
                    <h2>AREA PERSONALE</h2>
                </div>
                <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="input">
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" name="email" id="email" class="form-control" formControlName="email">
                                    </div>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.email">
                                            <div class="error-message" *ngIf="loginForm.get('email')?.hasError(error.type) && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <label for="pass">Password</label>
                                        <input type="password" name="pass" id="pass" class="form-control" formControlName="password" autocomplete>
                                    </div>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.password">
                                            <div class="error-message" *ngIf="loginForm.get('password')?.hasError(error.type) && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="error">
                                <div class="col-xs-12">
                                    <div class="form-group">
                                        <p class="alert alert-danger">
                                            {{ error }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row text-right" style="margin: 0px auto;">
                                <h5 class="reset" routerLink="reset-password">Hai dimenticato la password?</h5>
                            </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="row">
                            <div class="button-column">
                                <div class="button-overlay">
                                    <div class="button-container">
                                        <button type="submit" id="accedi" name="accedi" class="alternativa-css">ACCEDI</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center" style="margin: 20px auto;">
                            <h4><strong>Non sei ancora iscritto all'associazione Alternativa? Iscriviti qui:</strong></h4>
                        </div>
                        <div class="row">
                            <div class="button-column">
                                <div class="button-overlay">
                                    <div class="button-container">
                                        <button type="button" id="iscriviti" name="iscriviti" (click)="onIscrizione()" class="alternativa-css">ISCRIVITI</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>