import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Inject } from '@angular/core';

@Component({
  selector: 'app-delete-iscritto-backoffice-dialog',
  templateUrl: './delete-iscritto-backoffice-dialog.component.html',
  styleUrls: ['./delete-iscritto-backoffice-dialog.component.css']
})
export class DeleteIscrittoBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DeleteIscrittoBackofficeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { nome: string, cognome: string, email: string }) { }

  messaggio?: string;

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close({ confirm: true, message: this.messaggio });
  }

  onCloseClick() {
    this.dialogRef.close({ confirm: false, message: this.messaggio });
  }
}
