<app-header></app-header>
<div class="body">
    <div class="testo" *ngIf="!confermato">
        <div class="success" *ngIf="token && valid">
            <h2>Grazie per la tua iscrizione!<br /> Abbiamo bisogno del contributo di tutti per cambiare il nostro Paese!</h2>

            <div class="form" *ngIf="!confirmed">
                <h4 class="text-center">Inserire il codice ricevuto tramite SMS per convalidare il numero di cellulare</h4>
                <div class="form-group">
                    <input type="text" class="form-control text-center" id="cell" [(ngModel)]="userCode">
                </div>
                <div class="form-group">
                    <p *ngIf="error" class="alert alert-danger">
                        {{ error }}
                    </p>
                </div>
            </div>

            <br>

            <div class="flex">
                <div class="button-column" *ngIf="confirmed && valid">
                    <div class="button-overlay">
                        <div class="button-container">
                            <button type="button" id="accedi" name="accedi" (click)="onAccedi()" class="alternativa-css">ACCEDI</button>
                        </div>
                    </div>
                </div>
                <div class="button-column" *ngIf="!confirmed">
                    <div class="button-overlay">
                        <div class="button-container">
                            <button type="button" id="convalida" name="convalida" class="alternativa-css" (click)="onConvalida()">CONVALIDA</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="error" *ngIf="(!token || !valid)">
            <h2>Il link per l'iscrizione è scaduto</h2>
            <h3>Se non sei ancora iscritto iscriviti cliccando il pulsante qua sottostante</h3>
            <br>
            <div class="button-column">
                <div class="button-overlay">
                    <div class="button-container">
                        <button type="button" id="iscriviti" name="iscriviti" (click)="onIscriviti()" class="alternativa-css">ISCRIVITI</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="confermato testo" *ngIf=" confermato ">
        <div class="error ">
            <h2>La tua iscrizione è già stata confermata!</h2>
            <br>
            <div class="button-column ">
                <div class="button-overlay ">
                    <div class="button-container ">
                        <button type="button " id="iscriviti " name="iscriviti " (click)="onAccedi() " class="alternativa-css ">ACCEDI</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>