<div class="container-iscritti">
    <div class="col-xs-12">
        <div class="row">

            <mat-toolbar>
                <mat-toolbar-row>
                    <span style="text-transform: uppercase; font-weight: 900;">Gestione Comunicazioni</span>
                    <span class="spacer"></span>
                </mat-toolbar-row>
            </mat-toolbar>

        </div>
        <div class="filtri-email">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-label>Cerca Tipo</mat-label>
                        <mat-select [formControl]="tipoFilter">
                            <mat-option [value]="'Email'">Email</mat-option>
                            <mat-option [value]="'Sms'">Sms</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field>
                        <input matInput class="form-field" [formControl]="utenteFilter" placeholder="Cerca Utente">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="buttons row">
            <div class="col-xs-12 text-right">
                <button mat-raised-button color="warn" (click)="onClearFilter()">Pulisci Ricerca</button>
            </div>
        </div>

        <div class="elenco-email row">
            <mat-table #table [dataSource]="dataSource" matTableExporter matSort #exporter="matTableExporter">

                <!-- Tipo Column -->
                <ng-container matColumnDef="tipo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="tipo"> {{element.tipo}}</mat-cell>
                </ng-container>

                <!-- Utente Column -->
                <ng-container matColumnDef="utente">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Utente</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="utente"> {{element.username}} </mat-cell>
                </ng-container>

                <!-- Destinatari Column -->
                <ng-container matColumnDef="destinatari">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>N° Destinatari</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="destinatari"> {{numDestinatari(element)}} </mat-cell>
                </ng-container>

                <!-- Data Column -->
                <ng-container matColumnDef="data">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Data</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{element.created_on | date: 'dd-MM-yyyy HH:ss'}}</mat-cell>
                </ng-container>


                <!-- Modifica Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="actions">
                        <button mat-icon-button (click)="onOpen(element.id)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>

                <tr class="mat-row" style="text-align: center;" *matNoDataRow>
                    <td class="mat-cell" style="width: 100vw; vertical-align: middle;" colspan="4">Nessuna comunicazione trovata</td>
                </tr>
            </mat-table>

            <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>


        </div>

    </div>

</div>