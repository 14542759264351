import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sms-iscritto-send-sms-backoffice-dialog',
  templateUrl: './sms-iscritto-send-sms-backoffice-dialog.component.html',
  styleUrls: ['./sms-iscritto-send-sms-backoffice-dialog.component.css']
})
export class SmsIscrittoSendSmsBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<SmsIscrittoSendSmsBackofficeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { cell: string, numero: number, tot: number }) { }

  ngOnInit(): void {
  }

}
