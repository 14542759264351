import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-utente-backoffice-dialog',
  templateUrl: './delete-utente-backoffice-dialog.component.html',
  styleUrls: ['./delete-utente-backoffice-dialog.component.css']
})
export class DeleteUtenteBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef : MatDialogRef<DeleteUtenteBackofficeDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);            
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }
}
