import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modifica-utente-password-dialog',
  templateUrl: './modifica-utente-password-dialog.component.html',
  styleUrls: ['./modifica-utente-password-dialog.component.css']
})
export class ModificaUtentePasswordDialogComponent implements OnInit {

  constructor(private dialogRef : MatDialogRef<ModificaUtentePasswordDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }

}
