<div class="body">
    <div class="container-iscritti-front">
        <div class="row">
            <div class="reset col-xs-12">
                <h1>RESET<br><span style="color: #ef6c00;">PASSWORD</span></h1>
                <form [formGroup]="resetEmailForm" (ngSubmit)="onSubmitEmail()" *ngIf="!id">
                    <div class="col-xs-12 form">
                        <div class="col-xs-12">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="text" id="email" class="form-control" formControlName="email">
                            </div>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.email">
                                    <div class="error-message" *ngIf="resetEmailForm.get('email')?.hasError(error.type) && (resetEmailForm.get('email')?.dirty || resetEmailForm.get('email')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="error-messages" *ngIf="errorEmail">
                                <div class="error-message">
                                    <p class="alert alert-danger">{{errorEmail}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 row flex">
                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <div matTooltip="Per procedere con il reset della password valorizzare il campo email" [matTooltipDisabled]="resetEmailForm.valid" matTooltipClass="custom-tooltip">
                                        <button type="submit" id="conferma_isc" name="conferma_isc" class="alternativa-css" [disabled]="!resetEmailForm.valid">RESET PASSWORD</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <button type="button" id="revoca_isc" name="revoca_isc" (click)="onExit()" class="alternativa-css">ESCI</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" *ngIf="id">
                    <div class="col-xs-12 form">
                        <div class="col-xs-12">
                            <label for="password">Password</label>
                            <div class="form-group flex-button">
                                <input [type]="fieldTextType ? 'text' : 'password'" id="password" class="form-control" formControlName="password" autocomplete>
                                <button mat-icon-button type="button" (click)="toggleFieldTextType()">
                                    <mat-icon *ngIf="fieldTextType">visibility_off</mat-icon>
                                    <mat-icon *ngIf="!fieldTextType">visibility</mat-icon>
                                </button>
                            </div>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.password">
                                    <div class="error-message" *ngIf="resetForm.get('password')?.hasError(error.type) && (resetForm.get('password')?.dirty || resetForm.get('password')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <label for="confirm_password">Conferma password</label>
                            <div class="form-group flex-button">
                                <input [type]="repeatFieldTextType ? 'text' : 'password'" id="confirm_password" class="form-control" formControlName="confirm_password">
                                <button mat-icon-button type="button" (click)="toggleRepeatFieldTextType()">
                                    <mat-icon *ngIf="repeatFieldTextType">visibility_off</mat-icon>
                                    <mat-icon *ngIf="!repeatFieldTextType">visibility</mat-icon>
                                </button>
                            </div>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.confirm_password">
                                    <div class="error-message" *ngIf="resetForm.get('confirm_password')?.hasError(error.type) && (resetForm.get('confirm_password')?.dirty || resetForm.get('confirm_password')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="error-messages col-xs-12">
                            <div class="error-message" *ngIf="resetForm.errors?.passwordNotMatch">
                                <p class="alert alert-danger">Le due password non combaciano</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 row flex">
                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <div matTooltip="Per procedere con il reset della password valorizzare entrambi i campi correttamente" [matTooltipDisabled]="resetForm.valid" matTooltipClass="custom-tooltip">
                                        <button type="submit" id="conferma_isc" name="conferma_isc" class="alternativa-css" [disabled]="!resetForm.valid">RESET PASSWORD</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <button type="button" id="revoca_isc" name="revoca_isc" (click)="onExit()" class="alternativa-css">ESCI</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <br>
    </div>
</div>