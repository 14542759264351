<mat-dialog-content>
    <p>La procedura di invio massivo di email è stata annullata.<br> Sono state inviate <strong>{{data.numero}}</strong> / <strong>{{data.totale}}</strong> email</p>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onConfirmClick()" class="alternativa-css">Ok</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>