import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-double-iscritto-dialog',
  templateUrl: './double-iscritto-dialog.component.html',
  styleUrls: ['./double-iscritto-dialog.component.css']
})
export class DoubleIscrittoDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DoubleIscrittoDialogComponent>) {
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
      
  }

}
