<mat-dialog-content>
    <p>La comunicazione <strong>{{data.numero}}</strong> / {{data.tot}} sta per essere inviata all'email <strong>{{data.email}}</strong> </p>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onCloseClick()" class="alternativa-css">Annulla Invio Massivo</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>