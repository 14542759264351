import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Comunicazioni } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';


@Component({
  selector: 'app-elenco-comunicazioni-backoffice',
  templateUrl: './elenco-comunicazioni-backoffice.component.html',
  styleUrls: ['./elenco-comunicazioni-backoffice.component.css']
})
export class ElencoComunicazioniBackofficeComponent implements OnInit {
  constructor(private dialog: MatDialog, private apiBackofficeService: ApiBackofficeService, private router: Router, private route: ActivatedRoute, private titleService: Title) { }
  
  displayedColumns: Array<string> = ['tipo', 'utente', 'destinatari', 'data', 'actions']    
  dataSource!: MatTableDataSource<Comunicazioni>;  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;     

  tipoFilter = new FormControl();
  utenteFilter = new FormControl();  

  num_destinatari!: number;

  limitazione: number = 0;

  globalFilter = '';

  filteredValues = {
    tipo: '', utente: ''
  };

  ngOnInit(): void {
    this.titleService.setTitle('Alternativa Backoffice - Elenco Comunicazioni');                      

    this.apiBackofficeService.getUserData().subscribe(data => {      

      if (data.tipo !== 'Amministratore' && data.tipo !== 'Supervisore') {
        this.limitazione = data.id;
      }

      this.apiBackofficeService.getComunicazioni(this.limitazione).subscribe(data => {      

        this.dataSource = new MatTableDataSource<Comunicazioni>(data);
          this.paginator._intl.itemsPerPageLabel = 'Comunicazioni per pagina';
          this.paginator._intl.nextPageLabel = 'Pagina successiva';
          this.paginator._intl.previousPageLabel = 'Pagina precedente';

          this.dataSource.paginator = this.paginator;   
          this.dataSource.sort = this.sort;          
      
          this.tipoFilter.valueChanges.subscribe((tipoFilterValue) => {
            this.filteredValues['tipo'] = tipoFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
          });

          this.utenteFilter.valueChanges.subscribe((utenteFilterValue) => {
            this.filteredValues['utente'] = utenteFilterValue;
            this.dataSource.filter = JSON.stringify(this.filteredValues);
          });
        
          this.dataSource.filterPredicate = this.customFilterPredicate();
      })
    })
    
  }

  customFilterPredicate() {    

    const myFilterPredicate = (data: Comunicazioni, filter: string): boolean => {      

      let searchString = JSON.parse(filter);      

      return data.tipo.toString().trim().toLowerCase().indexOf(searchString.tipo.toLowerCase()) !== -1 &&
      data.username.toString().trim().toLowerCase().indexOf(searchString.utente.toLowerCase()) !== -1         
    }
    return myFilterPredicate;
  }

  onOpen(id: number) {
    this.router.navigate([id], {relativeTo: this.route});    
  }

  onClearFilter() {
    this.dataSource.filter = '';

    this.tipoFilter.setValue('');
    this.utenteFilter.setValue('');    
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);    
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }

  numDestinatari(comunicazione: Comunicazioni) : number {  
    var destinatari = comunicazione.destinatari.split(';');    
    return destinatari.length;
  }


}
