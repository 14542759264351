import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ApiService } from 'src/app/shared/api.service';
import { requiredMinYearType } from 'src/app/shared/customValidation';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Comune, Iscritto, Nazioni, Provincia, Regione } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { DeleteIscrittoBackofficeDialogComponent } from '../elenco-iscritti-backoffice/delete-iscritto-backoffice-dialog/delete-iscritto-backoffice-dialog.component';
import { ModificaIscrittoBackofficeDialogComponent } from './modifica-iscritto-backoffice-dialog/modifica-iscritto-backoffice-dialog.component';
import { ModificaPasswordIscrittoBackofficeDialogComponent } from './modifica-password-iscritto-backoffice-dialog/modifica-password-iscritto-backoffice-dialog.component';

@Component({
  selector: 'app-edit-iscritto-backoffice',
  templateUrl: './edit-iscritto-backoffice.component.html',
  styleUrls: ['./edit-iscritto-backoffice.component.css']
})

export class EditIscrittoBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog, private apiBackofficeService: ApiBackofficeService, private titleService: Title) { }

  userForm!: FormGroup;

  regioni?: Regione[];
  province?: Provincia[];
  comuni?: Comune[];

  id!: number;

  repData!: { id_nazione: number };
  repDataCheck?: { id: number };
  repDataCheckPassword?: { id: number };
  repDataIscritto!: Iscritto;

  hide = true;

  estero?: boolean;

  nazioni: Nazioni[] = [
    { value: 'IT', viewValue: 'Italia' },
    { value: 'EU', viewValue: 'Estero' }
  ];

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica dei dati' },
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con la modifica dei dati' },
    ],
    'email': [
      { type: 'required', message: 'Inserire una email per procedere con la modifica dei dati' },
      { type: 'minlength', message: 'Inserire minimo 6 caratteri.' },
      { type: 'email', message: 'Inserire una email valida (ex. test@gmail.com)' }
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con la modifica dei dati' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },
    ],
    'cell': [
      { type: 'required', message: 'Inserire un numero di cellulare per procedere con la modifica dei dati' },
      { type: 'validatePhoneNumber', message: 'Inserire un numero valido' },
    ],
    'cell_fisso': [
      { type: 'validatePhoneNumber', message: 'Inserire un numero valido' },
    ],
    'codice_fiscale': [
      { type: 'required', message: 'Inserire un codice fiscale per procedere con la modifica dei dati' },
      { type: 'pattern', message: 'Inserire un codice fiscale valido.' },
      { type: 'maxlength', message: 'Inserire massimo 16 cifre' }
    ],
    'data_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la modifica dei dati' },
      { type: 'requiredMinYearType', message: 'Per iscriversi bisogna avere almeno 16 anni' }
    ],
    'indirizzo': [
      { type: 'required', message: 'Inserire un indirizzo per procedere con la modifica dei dati' },
    ],
    'nazione': [
      { type: 'required', message: 'Inserire una nazione per procedere con la modifica dei dati' },
    ],
    'stato': [
      { type: 'required', message: 'Inserire uno stato per procedere con la modifica dei dati' },
    ],
    'regione': [
      { type: 'required', message: 'Inserire una regione per procedere con la modifica dei dati' },
    ],
    'provincia': [
      { type: 'required', message: 'Inserire una provincia per procedere con la modifica dei dati' },
    ],
    'comune': [
      { type: 'required', message: 'Inserire una comune per procedere con la modifica dei dati' },
    ],
    'cap': [
      { type: 'required', message: 'Inserire un cap per procedere con la modifica dei dati' },
    ],
    'precedenti_iscrizioni_text': [
      { type: 'required', message: 'Inserire dei dati per procedere con la modifica dei dati' },
    ],
    'precedenti_mandati_text': [
      { type: 'required', message: 'Inserire dei dati per procedere con la modifica dei dati' },
    ],
    'data_pagamento': [
      { type: 'required', message: 'Inserire una data per procedere con la modifica dei dati' },
    ],
  }

  ngOnInit(): void {    

    this.titleService.setTitle('Alternativa Backoffice - Modifica Iscritto');

    this.userForm = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.maxLength(30), Validators.minLength(6)]),
      indirizzo: new FormControl(null, Validators.required),
      professione: new FormControl(null),
      cell: new FormControl(null, Validators.required),
      cell_fisso: new FormControl(null),
      codice_fiscale: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
      data_nascita: new FormControl(null, [Validators.required, requiredMinYearType()]),
      nazione: new FormControl('', Validators.required),
      stato: new FormControl(null, Validators.required),
      id_regione: new FormControl('', Validators.required),
      regione: new FormControl(null, Validators.required),
      id_provincia: new FormControl('', Validators.required),
      id_comune: new FormControl('', Validators.required),
      comune: new FormControl(null, Validators.required),
      cap: new FormControl(null),
      precedenti_iscrizioni: new FormControl(null, Validators.required),
      precedenti_iscrizioni_text: new FormControl(null),
      precedenti_mandati: new FormControl(null, Validators.required),
      precedenti_mandati_text: new FormControl(null),
      pagamento: new FormControl(null, Validators.required),
      data_pagamento: new FormControl(null),
      flag_no_iscrizione_societa: new FormControl(null),
      flag_newsletter: new FormControl(null)
    })

    this.route.params.subscribe(params => {
      this.id = params['id'];


      this.apiBackofficeService.getIscritto(this.id).subscribe(data => {
        this.repDataIscritto = data;
        if (this.repDataIscritto) {

          this.apiBackofficeService.getUserData().subscribe(data => {
            if (data.tipo === 'Coordinatore') {
              if (data.id_regione !== this.repDataIscritto.id_regione) {
                this.router.navigate(['/backoffice/homepage']);
              }
            } else if (data.tipo === 'Operatore') {
              if (this.repDataIscritto.stato === 'attivo') {
                this.router.navigate(['/backoffice/homepage']);
              }
            }
          })

          this.apiService.getNazione(this.repDataIscritto.nazione).subscribe(data => {
            this.repData = data;
            if (this.repData) {
              this.apiService.getRegioni(this.repData.id_nazione).subscribe(data => {
                this.regioni = data;
                this.apiService.getProvince(this.repDataIscritto.id_regione).subscribe(data => {
                  this.province = data

                  this.apiService.getComuni(this.repDataIscritto.id_provincia).subscribe(data => {
                    this.comuni = data;

                    var datePipe = new DatePipe('en-US');

                    this.userForm.setValue({
                      nome: this.repDataIscritto.nome,
                      cognome: this.repDataIscritto.cognome,
                      email: this.repDataIscritto.email,
                      password: '',
                      indirizzo: this.repDataIscritto.indirizzo,
                      professione: this.repDataIscritto.professione,
                      cell: this.repDataIscritto.cell,
                      cell_fisso: this.repDataIscritto.cell_fisso,
                      codice_fiscale: this.repDataIscritto.codice_fiscale,
                      data_nascita: datePipe.transform(this.repDataIscritto.data_nascita, 'yyyy-MM-dd'),
                      nazione: this.repDataIscritto.nazione,
                      stato: this.repDataIscritto.state,
                      id_regione: +this.repDataIscritto.id_regione,
                      regione: this.repDataIscritto.regione,
                      id_provincia: +this.repDataIscritto.id_provincia,
                      id_comune: this.repDataIscritto.id_comune,
                      comune: this.repDataIscritto.comune,
                      cap: this.repDataIscritto.cap,
                      precedenti_iscrizioni: this.repDataIscritto.precedenti_iscrizioni ? true : false,
                      precedenti_iscrizioni_text: this.repDataIscritto.precedenti_iscrizioni,
                      precedenti_mandati: this.repDataIscritto.precedenti_mandati ? true : false,
                      precedenti_mandati_text: this.repDataIscritto.precedenti_mandati,
                      pagamento: this.repDataIscritto.pagamento ? true : false,
                      data_pagamento: this.repDataIscritto.data_pagamento,
                      flag_no_iscrizione_societa: this.repDataIscritto.iscrizione_societa_segrete,
                      flag_newsletter: this.repDataIscritto.newsletter,
                    })

                  }, err => {
                    this.openDialog('err');
                  })
                }, err => {
                  this.openDialog('err');
                })
              }, err => {
                this.openDialog('err');
              })
            } else {
              var datePipe = new DatePipe('en-US');

              this.userForm.setValue({
                nome: this.repDataIscritto.nome,
                cognome: this.repDataIscritto.cognome,
                email: this.repDataIscritto.email,
                password: '',
                indirizzo: this.repDataIscritto.indirizzo,
                professione: this.repDataIscritto.professione,
                cell: this.repDataIscritto.cell,
                cell_fisso: this.repDataIscritto.cell_fisso,
                codice_fiscale: this.repDataIscritto.codice_fiscale,
                data_nascita: datePipe.transform(this.repDataIscritto.data_nascita, 'yyyy-MM-dd'),
                nazione: this.repDataIscritto.nazione,
                stato: this.repDataIscritto.state,
                id_regione: +this.repDataIscritto.id_regione,
                regione: this.repDataIscritto.regione,
                id_provincia: +this.repDataIscritto.id_provincia,
                id_comune: this.repDataIscritto.id_comune,
                comune: this.repDataIscritto.comune,
                cap: this.repDataIscritto.cap,
                precedenti_iscrizioni: this.repDataIscritto.precedenti_iscrizioni ? true : false,
                precedenti_iscrizioni_text: this.repDataIscritto.precedenti_iscrizioni,
                precedenti_mandati: this.repDataIscritto.precedenti_mandati ? true : false,
                precedenti_mandati_text: this.repDataIscritto.precedenti_mandati,
                pagamento: this.repDataIscritto.pagamento ? true : false,
                data_pagamento: this.repDataIscritto.data_pagamento,
                flag_no_iscrizione_societa: this.repDataIscritto.iscrizione_societa_segrete,
                flag_newsletter: this.repDataIscritto.newsletter,
              })
            }
          }, err => {
            this.openDialog('err');
          })
        }

      }, err => {
        this.router.navigate(['..'], { relativeTo: this.route })
      })

    });
  }

  onSubmit() {
    let dialogRef;
    var datePipe = new DatePipe('it-IT');

    let iscritto = {
      nome: this.userForm.get('nome')?.value,
      cognome: this.userForm.get('cognome')?.value,
      email: this.userForm.get('email')?.value,
      password: this.userForm.get('password')?.value,
      indirizzo: this.userForm.get('indirizzo')?.value,
      professione: this.userForm.get('professione')?.value,
      cell: this.userForm.get('cell')?.value,
      cell_fisso: this.userForm.get('cell_fisso')?.value,
      codice_fiscale: this.userForm.get('codice_fiscale')?.value,
      data_nascita: datePipe.transform(this.userForm.get('data_nascita')?.value, 'yyyy-MM-dd'),
      nazione: this.userForm.get('nazione')?.value,
      stato: this.userForm.get('stato')?.value,
      id_regione: this.userForm.get('id_regione')?.value,
      regione: this.userForm.get('regione')?.value,
      id_provincia: this.userForm.get('id_provincia')?.value,
      id_comune: this.userForm.get('id_comune')?.value,
      comune: this.userForm.get('comune')?.value,
      cap: this.userForm.get('cap')?.value,
      precedenti_iscrizioni: this.userForm.get('precedenti_iscrizioni')?.value === true ? this.userForm.get('precedenti_iscrizioni_text')?.value : '',
      precedenti_mandati: this.userForm.get('precedenti_mandati')?.value === true ? this.userForm.get('precedenti_mandati_text')?.value : '',
      flag_no_iscrizione_societa: this.userForm.get('flag_no_iscrizione_societa')?.value || false,
      pagamento: this.userForm.get('pagamento')?.value || false,
      data_pagamento: this.userForm.get('pagamento')?.value === true ? datePipe.transform(this.userForm.get('data_pagamento')?.value, 'yyyy-MM-dd') : null,
      flag_newsletter: this.userForm.get('flag_newsletter')?.value || false
    }

    // Controllo se la password è stata inserita
    if (iscritto.password) {

      // Controlpasswordlo se la password è stata cambiata
      this.apiService.checkPassword(iscritto.password, iscritto.email).subscribe(data => {
        this.repDataCheckPassword = data;

        if (!this.repDataCheckPassword) {

          // Chiedo se vogliono procedere con la modifica della password
          dialogRef = this.dialog.open(ModificaPasswordIscrittoBackofficeDialogComponent);
          dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

              // Aggiorno la password
              this.apiService.updatePassword(iscritto.password, this.id).subscribe(data => {

                // Controllo se è stato selezionato estero
                if (!this.estero) {
                  // Recupero il nome delle localita selezionate
                  this.apiService.getLocalita(iscritto.id_regione, iscritto.id_provincia, iscritto.id_comune).subscribe(data => {

                    // Salvo Log
                    this.apiBackofficeService.addLog('Modifica Iscritto', this.id).subscribe(log => {

                      // Aggiorno il record
                      this.apiBackofficeService.updateIscritto(iscritto.nome, iscritto.cognome, iscritto.email, iscritto.cell, iscritto.cell_fisso, iscritto.codice_fiscale, iscritto.data_nascita, iscritto.indirizzo, iscritto.professione, iscritto.nazione, null, iscritto.id_regione, data.regione, iscritto.id_provincia, data.provincia, iscritto.id_comune, data.comune, null, iscritto.precedenti_iscrizioni, iscritto.precedenti_mandati, iscritto.flag_no_iscrizione_societa, iscritto.pagamento, iscritto.data_pagamento, iscritto.flag_newsletter, this.id).subscribe(data => {
                        this.openDialog('success');
                      }, error => {
                        this.openDialog('err');
                      })

                    }, err => {
                      this.openDialog('err');
                    })

                  }, errLoc => {
                    this.openDialog('err');
                  })
                } else {
                  // Salvo Log
                  this.apiBackofficeService.addLog('Modifica Iscritto', this.id).subscribe(log => {

                    // Aggiorno il record
                    this.apiBackofficeService.updateIscritto(iscritto.nome, iscritto.cognome, iscritto.email, iscritto.cell, iscritto.cell_fisso, iscritto.codice_fiscale, iscritto.data_nascita, iscritto.indirizzo, iscritto.professione, iscritto.nazione, iscritto.stato, null, iscritto.regione, null, null, null, iscritto.comune, iscritto.cap, iscritto.precedenti_iscrizioni, iscritto.precedenti_mandati, iscritto.flag_no_iscrizione_societa, iscritto.pagamento, iscritto.data_pagamento, iscritto.flag_newsletter, this.id).subscribe(data => {
                      this.openDialog('success');
                    }, error => {
                      this.openDialog('err');
                    })

                  }, err => {
                    this.openDialog('err');
                  })
                }

              }, err => {
                this.openDialog('err')
              })
            }
          });
        } else {

          // La password non è stata modificata chiedo se vuole aggiornare i dati
          dialogRef = this.dialog.open(ModificaIscrittoBackofficeDialogComponent);
          dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

              // Controllo se è stato selezionato estero
              if (!this.estero) {
                // Recupero il nome delle localita selezionate
                this.apiService.getLocalita(iscritto.id_regione, iscritto.id_provincia, iscritto.id_comune).subscribe(data => {

                  // Salvo Log
                  this.apiBackofficeService.addLog('Modifica Iscritto', this.id).subscribe(log => {

                    // Aggiorno il record
                    this.apiBackofficeService.updateIscritto(iscritto.nome, iscritto.cognome, iscritto.email, iscritto.cell, iscritto.cell_fisso, iscritto.codice_fiscale, iscritto.data_nascita, iscritto.indirizzo, iscritto.professione, iscritto.nazione, null, iscritto.id_regione, data.regione, iscritto.id_provincia, data.provincia, iscritto.id_comune, data.comune, null, iscritto.precedenti_iscrizioni, iscritto.precedenti_mandati, iscritto.flag_no_iscrizione_societa, iscritto.pagamento, iscritto.data_pagamento, iscritto.flag_newsletter, this.id).subscribe(data => {
                      this.openDialog('success');
                    }, error => {
                      this.openDialog('err');
                    })

                  }, err => {
                    this.openDialog('err');
                  })

                }, errLoc => {
                  this.openDialog('err');
                })
              } else {
                // Salvo Log
                this.apiBackofficeService.addLog('Modifica Iscritto', this.id).subscribe(log => {

                  // Aggiorno il record
                  this.apiBackofficeService.updateIscritto(iscritto.nome, iscritto.cognome, iscritto.email, iscritto.cell, iscritto.cell_fisso, iscritto.codice_fiscale, iscritto.data_nascita, iscritto.indirizzo, iscritto.professione, iscritto.nazione, iscritto.stato, null, iscritto.regione, null, null, null, iscritto.comune, iscritto.cap, iscritto.precedenti_iscrizioni, iscritto.precedenti_mandati, iscritto.flag_no_iscrizione_societa, iscritto.pagamento, iscritto.data_pagamento, iscritto.flag_newsletter, this.id).subscribe(data => {
                    this.openDialog('success');
                  }, error => {
                    this.openDialog('err');
                  })

                }, err => {
                  this.openDialog('err');
                })
              }

            }
          })
        }
      }, error => {
        this.openDialog('err')
      })
    } else {

      // La password non è stata inserita chiedo se vuole modificare i dati
      dialogRef = this.dialog.open(ModificaIscrittoBackofficeDialogComponent);
      dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {

          // Controllo se è stato selezionato estero
          if (!this.estero) {

            // Recupero il nome delle localita selezionate
            this.apiService.getLocalita(iscritto.id_regione, iscritto.id_provincia, iscritto.id_comune).subscribe(data => {

              // Salvo Log
              this.apiBackofficeService.addLog('Modifica Iscritto', this.id).subscribe(log => {

                // Aggiorno il record
                this.apiBackofficeService.updateIscritto(iscritto.nome, iscritto.cognome, iscritto.email, iscritto.cell, iscritto.cell_fisso, iscritto.codice_fiscale, iscritto.data_nascita, iscritto.indirizzo, iscritto.professione, iscritto.nazione, null, iscritto.id_regione, data.regione, iscritto.id_provincia, data.provincia, iscritto.id_comune, data.comune, null, iscritto.precedenti_iscrizioni, iscritto.precedenti_mandati, iscritto.flag_no_iscrizione_societa, iscritto.pagamento, iscritto.data_pagamento, iscritto.flag_newsletter, this.id).subscribe(data => {

                  this.openDialog('success');
                }, error => {
                  this.openDialog('err');
                })

              }, err => {
                this.openDialog('err');
              })

            }, errLoc => {
              this.openDialog('err');
            })
          } else {
            // Salvo Log
            this.apiBackofficeService.addLog('Modifica Iscritto', this.id).subscribe(log => {

              // Aggiorno il record
              this.apiBackofficeService.updateIscritto(iscritto.nome, iscritto.cognome, iscritto.email, iscritto.cell, iscritto.cell_fisso, iscritto.codice_fiscale, iscritto.data_nascita, iscritto.indirizzo, iscritto.professione, iscritto.nazione, iscritto.stato, null, iscritto.regione, null, null, null, iscritto.comune, iscritto.cap, iscritto.precedenti_iscrizioni, iscritto.precedenti_mandati, iscritto.flag_no_iscrizione_societa, iscritto.pagamento, iscritto.data_pagamento, iscritto.flag_newsletter, this.id).subscribe(data => {

                this.openDialog('success');
              }, error => {
                this.openDialog('err');
              })

            }, err => {
              this.openDialog('err');
            })
          }

        }
      })
    }
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else if (type === 'pass') {
      dialogRef = this.dialog.open(ModificaPasswordIscrittoBackofficeDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }
  }

  setEstero(codice_nazione: string) {

    this.userForm.patchValue({
      id_regione: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.id_regione,
      id_provincia: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.id_provincia,
      id_comune: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.id_comune,
      stato: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.state,
      cap: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.cap,
      regione: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.regione,
      comune: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.comune,
      cell: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.cell,
      cell_fisso: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.cell_fisso
    })

    if (codice_nazione == 'IT') {
      this.estero = false;

      this.userForm.get('id_regione')?.setValidators(Validators.required);
      this.userForm.get('id_provincia')?.setValidators(Validators.required);
      this.userForm.get('id_comune')?.setValidators(Validators.required);
      this.userForm.get('stato')?.clearValidators();
      this.userForm.get('regione')?.clearValidators();
      this.userForm.get('comune')?.clearValidators();
    } else {
      this.estero = true;

      this.userForm.get('id_regione')?.clearValidators();
      this.userForm.get('id_provincia')?.clearValidators();
      this.userForm.get('id_comune')?.clearValidators();
      this.userForm.get('stato')?.setValidators(Validators.required);
      this.userForm.get('regione')?.setValidators(Validators.required);
      this.userForm.get('comune')?.setValidators(Validators.required);
    }

    this.userForm.get('id_regione')?.updateValueAndValidity();
    this.userForm.get('id_provincia')?.updateValueAndValidity();
    this.userForm.get('id_comune')?.updateValueAndValidity();
    this.userForm.get('stato')?.updateValueAndValidity();
    this.userForm.get('regione')?.updateValueAndValidity();
    this.userForm.get('cap')?.updateValueAndValidity();
    this.userForm.get('comune')?.updateValueAndValidity();
    this.userForm.get('cell')?.updateValueAndValidity();
    this.userForm.get('cell_fisso')?.updateValueAndValidity();
  }

  setRegione(codice_nazione: string) {
    this.setEstero(codice_nazione);
    this.apiService.getNazione(codice_nazione).subscribe(data => {
      this.repData = data;
      if (this.repData) {
        this.apiService.getRegioni(this.repData.id_nazione).subscribe(data => {
          this.regioni = data;
        })
      }
    });
  }

  setProvincie(id_regione: number) {
    this.apiService.getProvince(id_regione).subscribe(data => {
      this.province = data;
    })
  }

  setComuni(id_provincia: number) {
    this.apiService.getComuni(id_provincia).subscribe(data => {
      this.comuni = data;
    })
  }

  onGoBack() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  onRadio(radio: boolean, type: string) {
    var field = type === 'iscrizioni' ? 'precedenti_iscrizioni_text' : 'precedenti_mandati_text';

    if (radio) {
      this.userForm.get(field)?.setValidators(Validators.required)
    } else {
      this.userForm.get(field)?.clearValidators();
    }

    this.userForm.get(field)?.updateValueAndValidity();
  }
  
  onPagamento(type: boolean) {

    if (type) {
      this.userForm.get('data_pagamento')?.setValidators(Validators.required);
    } else {
      this.userForm.get('data_pagamento')?.clearValidators();
    }

    this.userForm.get('data_pagamento')?.updateValueAndValidity();
    
  }

  onRevoca() {
    let dialogRef;

    dialogRef = this.dialog.open(DeleteIscrittoBackofficeDialogComponent, { data: { nome: this.repDataIscritto.nome, cognome: this.repDataIscritto.cognome, email: this.repDataIscritto.email } });
    dialogRef?.afterClosed().subscribe((confirmed: { confirm: boolean, message: string }) => {
      if (confirmed.confirm) {

        // Salvo Log
        this.apiBackofficeService.addLog('Revoca Iscritto', this.repDataIscritto.id).subscribe(log => {

          this.apiBackofficeService.deleteIscritto(this.repDataIscritto.id, this.repDataIscritto.email, confirmed.message).subscribe(data => {
            this.apiBackofficeService.setIscritti();
            this.router.navigate(['../..'], { relativeTo: this.route })
          }, err => {
            this.openDialog('err');
          })

        }, err => {
          this.openDialog('err');
        })

      }
    });
  }
}
