<div class="form">
    <div class="col-xs-12 col-md-offset-3 col-md-6">
        <div class="form-image">
            <img src="../../../assets/logo.png" alt="logo alternativa">
        </div>
        <div class="item">
            <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input type="text" name="username" id="username" class="form-control" formControlName="username">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" name="password" id="password" class="form-control" formControlName="password" autocomplete>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <p *ngIf="error" class="alert alert-danger">
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <div class="row">
                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <button type="submit" id="accedi" name="accedi" class="alternativa-css">ACCEDI</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>