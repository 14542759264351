import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Utente } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { DeleteUtenteBackofficeDialogComponent } from './delete-utente-backoffice-dialog/delete-utente-backoffice-dialog.component';


@Component({
  selector: 'app-elenco-utenti-backoffice',
  templateUrl: './elenco-utenti-backoffice.component.html',
  styleUrls: ['./elenco-utenti-backoffice.component.css']
})
export class ElencoUtentiBackofficeComponent implements OnInit {

  constructor(private apiBackofficeService: ApiBackofficeService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog, private titleService : Title) { }  

  displayedColumns = ['username', 'tipo', 'regione', 'actions'];
  dataSource!: MatTableDataSource<Utente>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  Utenti: Utente[] = [];
  subscription! : Subscription;

  usernameFilter = new FormControl();
  tipoFilter = new FormControl();
  globalFilter = '';

  filteredValues = {
    username: '',  tipo: ''
  };

  ngOnInit(): void {

    this.titleService.setTitle('Alternativa Backoffice - Elenco Utenti');    

    this.apiBackofficeService.getUserData().subscribe(data => {
      if (data.tipo != 'Amministratore') {        
        this.router.navigate(['/backoffice/homepage']);
      }
    })

    this.apiBackofficeService.setUtenti();
    this.subscription = this.apiBackofficeService.utenteChanged.subscribe(
      (utente: Utente[]) => {
        this.Utenti = utente;          
        this.dataSource = new MatTableDataSource<Utente>(this.Utenti);
        this.paginator._intl.itemsPerPageLabel = 'Utenti per pagina';
        this.paginator._intl.nextPageLabel = 'Pagina successiva';
        this.paginator._intl.previousPageLabel = 'Pagina precedente';
        this.dataSource.paginator = this.paginator;   
        this.dataSource.sort = this.sort;                        

        this.usernameFilter.valueChanges.subscribe((usernameFilterValue) => {          
          this.filteredValues['username'] =  usernameFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });
    
        this.tipoFilter.valueChanges.subscribe((tipoFilterValue) => {
          this.filteredValues['tipo'] = tipoFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.dataSource.filterPredicate = this.customFilterPredicate();

      }
    )
  
    this.Utenti = this.apiBackofficeService.getUtenti();  
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: Utente, filter: string): boolean => {
      var globalMatch = !this.globalFilter;      

      if (this.globalFilter) {        
        // search all text fields
        globalMatch = data.username.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1;        
      }

      if (!globalMatch) {
        return false;
      }
      

      let searchString = JSON.parse(filter);      
      return data.username.toString().trim().toLowerCase().indexOf(searchString.username.toLowerCase()) !== -1 &&
        data.tipo.toString().trim().toLowerCase().indexOf(searchString.tipo.toLowerCase()) !== -1         
    }
    return myFilterPredicate;
  }

  onEdit(id: number) {
    this.router.navigate([id], {relativeTo: this.route});
  }

  onDelete(id: number, username: string) {
    let dialogRef;

    dialogRef = this.dialog.open(DeleteUtenteBackofficeDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.apiBackofficeService.addLog('Eliminazione utente: ' +username).subscribe(log => {
          this.apiBackofficeService.deleteUtente(id).subscribe(data => {
            this.apiBackofficeService.setUtenti();
          }, err => {
            this.openDialog('err');
          })            
        }, err => {
          this.openDialog('err');
        })
        
      }
    });
    
  }

  onClearFilter() {
    this.dataSource.filter = '';    
    this.usernameFilter.setValue('');
    this.tipoFilter.setValue('');
  }

  onAddUtente() {
    this.router.navigate(['../add-utente'], {relativeTo: this.route});
  }

  onLogs(id: number) {
    this.router.navigate(['../log'], {queryParams: {utente: id}, relativeTo: this.route})
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);    
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }
}
