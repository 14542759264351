import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-eliminazione-documenti-iscritti-backoffice-dialog',
  templateUrl: './eliminazione-documenti-iscritti-backoffice-dialog.component.html',
  styleUrls: ['./eliminazione-documenti-iscritti-backoffice-dialog.component.css']
})
export class EliminazioneDocumentiIscrittiBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EliminazioneDocumentiIscrittiBackofficeDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }
}
