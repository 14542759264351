import { Injectable } from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { exhaustMap, take } from "rxjs/operators";
import { ApiService } from "./api.service";


@Injectable()
export class ApiInterceptorService implements HttpInterceptor{
        
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {    
  
        if (req.url.includes('v1.0')) {           
            let token: string = localStorage.getItem('token') || '';
            const modifiedReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`),
              }); 
            return next.handle(modifiedReq);                      
        }
        else {
            return next.handle(req);       
        }
      }
}
