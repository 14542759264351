import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTableExporterModule } from 'mat-table-exporter';
import { AngularEditorModule } from '@kolkov/angular-editor';
import localeIT from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.model';
import { HomepageComponent } from './homepage/homepage.component';
import { RegolamentoComponent } from './regolamento/regolamento.component';
import { AreaPersonaleComponent } from './area-personale/area-personale.component';
import { LoginComponent } from './homepage/login/login.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { IscrizioniComponent } from './iscrizioni/iscrizioni.component';
import { AutenticazioneComponent } from './autenticazione/autenticazione.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { LoginBackofficeComponent } from './backoffice/login-backoffice/login-backoffice.component';
import { HomepageBackofficeComponent } from './backoffice/homepage-backoffice/homepage-backoffice.component';
import { ModificaPasswordAreaPersonaleComponent } from './area-personale/modifica-password-area-personale/modifica-password-area-personale.component';
import { ModificaDocumentiAreaPersonaleComponent } from './area-personale/modifica-documenti-area-personale/modifica-documenti-area-personale.component';
import { ModificaDocumentiErrorAreaPersonaleComponent } from './area-personale/modifica-documenti-error-area-personale/modifica-documenti-error-area-personale.component';
import { ModificaConfermaAreaPersonaleComponent } from './area-personale/modifica-conferma-area-personale/modifica-conferma-area-personale.component';
import { EditIscrittoBackofficeComponent } from './backoffice/homepage-backoffice/edit-iscritto-backoffice/edit-iscritto-backoffice.component';
import { DocumentiIscrittiBackofficeComponent } from './backoffice/homepage-backoffice/documenti-iscritti-backoffice/documenti-iscritti-backoffice.component';
import { ElencoIscrittiBackofficeComponent } from './backoffice/homepage-backoffice/elenco-iscritti-backoffice/elenco-iscritti-backoffice.component';
import { ElencoUtentiBackofficeComponent } from './backoffice/homepage-backoffice/elenco-utenti-backoffice/elenco-utenti-backoffice.component';
import { EditUtenteBackofficeComponent } from './backoffice/homepage-backoffice/edit-utente-backoffice/edit-utente-backoffice.component';
import { AddUtenteBackofficeComponent } from './backoffice/homepage-backoffice/add-utente-backoffice/add-utente-backoffice.component';
import { SendEmailBackofficeComponent } from './backoffice/homepage-backoffice/send-email-backoffice/send-email-backoffice.component';
import { SendSmsBackofficeComponent } from './backoffice/homepage-backoffice/send-sms-backoffice/send-sms-backoffice.component';
import { FormResetPasswordComponent } from './form-reset-password/form-reset-password.component';
import { ModificaPasswordIscrittoBackofficeDialogComponent } from './backoffice/homepage-backoffice/edit-iscritto-backoffice/modifica-password-iscritto-backoffice-dialog/modifica-password-iscritto-backoffice-dialog.component';
import { ModificaIscrittoBackofficeDialogComponent } from './backoffice/homepage-backoffice/edit-iscritto-backoffice/modifica-iscritto-backoffice-dialog/modifica-iscritto-backoffice-dialog.component';
import { ModificaUtentePasswordDialogComponent } from './backoffice/homepage-backoffice/edit-utente-backoffice/modifica-utente-password-dialog/modifica-utente-password-dialog.component';
import { ModificaUtenteBackofficeDialogComponent } from './backoffice/homepage-backoffice/edit-utente-backoffice/modifica-utente-backoffice-dialog/modifica-utente-backoffice-dialog.component';
import { RevocaAreaPersonaleDialogComponent } from './area-personale/revoca-area-personale-dialog/revoca-area-personale-dialog.component';
import { DeleteUtenteBackofficeDialogComponent } from './backoffice/homepage-backoffice/elenco-utenti-backoffice/delete-utente-backoffice-dialog/delete-utente-backoffice-dialog.component';
import { DeleteIscrittoBackofficeDialogComponent } from './backoffice/homepage-backoffice/elenco-iscritti-backoffice/delete-iscritto-backoffice-dialog/delete-iscritto-backoffice-dialog.component';
import { DoubleIscrittoDialogComponent } from './iscrizioni/double-iscritto-dialog/double-iscritto-dialog.component';
import { NewIscrittoDialogComponent } from './iscrizioni/new-iscritto-dialog/new-iscritto-dialog.component';
import { ModificaAreaPersonaleDialogComponent } from './area-personale/modifica-area-personale-dialog/modifica-area-personale-dialog.component';
import { EsciAreaPersonaleDialogComponent } from './area-personale/esci-area-personale-dialog/esci-area-personale-dialog.component';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { FormResetPasswordDialogComponent } from './form-reset-password/form-reset-password-dialog/form-reset-password-dialog.component';
import { FormSendEmailBackofficeDialogComponent } from './backoffice/homepage-backoffice/send-email-backoffice/form-send-email-backoffice-dialog/form-send-email-backoffice-dialog.component';
import { SuccessDialogComponent } from './shared/success-dialog/success-dialog.component';
import { FormSendSmsBackofficeDialogComponent } from './backoffice/homepage-backoffice/send-sms-backoffice/form-send-sms-backoffice-dialog/form-send-sms-backoffice-dialog.component';
import { SendEmailConfermaBackofficeDialogComponent } from './backoffice/homepage-backoffice/elenco-iscritti-backoffice/send-email-conferma-backoffice-dialog/send-email-conferma-backoffice-dialog.component';
import { ApiInterceptorService } from './shared/api-interceptor.service';
import { EliminazioneDocumentiIscrittiBackofficeDialogComponent } from './backoffice/homepage-backoffice/documenti-iscritti-backoffice/eliminazione-documenti-iscritti-backoffice-dialog/eliminazione-documenti-iscritti-backoffice-dialog.component';
import { AttivazioneDocumentiIscrittiBackofficeDialogComponent } from './backoffice/homepage-backoffice/documenti-iscritti-backoffice/attivazione-documenti-iscritti-backoffice-dialog/attivazione-documenti-iscritti-backoffice-dialog.component';
import { LoadingDialogComponent } from './shared/loading-dialog/loading-dialog.component';
import { LogsBackofficeComponent } from './backoffice/homepage-backoffice/logs-backoffice/logs-backoffice.component';
import { ConfermaIscrizioneDialogComponent } from './area-personale/conferma-iscrizione-dialog/conferma-iscrizione-dialog.component';
import { EmailIscrittoSendEmailBackofficeDialogComponent } from './backoffice/homepage-backoffice/send-email-backoffice/email-iscritto-send-email-backoffice-dialog/email-iscritto-send-email-backoffice-dialog.component';
import { ElencoComunicazioniBackofficeComponent } from './backoffice/homepage-backoffice/elenco-comunicazioni-backoffice/elenco-comunicazioni-backoffice.component';
import { SmsIscrittoSendSmsBackofficeDialogComponent } from './backoffice/homepage-backoffice/send-sms-backoffice/sms-iscritto-send-sms-backoffice-dialog/sms-iscritto-send-sms-backoffice-dialog.component';
import { EditComunicazioneBackofficeComponent } from './backoffice/homepage-backoffice/edit-comunicazione-backoffice/edit-comunicazione-backoffice.component';
import { AnnulloSendEmailBackofficeDialogComponent } from './backoffice/homepage-backoffice/send-email-backoffice/annullo-send-email-backoffice-dialog/annullo-send-email-backoffice-dialog.component';
import { AnnulloSendSmsBackofficeDialogComponent } from './backoffice/homepage-backoffice/send-sms-backoffice/annullo-send-sms-backoffice-dialog/annullo-send-sms-backoffice-dialog.component';

registerLocaleData(localeIT, 'it');
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    RegolamentoComponent,
    AreaPersonaleComponent,
    IscrizioniComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DoubleIscrittoDialogComponent,
    NewIscrittoDialogComponent,    
    ModificaAreaPersonaleDialogComponent,
    RevocaAreaPersonaleDialogComponent,
    EsciAreaPersonaleDialogComponent,
    AutenticazioneComponent,
    BackofficeComponent,
    LoginBackofficeComponent,
    HomepageBackofficeComponent,
    ModificaPasswordAreaPersonaleComponent,
    ModificaDocumentiAreaPersonaleComponent,
    ModificaDocumentiErrorAreaPersonaleComponent,
    ModificaConfermaAreaPersonaleComponent,  
    DeleteIscrittoBackofficeDialogComponent,
    EditIscrittoBackofficeComponent,
    ElencoIscrittiBackofficeComponent,
    DocumentiIscrittiBackofficeComponent,
    ElencoUtentiBackofficeComponent,
    DeleteUtenteBackofficeDialogComponent,
    ModificaUtenteBackofficeDialogComponent,
    ModificaIscrittoBackofficeDialogComponent,
    EditUtenteBackofficeComponent,
    ModificaUtentePasswordDialogComponent,
    ModificaPasswordIscrittoBackofficeDialogComponent,  
    AttivazioneDocumentiIscrittiBackofficeDialogComponent,    
    EliminazioneDocumentiIscrittiBackofficeDialogComponent,
    AddUtenteBackofficeComponent,
    SendEmailBackofficeComponent,
    SendSmsBackofficeComponent,    
    FormSendEmailBackofficeDialogComponent,
    FormResetPasswordComponent,    
    ErrorDialogComponent,
    FormResetPasswordDialogComponent,
    SuccessDialogComponent,
    FormSendSmsBackofficeDialogComponent,
    SendEmailConfermaBackofficeDialogComponent,    
    LoadingDialogComponent,    
    LogsBackofficeComponent, 
    ConfermaIscrizioneDialogComponent, 
    EmailIscrittoSendEmailBackofficeDialogComponent, 
    ElencoComunicazioniBackofficeComponent,
    SmsIscrittoSendSmsBackofficeDialogComponent, 
    EditComunicazioneBackofficeComponent, 
    AnnulloSendEmailBackofficeDialogComponent, 
    AnnulloSendSmsBackofficeDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,    
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSortModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatTabsModule,    
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatTableExporterModule,    
    AngularEditorModule,
    NgxMatIntlTelInputModule    
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "it" },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
