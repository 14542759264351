<mat-dialog-content>
    <p>Una mail ed un sms verranno inviati all'indirizzo e al numero di cellulare salvati per confermare il tuo numero di cellulare.
        <br>Vuoi procedere?.
    </p>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onConfirmClick()" class="alternativa-css">Ok</button>
            </div>
        </div>
    </div>
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onCloseClick()" class="alternativa-css">Chiudi</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>