import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AreaPersonaleComponent } from "./area-personale/area-personale.component";
import { AutenticazioneComponent } from "./autenticazione/autenticazione.component";
import { BackofficeComponent } from "./backoffice/backoffice.component";
import { AddUtenteBackofficeComponent } from "./backoffice/homepage-backoffice/add-utente-backoffice/add-utente-backoffice.component";
import { DocumentiIscrittiBackofficeComponent } from "./backoffice/homepage-backoffice/documenti-iscritti-backoffice/documenti-iscritti-backoffice.component";
import { EditComunicazioneBackofficeComponent } from "./backoffice/homepage-backoffice/edit-comunicazione-backoffice/edit-comunicazione-backoffice.component";
import { EditIscrittoBackofficeComponent } from "./backoffice/homepage-backoffice/edit-iscritto-backoffice/edit-iscritto-backoffice.component";
import { EditUtenteBackofficeComponent } from "./backoffice/homepage-backoffice/edit-utente-backoffice/edit-utente-backoffice.component";
import { ElencoComunicazioniBackofficeComponent  } from "./backoffice/homepage-backoffice/elenco-comunicazioni-backoffice/elenco-comunicazioni-backoffice.component";
import { ElencoIscrittiBackofficeComponent } from "./backoffice/homepage-backoffice/elenco-iscritti-backoffice/elenco-iscritti-backoffice.component";
import { ElencoUtentiBackofficeComponent } from "./backoffice/homepage-backoffice/elenco-utenti-backoffice/elenco-utenti-backoffice.component";
import { HomepageBackofficeComponent } from "./backoffice/homepage-backoffice/homepage-backoffice.component";
import { LogsBackofficeComponent } from "./backoffice/homepage-backoffice/logs-backoffice/logs-backoffice.component";
import { SendEmailBackofficeComponent } from "./backoffice/homepage-backoffice/send-email-backoffice/send-email-backoffice.component";
import { SendSmsBackofficeComponent } from "./backoffice/homepage-backoffice/send-sms-backoffice/send-sms-backoffice.component";
import { LoginBackofficeComponent } from "./backoffice/login-backoffice/login-backoffice.component";
import { FormResetPasswordComponent } from "./form-reset-password/form-reset-password.component";
import { HomepageComponent } from "./homepage/homepage.component";
import { IscrizioniComponent } from "./iscrizioni/iscrizioni.component";
import { RegolamentoComponent } from "./regolamento/regolamento.component";
import { AuthBackofficeGuard } from "./shared/auth-backoffice.guard";
import { AuthGuard } from "./shared/auth.guard";

const appRoutes : Routes = [
    { path: '', component: HomepageComponent, pathMatch: 'full'},
    { path: 'iscrizioni', component: IscrizioniComponent},
    { path: 'regolamento', component: RegolamentoComponent},
    { path: 'area-personale', component: AreaPersonaleComponent, canActivate: [AuthGuard]},   
    { path: 'autenticazione', component: AutenticazioneComponent},     
    { path: 'reset-password', component: FormResetPasswordComponent}, 
    { path: 'backoffice', component: BackofficeComponent, children: [
        { path: '', component: LoginBackofficeComponent, pathMatch: 'full' },
        { path: 'homepage', component: HomepageBackofficeComponent, canActivate: [AuthBackofficeGuard] , children: [
            { path: '', component: ElencoIscrittiBackofficeComponent},
            { path: 'iscritto/:id', component: EditIscrittoBackofficeComponent},
            { path: 'documenti/:id', component: DocumentiIscrittiBackofficeComponent},
            { path: 'utenti', component: ElencoUtentiBackofficeComponent},            
            { path: 'utenti/:id', component: EditUtenteBackofficeComponent},
            { path: 'add-utente', component: AddUtenteBackofficeComponent},
            { path: 'email', component: SendEmailBackofficeComponent},                                    
            { path: 'sms', component: SendSmsBackofficeComponent},            
            { path: 'log', component: LogsBackofficeComponent}, 
            { path: 'comunicazioni', component: ElencoComunicazioniBackofficeComponent},  
            { path: 'comunicazioni/:id', component: EditComunicazioneBackofficeComponent},             
        ]},        
    ]},       
    { path: '**', redirectTo: ''},
]

@NgModule ({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
}) 

export class AppRoutingModule {

}