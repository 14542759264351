<div class="container-iscritti">
    <div class="col-xs-12">
        <mat-toolbar>
            <mat-toolbar-row>
                <span style="font-weight: 900;">COMUNICAZIONI SMS</span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="onGoBack()">
                    <mat-icon>backspace</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="send-sms">
            <form (ngSubmit)="onSubmit()" [formGroup]="formSMS">
                <div class="col-xs-12 form">
                    <div class="row">
                        <div class="col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Testo Messaggio SMS</mat-label>
                                <textarea matInput formControlName="messaggio" rows="10" required></textarea>
                            </mat-form-field>
                            <div class="error-messages">
                                <ng-container *ngFor="let error of error_messages.messaggio">
                                    <div class="error-message" *ngIf="formSMS.get('messaggio')?.hasError(error.type) && (formSMS.get('messaggio')?.dirty || formSMS.get('messaggio')?.touched)">
                                        <p class="alert alert-danger">{{error.message}}</p>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col xs-12">
                    <div class="button-row text-center">
                        <button mat-raised-button type="submit" color="success" [disabled]="!formSMS.valid">Invia SMS</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>