import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiBackofficeService } from './api-backoffice.service';
import { AuthResponse } from './interface';

@Injectable({
  providedIn: 'root'
})
export class AuthBackofficeService {
  url_database: string = environment.apiHost;

  token = new BehaviorSubject<string | null>(null);  

  private tokenExpirationTime: any;

  constructor(private http: HttpClient, private apiBackofficeService: ApiBackofficeService, private router: Router) { }  

  login(username: string, password: string) {        
    return this.http.post<AuthResponse>(this.url_database + 'api/v1.0/login_bo', {username: username, password: password, expires: new Date(new Date().getTime() + 3500 * 1000) }).pipe(
      tap(
        resposeData => {                
          if (resposeData) {            
            this.handleAuth(resposeData.token)
          }
        }
      )
    );
  }

  AutoLogin() {    
    var token: any = localStorage.getItem('AuthBo');        
    this.token.next(token);

    this.apiBackofficeService.getUserData().subscribe(data => {                
      const expirationDuration = new Date(data.expires).getTime() - new Date().getTime();      
      this.autoLogout(expirationDuration);

      if (data.message) {
        this.Logout();
      }
    }, err => {
      this.Logout();
    })    
  }

  Logout() {
    localStorage.removeItem('AuthBo');  
    this.token.next(null); 
    this.router.navigate(['/backoffice'])
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTime = setTimeout(() => {
      this.Logout();
    }, expirationDuration);
  }

  private handleAuth(token: string) {         
    localStorage.setItem('AuthBo', token); 
    this.token.next(token);
  }
}