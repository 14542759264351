import { FormControl } from "@angular/forms";

export function requiredFileType() {
  return function (control: FormControl) {
    const file = control.value;    
    if ( file ) {                 

      if (file.name.toLowerCase().endsWith('png')) {     
          return null;   
      } else if (file.name.toLowerCase().endsWith('jpg')) {
          return null;
      } else if (file.name.toLowerCase().endsWith('jpeg')) {     
          return null;
      } else {
          return { requiredFileType: true }; 
      }              
    }
  
    return null;
  };
}

export function requiredMaxFileSize() {
  return function (control: FormControl) {
    const file = control.value;    
    if ( file ) {                       
      if (file.size <= 20000000) {            
          return null;   
      } else {
          return { requiredMaxFileSize: true }; 
      }
              
    }
  
    return null;
  };
}

export function requiredMinYearType() {
  return function (control: FormControl) {
    const date: Date = control.value;
    if ( date ) {      
      var timeDiff = Math.abs(Date.now() - new Date(date).getTime());
      var age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      
      if ( age > 16) {     
          return null;         
      } else {
          return { requiredMinYearType: true }; 
      }
                  
    }
  
    return null;
  };
}