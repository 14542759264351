import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Iscritto, UserBackoffice, UserBackofficeResponse } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { DeleteIscrittoBackofficeDialogComponent } from './delete-iscritto-backoffice-dialog/delete-iscritto-backoffice-dialog.component';
import { SendEmailConfermaBackofficeDialogComponent } from './send-email-conferma-backoffice-dialog/send-email-conferma-backoffice-dialog.component';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-elenco-iscritti-backoffice',
  templateUrl: './elenco-iscritti-backoffice.component.html',
  styleUrls: ['./elenco-iscritti-backoffice.component.css']
})
export class ElencoIscrittiBackofficeComponent implements OnInit, OnDestroy {

  constructor(private dialog: MatDialog, private apiBackofficeService: ApiBackofficeService, private router: Router, private route: ActivatedRoute, private titleService: Title) { }

  displayedColumns: Array<string> = ['anagrafica', 'email', 'cell', 'cell_fisso', 'codice_fiscale', 'data_nascita', 'indirizzo', 'professione', 'nazione', 'regione', 'provincia', 'comune', 'precedenti_iscrizioni', 'precedenti_mandati', 'newsletter', 'created_on', 'stato', 'actions']
  dataSource!: MatTableDataSource<Iscritto>;
  selection!: SelectionModel<Iscritto>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  userBackoffice!: UserBackofficeResponse;

  Iscritti: Iscritto[] = [];

  subscription!: Subscription;

  variabili!: { pagina: number, iscritti: number, confermati: boolean, donatori: boolean };

  showNonConfermati?: boolean = false;
  showDonatori?: boolean = false;
  visible?: boolean;

  emailComunication?: boolean;
  smsComunication?: boolean;

  amminstratore?: boolean;

  anagraficaFilter = new FormControl();
  regioneFilter = new FormControl();
  provinciaFilter = new FormControl();
  emailFilter = new FormControl();
  nazioneFilter = new FormControl();
  statoFilter = new FormControl();
  checkboxForm = new FormControl();

  filteredValues = {
    email: '', regione: '', provincia: '', stato: '', nazione: '', anagrafica: ''
  };

  ngOnInit(): void {
    this.titleService.setTitle('Alternativa Backoffice - Elenco Iscritti');

    this.apiBackofficeService.getUserData().subscribe(data => {
      this.userBackoffice = data;

      if (data.tipo === 'Amministratore') {
        this.amminstratore = true;
      }
    })

    this.getData();
  }

  onToggleShowNonConfermati() {
    this.showNonConfermati = !this.showNonConfermati;

    this.saveVariable();
    this.getData();
  }


  onToggleShowDonatori() {
    this.showDonatori = !this.showDonatori;

    this.saveVariable();
    this.getData();
  }

  onReload() {
    this.smsComunication = false;
    this.emailComunication = false;

    this.saveVariable()
    this.getData()
  }

  getData() {

    this.variabili = JSON.parse(localStorage.getItem('variabili') || '{}');
    this.showNonConfermati = this.variabili?.confermati ? this.variabili?.confermati : false;
    this.showDonatori = this.variabili?.donatori ? this.variabili?.donatori : false;

    this.selection = new SelectionModel<Iscritto>(true, []);

    this.displayedColumns = this.visible ? ['checkbox', 'anagrafica', 'email', 'cell', 'cell_fisso', 'codice_fiscale', 'data_nascita', 'indirizzo', 'professione', 'nazione', 'regione', 'provincia', 'comune', 'precedenti_iscrizioni', 'precedenti_mandati', 'newsletter', 'created_on', 'stato', 'actions'] : this.displayedColumns;

    this.apiBackofficeService.setIscritti(this.showNonConfermati, this.showDonatori);
    this.subscription = this.apiBackofficeService.iscrittoChanged.subscribe(
      (iscritto: Iscritto[]) => {
        this.Iscritti = iscritto;
        this.dataSource = new MatTableDataSource<Iscritto>(this.Iscritti);
        this.dataSource.paginator = this.paginator;

        this.paginator._intl.itemsPerPageLabel = 'Iscritti per pagina';
        this.paginator._intl.nextPageLabel = 'Pagina successiva';
        this.paginator._intl.previousPageLabel = 'Pagina precedente';

        this.paginator.pageSize = this.variabili.iscritti;
        this.paginator.pageIndex = this.variabili.pagina;

        this.anagraficaFilter.valueChanges.subscribe((anagraficaFilterValue) => {
          this.filteredValues['anagrafica'] = anagraficaFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.emailFilter.valueChanges.subscribe((emailFilterValue) => {
          this.filteredValues['email'] = emailFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.nazioneFilter.valueChanges.subscribe((nazioneFilterValue) => {
          this.filteredValues['nazione'] = nazioneFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.regioneFilter.valueChanges.subscribe((regioneFilterValue) => {
          this.filteredValues['regione'] = regioneFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.provinciaFilter.valueChanges.subscribe((provinciaFilterValue) => {
          this.filteredValues['provincia'] = provinciaFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.statoFilter.valueChanges.subscribe((statoFilterValue) => {
          this.filteredValues['stato'] = statoFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.customFilterPredicate();

        var filtri: { anagrafica?: string, email?: string, nazione?: string, regione?: string, provincia?: string, stato?: string } = JSON.parse(localStorage.getItem('filtri') || '{}');
        this.statoFilter.setValue(filtri?.stato ? filtri.stato : '');
        this.emailFilter.setValue(filtri?.email ? filtri.email : '');
        this.nazioneFilter.setValue(filtri?.nazione ? filtri.nazione : '');
        this.regioneFilter.setValue(filtri?.regione ? filtri.regione : '');
        this.provinciaFilter.setValue(filtri?.provincia ? filtri.provincia : '');
        this.anagraficaFilter.setValue(filtri?.anagrafica ? filtri.anagrafica : '');

      }
    )

    this.Iscritti = this.apiBackofficeService.getIscritti();
  }

  customFilterPredicate() {

    const myFilterPredicate = (data: Iscritto, filter: string): boolean => {

      let searchString = JSON.parse(filter);

      return data.email.toString().trim().toLowerCase().indexOf(searchString.email.toLowerCase()) !== -1 &&
        data.regione?.toString().trim().toLowerCase().indexOf(searchString.regione.toLowerCase()) !== -1 &&
        data.provincia?.toString().trim().toLowerCase().indexOf(searchString.provincia.toLowerCase()) !== -1 &&
        data.stato?.toString().trim().toLowerCase().indexOf(searchString.stato.toLowerCase()) !== -1 &&
        data.nazione.toString().trim().toLowerCase().indexOf(searchString.nazione.toLowerCase()) !== -1 &&
        (data.nome?.toString().trim().toLocaleLowerCase().indexOf(searchString.anagrafica.toLocaleLowerCase()) !== -1 ||
          data.cognome.toString().trim().toLocaleLowerCase().indexOf(searchString.anagrafica.toLocaleLowerCase()) !== -1);
    }
    return myFilterPredicate;
  }

  onEdit(id: number) {
    this.router.navigate(['iscritto/' + id], { relativeTo: this.route });
  }

  onDelete(id: number, email: string, nome: string, cognome: string) {
    let dialogRef;

    dialogRef = this.dialog.open(DeleteIscrittoBackofficeDialogComponent, { data: { nome: nome, cognome: cognome, email: email } });
    dialogRef?.afterClosed().subscribe((confirmed: { confirm: boolean, message: string }) => {
      if (confirmed.confirm) {

        this.apiBackofficeService.addLog('Revoca iscrizione', id).subscribe(log => {

          this.apiBackofficeService.deleteIscritto(id, email, confirmed.message).subscribe(data => {
            this.saveVariable();
            this.getData()
          }, err => {
            this.openDialog('err');
          });

        }, err => {
          this.openDialog('err');
        })

      }
    });

  }

  onDocs(id: number) {
    this.router.navigate(['documenti/' + id], { relativeTo: this.route });
  }

  onEmail(id: number) {
    var iscritti: Array<number> = [];
    iscritti.push(id);

    this.apiBackofficeService.setComunicazioniIscritti(iscritti);

    this.router.navigate(['email'], { relativeTo: this.route });
  }

  onSms(id: number) {
    var iscritti: Array<number> = [];
    iscritti.push(id);

    this.apiBackofficeService.setComunicazioniIscritti(iscritti);

    this.router.navigate(['sms'], { relativeTo: this.route });
  }

  onClearFilter() {
    this.dataSource.filter = '';
    this.statoFilter.setValue('');
    this.nazioneFilter.setValue('');
    this.emailFilter.setValue('');
    this.regioneFilter.setValue('');
    this.provinciaFilter.setValue('');
    this.anagraficaFilter.setValue('');

    localStorage.removeItem('filtri');
  }

  onSendComunication(type: string) {
    this.visible = true;

    if (type === 'email') {
      this.emailComunication = true;
      this.smsComunication = false;
    } else if (type === 'sms') {
      this.smsComunication = true;
      this.emailComunication = false;
    }

    this.selection.clear();

    this.getData()
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.filteredData.forEach((row) => this.selection.select(row));
  }

  onSendEmail() {
    var iscritti: Array<number> = [];
    this.selection.selected.forEach((s) => iscritti.push(s.id));

    this.apiBackofficeService.setComunicazioniIscritti(iscritti);

    this.router.navigate(['email'], { relativeTo: this.route });
  }

  onSendSms() {
    var iscritti: Array<number> = [];
    this.selection.selected.forEach((s) => iscritti.push(s.id));

    this.apiBackofficeService.setComunicazioniIscritti(iscritti);

    this.router.navigate(['sms'], { relativeTo: this.route });
  }

  onDeleteCommunication() {
    this.selection.clear();
    this.visible = false;
    this.emailComunication = false;
    this.smsComunication = false;

    this.getData();
  }

  onSendConfermaEmail(id: number, email: string, cell: string) {
    let dialogRef;

    dialogRef = this.dialog.open(SendEmailConfermaBackofficeDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {

        this.apiBackofficeService.addLog('Re-invio email conferma', id).subscribe(log => {
          this.apiBackofficeService.sendEmailConferma(id, email, cell).subscribe(data => {
            this.getData();
          }, err => {
            this.openDialog('err');
          })
        }, err => {
          this.openDialog('err');
        })
      }
    })
  }

  onExport() {

    this.apiBackofficeService.exportIscritti(this.anagraficaFilter?.value, this.emailFilter?.value, this.nazioneFilter?.value, this.regioneFilter?.value, this.provinciaFilter?.value, this.statoFilter?.value, false, this.showNonConfermati, this.userBackoffice.tipo, this.userBackoffice.id_regione).subscribe(iscrizioni => {

      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(iscrizioni);

      workSheet["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 40 }, { wch: 40 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

      XLSX.utils.book_append_sheet(workBook, workSheet, 'Iscritti');
      XLSX.writeFile(workBook, 'export_iscrizioni.xlsx');
    }, err => {
      this.openDialog('err');
    })
  }

  onExportDonatori() {

    this.apiBackofficeService.exportIscritti(this.anagraficaFilter?.value, this.emailFilter?.value, this.nazioneFilter?.value, this.regioneFilter?.value, this.provinciaFilter?.value, this.statoFilter?.value, true, this.showNonConfermati, this.userBackoffice.tipo, this.userBackoffice.id_regione).subscribe(iscrizioni => {
      const workBook = XLSX.utils.book_new();
      const workSheet = XLSX.utils.json_to_sheet(iscrizioni);

      workSheet["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 30 }, { wch: 20 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 40 }, { wch: 40 }, { wch: 10 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

      XLSX.utils.book_append_sheet(workBook, workSheet, 'Iscritti');
      XLSX.writeFile(workBook, 'export_iscrizioni.xlsx');
    }, err => {
      this.openDialog('err');
    })

  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }
  }

  onLog(id: number) {
    this.router.navigate(['log'], { queryParams: { iscritto: id }, relativeTo: this.route });
  }

  saveVariable() {
    var variabili = JSON.stringify({ pagina: this.paginator.pageIndex, iscritti: this.paginator.pageSize, confermati: this.showNonConfermati, donatori: this.showDonatori });
    var filtri = JSON.stringify({ anagrafica: this.anagraficaFilter?.value, email: this.emailFilter?.value, nazione: this.nazioneFilter?.value, regione: this.regioneFilter?.value, provincia: this.provinciaFilter?.value, stato: this.statoFilter?.value });
    localStorage.setItem('variabili', variabili);
    localStorage.setItem('filtri', filtri);
  }

  ngOnDestroy(): void {
    this.saveVariable();
  }
}
