import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AuthBackofficeService } from "./auth-backoffice.service";


@Injectable({
    providedIn: 'root'
})
export class AuthBackofficeGuard implements CanActivate {

    constructor(private authBackofficeService: AuthBackofficeService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authBackofficeService.token.pipe(take(1),map(user => {                                               
            const isAuth = !!user;                        
            if (isAuth) {
                return true;
            } else {
                return this.router.createUrlTree(['/backoffice']);
            }
        }))
    }
}