import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ApiService } from 'src/app/shared/api.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Regione } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';

@Component({
  selector: 'app-add-utente-backoffice',
  templateUrl: './add-utente-backoffice.component.html',
  styleUrls: ['./add-utente-backoffice.component.css']
})
export class AddUtenteBackofficeComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private dialog: MatDialog, private apiBackofficeService: ApiBackofficeService, private titleService: Title, private apiService: ApiService) { }

  utenteForm!: FormGroup;  
  regioni?: Regione[];

  id!: number;

  repDataUtente! : { id: number, username: string, password: string, tipo: string, id_regione: number, regione: string}  
  repDataCheck! : {id: number};

  hide = true;
  
  error_messages = {
    'username': [
      { type: 'required', message: 'Inserire un username per procedere con l"aggiunta di un utente' },              
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con l"aggiunta di un utente' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],   
    'tipo': [
      { type: 'required', message: 'Inserire un tipo di utente per procedere con l"aggiunta di un utente' },              
    ], 
  }

  ngOnInit(): void {    

    this.titleService.setTitle('Alternativa Backoffice - Aggiungi Utente');

    this.utenteForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      tipo: new FormControl(null, Validators.required),
      regione: new FormControl(null)
    })

    this.apiBackofficeService.getUserData().subscribe(data => {
      if (data.tipo != 'Amministratore') {        
        this.router.navigate(['..'], {relativeTo: this.route});
      }

      this.apiService.getRegioni(1).subscribe(regioni => {
        this.regioni = regioni;
      })      
    })      
  }

  onSubmit() {
    let dialogRef;
    var username = this.utenteForm.get('username')?.value;
    var password = this.utenteForm.get('password')?.value;
    var tipo = this.utenteForm.get('tipo')?.value;
    var regione = this.utenteForm.get('regione')?.value;

    this.apiBackofficeService.addLog('Nuovo utente: ' + username).subscribe(log => {
      this.apiBackofficeService.addUtente(username, password , tipo, regione).subscribe(data=> {
        dialogRef = this.dialog.open(SuccessDialogComponent);
        dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
              this.router.navigate(['../utenti'], {relativeTo: this.route});          
          }
        })
      }, err => {
        this.openDialog('err');
      })
    }, err => {
      this.openDialog('err');
    })
        
  }

  onGoBack() {
    this.router.navigate(['../utenti'], {relativeTo: this.route});
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);    
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }
}
