<div class="container-iscritti">
    <div class="col-xs-12">
        <mat-toolbar>
            <mat-toolbar-row>
                <span style="font-weight: 900;">GESTIONE ISCRITTO</span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="onGoBack()">
                    <mat-icon>backspace</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="edit-iscritto">
            <div class="row">
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                    <div class="col-xs-12 form">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nome</mat-label>
                                    <input matInput formControlName="nome" required>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.nome">
                                        <div class="error-message" *ngIf="userForm.get('nome')?.hasError(error.type) && (userForm.get('nome')?.dirty || userForm.get('nome')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Cognome</mat-label>
                                    <input matInput formControlName="cognome" required>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.cognome">
                                        <div class="error-message" *ngIf="userForm.get('cognome')?.hasError(error.type) && (userForm.get('cognome')?.dirty || userForm.get('cognome')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div [ngClass]="estero ? 'col-md-12': 'col-md-6'">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nazione</mat-label>
                                    <mat-select formControlName="nazione" (ngModelChange)="setRegione($event)" required>
                                        <mat-option *ngFor="let nazione of nazioni" [value]="nazione.value">
                                            {{nazione.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.nazione">
                                        <div class="error-message" *ngIf="userForm.get('nazione')?.hasError(error.type) && (userForm.get('nazione')?.dirty || userForm.get('nazione')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="!estero">
                                <mat-form-field appearance="fill">
                                    <mat-label>Regione</mat-label>
                                    <mat-select formControlName="id_regione" (valueChange)="setProvincie($event)" required>
                                        <mat-option *ngFor="let regione of regioni" [value]="regione.id_regione">
                                            {{regione.regione}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.regione">
                                        <div class="error-message" *ngIf="userForm.get('id_regione')?.hasError(error.type) && (userForm.get('id_regione')?.dirty || userForm.get('id_regione')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="estero" *ngIf="estero">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Stato</mat-label>
                                        <input matInput formControlName="stato" required>
                                    </mat-form-field>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.stato">
                                            <div class="error-message" *ngIf="userForm.get('stato')?.hasError(error.type) && (userForm.get('stato')?.dirty || userForm.get('stato')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Regione</mat-label>
                                        <input matInput formControlName="regione" required>
                                    </mat-form-field>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.regione">
                                            <div class="error-message" *ngIf="userForm.get('regione')?.hasError(error.type) && (userForm.get('regione')?.dirty || userForm.get('regione')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Comune</mat-label>
                                        <input matInput formControlName="comune" required>
                                    </mat-form-field>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.comune">
                                            <div class="error-message" *ngIf="userForm.get('comune')?.hasError(error.type) && (userForm.get('comune')?.dirty || userForm.get('comune')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Cap</mat-label>
                                        <input matInput formControlName="cap">
                                    </mat-form-field>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.cap">
                                            <div class="error-message" *ngIf="userForm.get('cap')?.hasError(error.type) && (userForm.get('cap')?.dirty || userForm.get('cap')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!estero">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Provincia</mat-label>
                                    <mat-select formControlName="id_provincia" (valueChange)="setComuni($event)" required>
                                        <mat-option *ngFor="let provincia of province" [value]="provincia.id_provincia">
                                            {{provincia.provincia}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.provincia">
                                        <div class="error-message" *ngIf="userForm.get('id_provincia')?.hasError(error.type) && (userForm.get('id_provincia')?.dirty || userForm.get('id_provincia')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Comune</mat-label>
                                    <mat-select formControlName="id_comune" required>
                                        <mat-option *ngFor="let comune of comuni" [value]="comune.id_comune">
                                            {{comune.comune}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.comune">
                                        <div class="error-message" *ngIf="userForm.get('id_comune')?.hasError(error.type) && (userForm.get('id_comune')?.dirty || userForm.get('id_comune')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Codice fiscale</mat-label>
                                    <input matInput formControlName="codice_fiscale" maxlength="16" required>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.codice_fiscale">
                                        <div class="error-message" *ngIf="userForm.get('codice_fiscale')?.hasError(error.type) && (userForm.get('codice_fiscale')?.dirty || userForm.get('codice_fiscale')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Data di nascita</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="data_nascita" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.data_nascita">
                                        <div class="error-message" *ngIf="userForm.get('data_nascita')?.hasError(error.type) && (userForm.get('data_nascita')?.dirty || userForm.get('data_nascita')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Indirizzo di residenza</mat-label>
                                    <input matInput formControlName="indirizzo" required>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.indirizzo">
                                        <div class="error-message" *ngIf="userForm.get('indirizzo')?.hasError(error.type) && (userForm.get('indirizzo')?.dirty || userForm.get('indirizzo')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Professione (opzionale)</mat-label>
                                    <input matInput formControlName="professione">
                                </mat-form-field>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Email</mat-label>
                                    <input matInput placeholder="Ex. mario@rossi.it" formControlName="email" required>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.email">
                                        <div class="error-message" *ngIf="userForm.get('email')?.hasError(error.type) && (userForm.get('email')?.dirty || userForm.get('email')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete>
                                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide">
                                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.password">
                                        <div class="error-message" *ngIf="userForm.get('password')?.hasError(error.type) && (userForm.get('password')?.dirty || userForm.get('password')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="legacy" *ngIf="!estero">
                                    <mat-label>Cellulare</mat-label>
                                    <ngx-mat-intl-tel-input formControlName="cell" [onlyCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="false" name="phone" #phone></ngx-mat-intl-tel-input>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" *ngIf="estero">
                                    <mat-label>Cellulare</mat-label>
                                    <ngx-mat-intl-tel-input formControlName="cell" [preferredCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="true" name="phone" #phone [searchPlaceholder]="'Cerca'"></ngx-mat-intl-tel-input>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.cell">
                                        <div class="error-message" *ngIf="userForm.get('cell')?.hasError(error.type) && (userForm.get('cell')?.dirty || userForm.get('cell')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="legacy" *ngIf="!estero">
                                    <mat-label>Numero di telefono fisso (opzionale)</mat-label>
                                    <ngx-mat-intl-tel-input formControlName="cell_fisso" [onlyCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="false" name="phone" #phone></ngx-mat-intl-tel-input>
                                </mat-form-field>
                                <mat-form-field appearance="legacy" *ngIf="estero">
                                    <mat-label>Numero di telefono fisso (opzionale)</mat-label>
                                    <ngx-mat-intl-tel-input formControlName="cell_fisso" [preferredCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="true" name="phone" #phone [searchPlaceholder]="'Cerca'"></ngx-mat-intl-tel-input>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.cell_fisso">
                                        <div class="error-message" *ngIf="userForm.get('cell_fisso')?.hasError(error.type) && (userForm.get('cell_fisso')?.dirty || userForm.get('cell_fisso')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div [ngClass]="{'col-xs-12': userForm.get('precedenti_iscrizioni')?.value == false || !userForm.get('precedenti_iscrizioni')?.touched, 'col-md-6': userForm.get('precedenti_iscrizioni')?.value == true}">
                                <label>Sei stato precedentemente iscritto a partiti, movimenti o associazioni politiche?</label>
                                <div class="form-group">
                                    <mat-radio-group aria-label="Select an option" formControlName="precedenti_iscrizioni">
                                        <mat-radio-button [value]="true" (click)="onRadio(true, 'iscrizioni')">SI</mat-radio-button>
                                        <span class="spacer-radio"></span>
                                        <mat-radio-button [value]="false" (click)="onRadio(false, 'iscrizioni')">NO</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="userForm.get('precedenti_iscrizioni')?.value == true">
                                <div class="form-group">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Se sì, quali? (inserisci nel campo sottostante)</mat-label>
                                        <textarea matInput formControlName="precedenti_iscrizioni_text" rows="3" style="resize: none;"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.precedenti_iscrizioni_text">
                                        <div class="error-message" *ngIf="userForm.get('precedenti_iscrizioni_text')?.hasError(error.type) && (userForm.get('precedenti_iscrizioni_text')?.dirty || userForm.get('precedenti_iscrizioni_text')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div [ngClass]="{'col-xs-12': userForm.get('precedenti_mandati')?.value == false || !userForm.get('precedenti_mandati')?.touched, 'col-md-6': userForm.get('precedenti_mandati')?.value == true}">
                                <label>Hai avuto precedenti mandati elettorali o nomine in assessorati o ministeri?</label>
                                <div class="form-group">
                                    <mat-radio-group aria-label="Select an option" formControlName="precedenti_mandati">
                                        <mat-radio-button [value]="true" (click)="onRadio(true, 'mandati')">SI</mat-radio-button>
                                        <span class="spacer-radio"></span>
                                        <mat-radio-button [value]="false" (click)="onRadio(false, 'mandati')">NO</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="userForm.get('precedenti_mandati')?.value == true">
                                <div class="form-group">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Se sì, quali? (inserisci nel campo sottostante)</mat-label>
                                        <textarea matInput formControlName="precedenti_mandati_text" rows="3" style="resize: none;"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.precedenti_mandati_text">
                                        <div class="error-message" *ngIf="userForm.get('precedenti_mandati_text')?.hasError(error.type) && (userForm.get('precedenti_mandati_text')?.dirty || userForm.get('precedenti_mandati_text')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div [ngClass]="{'col-xs-12': userForm.get('pagamento')?.value == false || !userForm.get('pagamento')?.touched, 'col-md-6': userForm.get('pagamento')?.value == true}">
                                <label>L'iscritto ha effettuato una donazione? Se si inserisci la data di Pagamento</label>
                                <div class="form-group">
                                    <mat-radio-group aria-label="Seleziona un'opzione" formControlName="pagamento">
                                        <mat-radio-button [value]="true" (click)="onPagamento(true)">SI</mat-radio-button>
                                        <span class="spacer-radio"></span>
                                        <mat-radio-button [value]="false" (click)="onPagamento(false)">NO</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="userForm.get('pagamento')?.value == true">
                                <div class="form-group">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Data di pagamento</mat-label>                                        
                                        <input matInput [matDatepicker]="pickerPagamento" formControlName="data_pagamento" required>
                                        <mat-datepicker-toggle matSuffix [for]="pickerPagamento"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerPagamento></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.data_pagamento">
                                        <div class="error-message" *ngIf="userForm.get('data_pagamento')?.hasError(error.type) && (userForm.get('data_pagamento')?.dirty || userForm.get('data_pagamento')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <br/>
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <input type="checkbox" id="no_associazioni" formControlName="flag_no_iscrizione_societa">
                                    <label for="no_associazioni">Auto-dichiarazione di non essere iscritto a società od associazioni segrete</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <input type="checkbox" id="newsletter" formControlName="flag_newsletter">
                                    <label for="newsletter">Presto il consenso all'invio della Newsletter da parte di Alternativa per rimanere sempre aggiornato sulle attività dell'Associazione!</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="button-row text-center">
                            <button mat-raised-button type="submit" color="success" [disabled]="!userForm.valid">Modifica dati</button>
                            <button mat-raised-button type="button" color="warn" (click)="onRevoca()">Revoca l'iscrizione</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>