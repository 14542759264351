import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modifica-utente-backoffice-dialog',
  templateUrl: './modifica-utente-backoffice-dialog.component.html',
  styleUrls: ['./modifica-utente-backoffice-dialog.component.css']
})
export class ModificaUtenteBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaUtenteBackofficeDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true)
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }

}
