import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { AuthBackofficeService } from 'src/app/shared/auth-backoffice.service';

@Component({
  selector: 'app-homepage-backoffice',
  templateUrl: './homepage-backoffice.component.html',
  styleUrls: ['./homepage-backoffice.component.css']
})

export class HomepageBackofficeComponent implements OnInit {  
  
  mobileQuery: MediaQueryList;
  visible?: boolean;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private titleService: Title, private authBackofficeService: AuthBackofficeService, private router: Router, private apiBackofficeService: ApiBackofficeService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  ngOnInit(): void {
  
    this.titleService.setTitle('Alternativa Backoffice - Homepage');    

    this.authLevel();
  }

  authLevel() {        
    this.apiBackofficeService.getUserData().subscribe(data => {
      if (data.tipo === 'Amministratore') {
        this.visible = true;
      } else {
        this.visible = false;
      }
    })
    
  }

  onLogout() {
    this.authBackofficeService.Logout();
    this.router.navigate(['/backoffice']);
  }

}