import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ApiService } from 'src/app/shared/api.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Regione } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { DeleteUtenteBackofficeDialogComponent } from '../elenco-utenti-backoffice/delete-utente-backoffice-dialog/delete-utente-backoffice-dialog.component';
import { ModificaUtenteBackofficeDialogComponent } from './modifica-utente-backoffice-dialog/modifica-utente-backoffice-dialog.component';
import { ModificaUtentePasswordDialogComponent } from './modifica-utente-password-dialog/modifica-utente-password-dialog.component';

@Component({
  selector: 'app-edit-utente-backoffice',
  templateUrl: './edit-utente-backoffice.component.html',
  styleUrls: ['./edit-utente-backoffice.component.css']
})
export class EditUtenteBackofficeComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private dialog: MatDialog, private apiBackofficeService: ApiBackofficeService, private titleService: Title, private apiService: ApiService) { }

  utenteForm!: FormGroup;
  nazione?: string;
  regioni?: Regione[];

  id!: number;

  hide = true;

  repDataUtente! : { id: number, username: string, password: string, tipo: string, id_regione: number, regione: string}  
  repDataCheck! : {id: number};
  
  error_messages = {
    'username': [
      { type: 'required', message: 'Inserire un username per procedere con la modifica dell"utente' },
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con la modifica dell"utente' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],
    'tipo': [
      { type: 'required', message: 'Inserire un tipo di utente per procedere con la modifica dell"utente' },  
    ],
  }

  ngOnInit(): void {    

    this.titleService.setTitle('Alternativa Backoffice - Modifica Utente');     

    this.apiBackofficeService.getUserData().subscribe(data => {
      if (data.tipo != 'Amministratore') {        
        this.router.navigate(['../..'], {relativeTo: this.route});
      }
    })
    
    this.utenteForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null),
      tipo: new FormControl(null, Validators.required),
      regione: new FormControl(null)
    })

    var params = this.route.params.subscribe(params => {      
      this.id = params['id'];
    });    
    
    this.apiBackofficeService.getUtente(this.id).subscribe(data => {
      this.repDataUtente = data;      
      
      if (this.repDataUtente) {        
        
        this.apiService.getRegioni(1).subscribe(regioni => {
          this.regioni = regioni;

          this.utenteForm.setValue({
            username: this.repDataUtente.username,
            password: '',
            tipo: this.repDataUtente.tipo, 
            regione: this.repDataUtente.id_regione                 
          })    
        })           
                                                              
      }

    })    
  }

  onSubmit() {
    let dialogRef;
    var username = this.utenteForm.get('username')?.value;
    var password = this.utenteForm.get('password')?.value;
    var tipo = this.utenteForm.get('tipo')?.value;
    var regione = this.utenteForm.get('regione')?.value;
    
    if (password) {      
      this.apiBackofficeService.checkUtentePassoword(this.id, password).subscribe(data => {
        this.repDataCheck = data;
        if (!this.repDataCheck) {
          dialogRef = this.dialog.open(ModificaUtentePasswordDialogComponent);
          dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

              this.apiService.updatePassword(password, this.id, true).subscribe(data => {                

                this.apiBackofficeService.addLog('Modifica utente: ' + username).subscribe(log => {

                  this.apiBackofficeService.updateUtente(this.id, username, tipo, regione).subscribe(data => {                    
                    this.openDialog('success');
                  }, errorUp => {
                    this.openDialog('err');
                  })

                }, err => {
                  this.openDialog('err');
                })                

              }, errorPass => {
                this.openDialog('err');
              })
            }
          });
        } else {
          dialogRef = this.dialog.open(ModificaUtenteBackofficeDialogComponent);
          dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {

              this.apiBackofficeService.addLog('Modifica utente: ' + username).subscribe(log => {
                this.apiBackofficeService.updateUtente(this.id, username, tipo, regione).subscribe(data => {                  
                  this.openDialog('success');
                }, errorUp => {                
                  this.openDialog('err');
                })

              }, err => {
                this.openDialog('err');
              })              
            }
          })
        }        
      }, errorCheck => {      
        this.openDialog('err');
      })
    } else {
      dialogRef = this.dialog.open(ModificaUtenteBackofficeDialogComponent);
      dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {

          this.apiBackofficeService.addLog('Modifica utente: ' + username).subscribe(log => {

            this.apiBackofficeService.updateUtente(this.id, username, tipo, regione).subscribe(data => {              
              this.openDialog('success');
            }, errorUp => {
              this.openDialog('err');
            })

          }, err => {
            this.openDialog('err');
          })
          
        }
      })
    }
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'modifica') {
      dialogRef = this.dialog.open(ModificaUtenteBackofficeDialogComponent);
    } else if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else if (type === 'delete') {
      dialogRef = this.dialog.open(DeleteUtenteBackofficeDialogComponent);
    } else if (type === 'pass') {
      dialogRef = this.dialog.open(ModificaUtentePasswordDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }

  onGoBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }
}
