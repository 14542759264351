import { Component, OnInit } from '@angular/core';
import { ApiService } from './shared/api.service';
import { AuthBackofficeService } from './shared/auth-backoffice.service';
import { AuthService } from './shared/auth.service';
import { NavigationStart, Router, Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {    
  
  constructor(private authService: AuthService, private authBackofficeService : AuthBackofficeService, private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {    
    this.apiService.setToken();
    

    this.router.events.subscribe((event: NavigationEvent) => {      
      if(event instanceof NavigationStart) {        
          if (event.url.indexOf('/backoffice/homepage') > -1) {              
            this.authBackofficeService.AutoLogin(); 
          }
          else if (event.url.indexOf('/area-personale') > -1) {
            this.authService.AutoLogin();  
          }
      }
    });    
    
  }
}
