import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CheckUtentePass, Comunicazioni, Documents, Iscritto, Log, SendEmail, UserBackofficeResponse, Utente } from './interface';


@Injectable({
  providedIn: 'root'
})

export class ApiBackofficeService {

  constructor(private http: HttpClient) { }
  
  url_database: string = environment.apiHost;
  
  private Iscritti : Iscritto[] = [];
  private Utenti: Utente[] = [];

  iscrittoChanged = new Subject<Iscritto[]>();
  utenteChanged = new Subject<Utente[]>();

  comunicazioniIscritti: Array<number> = [];

  token: any = localStorage.getItem("token") ? localStorage!.getItem("token"): '';  

  getUserData() {
    var token_bo: string = localStorage.getItem('AuthBo') || '';
    return this.http.post<UserBackofficeResponse>(this.url_database + 'api/v1.0/data_bo', {user_token: token_bo});
  }

  deleteIscritto(id: number, email: string, messaggio?: string) {    
    return this.http.delete(this.url_database + 'api/v1.0/iscritto/' + id, {body: {email: email, messaggio: messaggio, backoffice: true}});
  }

  setIscritti(confermati?: boolean, donatori?: boolean) {             
    this.getUserData().subscribe(data => {      
      this.http.get<Iscritto[]>(this.url_database + 'api/v1.0/iscritti', {params: {tipo: data.tipo, regione: data.id_regione, confermato: confermati || false, donatori: donatori || false }}).subscribe(data => {      
        this.Iscritti = data;
        this.iscrittoChanged.next(this.Iscritti.slice());
      })
    })        
      
  }

  getIscritti() {    
    return this.Iscritti.slice();
  }

  getIscritto(id: number) {
    return this.http.get<Iscritto>(this.url_database + 'api/v1.0/iscritto/' + id);
  }

  updateIscritto(nome: string, cognome: string, email: string, cell: string, cell_fisso: string, codice_fiscale: string, data_nascita: string | null, indirizzo: string, professione: string, nazione: string, stato: string | null, id_regione: number | null, regione: string, id_provincia: number | null, provincia: string | null, id_comune: number | null, comune: string, cap: string | null, precedenti_iscrizioni: string, precedenti_mandati: string, flag_no_iscrizione_societa: boolean, pagamento: boolean, data_pagamento: string | null, flag_newsletter: string, id: number) {    
    return this.http.put(this.url_database + 'api/v1.0/iscritto/' + id, { nome: nome, cognome: cognome, email: email, cell: cell, cell_fisso: cell_fisso, codice_fiscale: codice_fiscale, data_nascita: data_nascita ,indirizzo: indirizzo, professione: professione, nazione: nazione, stato: stato, id_regione: id_regione, regione: regione, id_provincia: id_provincia, provincia: provincia, id_comune: id_comune , comune: comune, cap: cap, precedenti_iscrizioni: precedenti_iscrizioni, precedenti_mandati: precedenti_mandati, iscrizione_societa: flag_no_iscrizione_societa, pagamento: pagamento, data_pagamento: data_pagamento, newsletter: flag_newsletter, backoffice: true});
  }

  setUtenti() {      
    this.http.get<Utente[]>(this.url_database + 'api/v1.0/utenti').subscribe(data => {      
      this.Utenti = data;
      this.utenteChanged.next(this.Utenti.slice());
    })
  }

  getUtenti() {    
    return this.Utenti.slice();
  }

  getUtente(id: number) {    
    return this.http.get<Utente>(this.url_database + 'api/v1.0/utente/' + id);
  }

  addUtente(username: string, password: string, tipo: string, regione?: string) {    
    return this.http.post(this.url_database + 'api/v1.0/utente', {username: username, password: password, tipo: tipo, regione: regione}); 
  } 

  deleteUtente(id:number) {
    return this.http.delete(this.url_database + 'api/v1.0/utente/'+ id);              
  }

  checkUtentePassoword(id: number, password: string) {
    return this.http.post<CheckUtentePass>(this.url_database + 'api/v1.0/check_utente_password', {id: id, password: password});
  }

  updateUtente(id:number, username: string, tipo: string, regione?: string) {    
    return this.http.put(this.url_database + 'api/v1.0/utente/' + id, {username: username, tipo: tipo, regione: regione});
  }

  getDocuments(id: number, fronte_carta: string, retro_carta: string) {
    return this.http.post<Documents>(this.url_database + 'api/v1.0/documenti/' + id, {fronte_carta: fronte_carta, retro_carta: retro_carta});
  }

  deleteDocuments(id: number) {
    return this.http.delete(this.url_database + 'api/v1.0/documenti/' + id);
  }

  attivaIscritto(id: number, email: string) {
    return this.http.put(this.url_database + 'api/v1.0/attivazione/' + id, {email: email});
  }  

  setComunicazioniIscritti(id_iscritti:any){
    this.comunicazioniIscritti = id_iscritti;
  }

  retrieveIDs(){
    return this.comunicazioniIscritti.slice();
  }

  sendEmail(email_to: string, email_from: string, oggetto: string, titolo: string, html: string) {     
    return this.http.post<SendEmail>(this.url_database + 'api/v1.0/send_email', {email_to: email_to, email_from: email_from, oggetto: oggetto, titolo: titolo, html: html})
  }

  sendSms(cell: string, messaggio: string) {
    return this.http.post(this.url_database + 'api/v1.0/send_sms', {cell: cell, messaggio: messaggio});
  }  

  sendEmailConferma(id: number, email: string, cell: string) {
    return this.http.post(this.url_database + 'api/v1.0/send_email_conferma', {id: id, email: email, cell: cell})
  }

  getLogs(utente?: number, iscritto?: number) {
    return this.http.get<Log[]>(this.url_database + 'api/v1.0/log', {params: {utente: utente || 0, iscritto: iscritto || 0}});
  }

  addLog(tipo: string, id_iscritto?: number) {
    var token_bo: string = localStorage.getItem('AuthBo') || '';
    return this.http.post(this.url_database + 'api/v1.0/log', {token_bo: token_bo, tipo: tipo, id_iscritto: id_iscritto});
  }

  addComunicazione(tipo: string, oggetto: string, html: string, messaggio: string, destinatari: string, id_utente: number) {    
    return this.http.post(this.url_database + 'api/v1.0/comunicazione', {tipo: tipo, oggetto: oggetto, messaggio: messaggio, html: html, destinatari: destinatari, utente: id_utente});
  }

  getComunicazioni(id_utente: number) {
    return this.http.get<Comunicazioni[]>(this.url_database + 'api/v1.0/comunicazioni', {params: {utente: id_utente}});
  }

  getComunicazione(id: number) {
    return this.http.get<Comunicazioni>(this.url_database + 'api/v1.0/comunicazioni/' + id);
  }

  exportIscritti(anagrafica?: string, email?: string, nazione?: string, regione?: string, provincia?: string, stato?: string, donatori?: boolean, confermati?: boolean, tipo?: string, id_regione?: number) {
    return this.http.get<Iscritto[]>(this.url_database + 'api/v1.0/export_iscritti', { params: {anagrafica: anagrafica || '', email: email || '', nazione: nazione || '', regione: regione || '', provincia: provincia || '', stato: stato || '', donatori: donatori || false, confermati: confermati || false, tipo: tipo || '', id_regione: id_regione || 0}} )
  }
}