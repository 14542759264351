<mat-dialog-content>
    <p>Grazie per aver sottomesso la tua iscrizione!</p>
    <p>Una mail ed un sms sono stati inviati all'indirizzo e al numero di cellulare inserito per completare la procedura di iscrizione.
        <br>(se non hai ricevuto l'email controlla nello spam o nelle cartelle della tua casella di posta)</p>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onConfirmClick()" class="alternativa-css">Ok</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>