import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../shared/api.service';

@Component({
  selector: 'app-autenticazione',
  templateUrl: './autenticazione.component.html',
  styleUrls: ['./autenticazione.component.css']
})
export class AutenticazioneComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService) { }

  token!: string; 

  userCode!: string;  
  
  repData!: { days: number, id: number, email: string, sms: number, name?:string, message?:string};
    
  valid?: boolean;
  confirmed?: boolean;

  confermato?: boolean;

  stato_iscritto!: string;

  error?: string;

  ngOnInit(): void {    

    // Ottengo i query params
    this.route.queryParams.subscribe(params => {                                
        this.token = params['token'];  
        
        // Recupero i dati del token
        this.apiService.checkToken(this.token).subscribe(data => {              
          
          this.repData = data;

          // Se ha meno di 20 giorni il token è valido
          if (this.repData.days <= 20) {      
            this.valid = true; 
            
            this.apiService.getIscritto(this.repData.id).subscribe(data => {  
              this.stato_iscritto = data.stato;            
              if (data.stato !== 'attesa_conferma' && data.cell_confermato !== 0) {
                this.confermato = true;                
              }
            })
          }              
        }, err => {
          this.valid =false;
        })                               
    })    
  }

  onIscriviti() {
    this.router.navigate(['/regolamento']);
  }

  onAccedi() {
    this.router.navigate(['/']);
  }

  onConvalida() {               
    if (parseInt(this.userCode) === this.repData.sms) {      
      this.apiService.updateIscrittoConfermato(this.repData.id, this.repData.email, this.stato_iscritto).subscribe(data => {        
        this.confirmed = true; 
      }, err => {
        this.valid = false
      }) 
    } else {
      this.error = 'Il codice inserito non è corretto!';
    }
  }
}
