<div class="container-iscritti">
    <div class="col-xs-12">
        <div class="row">

            <mat-toolbar>
                <mat-toolbar-row>
                    <span style="font-weight: 900;">GESTIONE UTENTI</span>
                    <span class="spacer"></span>
                    <button mat-icon-button class="icon" aria-label="icon-button with menu icon">
                        <mat-icon [matMenuTriggerFor]="menuIscritti">menu
                        </mat-icon>
                        <mat-menu #menuIscritti="matMenu">
                            <button mat-menu-item (click)="onAddUtente()">
                                <mat-icon>person_add</mat-icon>
                                <span>Inserisci un utente</span>
                            </button>

                    </mat-menu>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>

        </div>

        <div class="filtri-utente">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <input matInput class="form-field" [formControl]="usernameFilter" placeholder="Cerca Username">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field>
                        <mat-label>Cerca Tipo</mat-label>
                        <mat-select [formControl]="tipoFilter">
                            <mat-option [value]="'Operatore'">Operatore</mat-option>
                            <mat-option [value]="'Coordinatore'">Coordinatore</mat-option>
                            <mat-option [value]="'Supervisore'">Supervisore</mat-option>
                            <mat-option [value]="'Amministratore'">Amministratore</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="buttons row">
            <div class="col-xs-12 text-right">
                <button mat-raised-button color="warn" (click)="onClearFilter()">Pulisci Ricerca</button>
            </div>
        </div>
        <div class="elenco-iscritti">
            <mat-table #table matSort [dataSource]="dataSource">

                <!-- Username Column -->
                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Username"> Username </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="username"> {{element.username}} </mat-cell>
                </ng-container>

                <!-- Tipo Column -->
                <ng-container matColumnDef="tipo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Tipo"> Tipo </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="tipo"> {{element.tipo}} </mat-cell>
                </ng-container>

                <!-- Regione Column -->
                <ng-container matColumnDef="regione">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Regione"> Regione </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="regione"> {{element.regione}} </mat-cell>
                </ng-container>

                <!-- Modifica Column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="actions">
                        <button mat-icon-button (click)="onEdit(element.id)" [disabled]="element.id === 1">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onDelete(element.id, element.username)" [disabled]="element.id === 1">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onLogs(element.id)">
                            <mat-icon>info</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                <tr class="mat-row" style="text-align: center;" *matNoDataRow>
                    <td class="mat-cell" style="width: 100vw; vertical-align: middle;" colspan="4">Nessun utente trovato</td>
                </tr>
            </mat-table>

            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]">
            </mat-paginator>
        </div>

    </div>

</div>