import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { ErrorDialogComponent } from 'src/app/shared/error-dialog/error-dialog.component';
import { Iscritto, UserBackofficeResponse } from 'src/app/shared/interface';
import { SuccessDialogComponent } from 'src/app/shared/success-dialog/success-dialog.component';
import { AnnulloSendEmailBackofficeDialogComponent } from './annullo-send-email-backoffice-dialog/annullo-send-email-backoffice-dialog.component';
import { EmailIscrittoSendEmailBackofficeDialogComponent } from './email-iscritto-send-email-backoffice-dialog/email-iscritto-send-email-backoffice-dialog.component';
import { FormSendEmailBackofficeDialogComponent } from './form-send-email-backoffice-dialog/form-send-email-backoffice-dialog.component';


@Component({
  selector: 'app-send-email-backoffice',
  templateUrl: './send-email-backoffice.component.html',
  styleUrls: ['./send-email-backoffice.component.css']
})
export class SendEmailBackofficeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private apiBackofficeService: ApiBackofficeService, private titleService: Title, private dialog : MatDialog) { }

  private dialogRef!: MatDialogRef<EmailIscrittoSendEmailBackofficeDialogComponent>
  
  emailForm!: FormGroup;
  iscrittiSelezionati: Array<number> = [];

  stop: boolean = false;
  stop_primo: boolean = false;  
  email_comunicazione!: string;
  block_email_to: boolean = false;

  repData!: Iscritto;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial'
  };  

  numIscritti!: number;  
  utente!: UserBackofficeResponse;

  error_messages = {
    'oggetto': [
      { type: 'required', message: 'Inserire un oggetto per procedere con l"invio delle comunicazione via email' },
    ],
    'titolo': [
      { type: 'required', message: 'Inserire un titolo per procedere con l"invio delle comunicazione via email' },
    ],
    'email': [
      { type: 'required', message: 'Selezionare un indirizzo email di spedizione per procedere con l"invio delle comunicazione via email' },
    ],
  }

  ngOnInit(): void {
    this.titleService.setTitle('Alternativa Backoffice - Invio Comunicazioni Email');

    this.emailForm = new FormGroup({
      oggetto: new FormControl(null, Validators.required),
      titolo: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      html: new FormControl(null)
    })

    this.apiBackofficeService.getUserData().subscribe(user => {
    
      this.utente = user;      
      
      this.iscrittiSelezionati = this.apiBackofficeService.retrieveIDs();      

      if (this.iscrittiSelezionati.length <= 0) {
        this.router.navigate(['..'], {relativeTo: this.route});
      }

      if (this.utente.tipo == 'Coordinatore') {
        this.block_email_to = true;
        this.getEmail();
      }

    })
  }

  getEmail() {
    switch (this.utente.id_regione) {
        case 2:
          this.emailForm.patchValue({email: 'abruzzo@alternativa.it'});
          break;
        case 3:
          this.emailForm.patchValue({email: 'basilicata@alternativa.it'});
          break;
        case 7:
          this.emailForm.patchValue({email: 'calabria@alternativa.it'});
          break;
        case 8:
          this.emailForm.patchValue({email: 'campania@alternativa.it'});
          break;
        case 14:
          this.emailForm.patchValue({email: 'emiliaromagna@alternativa.it'});
          break;
        case 15:
          this.emailForm.patchValue({email: 'friuliveneziagiulia@alternativa.it'});
          break;
        case 9:
          this.emailForm.patchValue({email: 'lazio@alternativa.it'});
          break;
        case 18:
          this.emailForm.patchValue({email: 'liguria@alternativa.it'});
          break;
        case 19:
          this.emailForm.patchValue({email: 'lombardia@alternativa.it'});
          break;
        case 1:
          this.emailForm.patchValue({email: 'marche@alternativa.it'});
          break;
        case 4:
          this.emailForm.patchValue({email: 'molise@alternativa.it'});
          break;
        case 13:
          this.emailForm.patchValue({email: 'piemonte@alternativa.it'});
          break;
        case 6:
          this.emailForm.patchValue({email: 'puglia@alternativa.it'});
          break;
        case 10:
          this.emailForm.patchValue({email: 'sardegna@alternativa.it'});
          break;
        case 11:
          this.emailForm.patchValue({email: 'sicilia@alternativa.it'});
          break;
        case 12:
          this.emailForm.patchValue({email: 'toscana@alternativa.it'});
          break;
        case 5:
          this.emailForm.patchValue({email: 'trentinoaltoadige@alternativa.it'});
          break;
        case 20:
          this.emailForm.patchValue({email: 'umbria@alternativa.it'});
          break;
        case 16:
          this.emailForm.patchValue({email: 'valledaosta@alternativa.it'});
          break;
        case 17:
          this.emailForm.patchValue({email: 'veneto@alternativa.it'});
          break;
    }
  }

  onSubmit() {
    let dialogRef;
    let oggetto = this.emailForm.get('oggetto')?.value;
    let titolo = this.emailForm.get('titolo')?.value;
    let email_to = this.emailForm.get('email')?.value;
    let html = this.emailForm.get('html')?.value;    
    this.numIscritti = this.iscrittiSelezionati.length;      
    
    var destinatari: string = '';

    dialogRef = this.dialog.open(FormSendEmailBackofficeDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {       
        
        this.dialog.closeAll();
        this.dialogRef = this.dialog.open(EmailIscrittoSendEmailBackofficeDialogComponent, {data: {email: '', numero: 0, tot: 0}});              
        this.dialogRef?.afterClosed().subscribe((confirmed: boolean) => {            
          if (!confirmed) {  
            this.stop = true;     
            this.stop_primo = true;          
          }
        })        

        this.iscrittiSelezionati.forEach((id, i) => {                          
          
          setTimeout(() => {       
            
            if (this.stop) { 

              if (this.stop_primo) {                           
                this.stop_primo = false;

                this.apiBackofficeService.addComunicazione('Email', oggetto, this.email_comunicazione, '', destinatari, this.utente.id).subscribe(comms => {

                  this.dialog.closeAll();
                  dialogRef = this.dialog.open(AnnulloSendEmailBackofficeDialogComponent, {data: {numero: i + 1, totale: this.numIscritti }});
                  dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
                    if (confirmed) {
                      this.router.navigate(['..'], {relativeTo: this.route});
                      return;
                    }                    
                  })

                }, err => {
                  this.openDialog('err');
                })
                
                return;
                
              }  else {
                return;
              }  
                                        
            }

            this.apiBackofficeService.getIscritto(id).subscribe(iscritto => {               
              
              this.dialogRef.componentInstance.data = {email: iscritto.email, numero: i + 1, tot: this.iscrittiSelezionati.length }

              this.apiBackofficeService.addLog('Invio comunicazione email', id).subscribe(log => {

                this.apiBackofficeService.sendEmail(iscritto.email, email_to, oggetto, titolo, html).subscribe(email => {
                  
                  this.email_comunicazione = email.html;
                  i++;
                  if (i == this.numIscritti) {                    
                    destinatari += id;                                            

                    this.apiBackofficeService.addComunicazione('Email', oggetto, this.email_comunicazione, '', destinatari, this.utente.id).subscribe(comms => {

                      this.dialog.closeAll();
                      dialogRef = this.dialog.open(SuccessDialogComponent);
                      dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
                        if (confirmed) {
                          this.router.navigate(['..'], {relativeTo: this.route});
                        }
                      })

                    }, err => {
                      this.openDialog('err');
                    })

                  } else {
                    destinatari += id  + ';';
                  }                  

                }, err => {
                  this.openDialog('err');
                })

              }, err => {
                this.openDialog('err');
              })

            }, err => {
              this.openDialog('err');
            })

          }, i * 1000);
          
        })
                             
      }
    });

  }

  openDialog(type: string) {
    let dialogRef;

    this.dialog.closeAll();

    if (type === 'email') {
      dialogRef = this.dialog.open(FormSendEmailBackofficeDialogComponent);
    } else if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }
  }

  onGoBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

}
