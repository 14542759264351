import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modifica-area-personale-dialog',
  templateUrl: './modifica-area-personale-dialog.component.html',
  styleUrls: ['./modifica-area-personale-dialog.component.css']
})
export class ModificaAreaPersonaleDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaAreaPersonaleDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

}
