<mat-dialog-content>
    <p>Stai per revocare l'iscrizione dell'iscritto {{data.nome}} {{data.cognome}}({{data.email}}) dall'area di backoffice. <br>Sei sicuro di voler procedere?</p>
    <br/>
    <p>Vuoi mandare un messaggio all'iscritto? Scrivi nel campo qua sottostante.</p>
    <mat-form-field appearance="fill">
        <mat-label>Messaggio</mat-label>
        <input matInput [(ngModel)]="messaggio">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onConfirmClick()" class="alternativa-css">Procedi</button>
            </div>
        </div>
    </div>
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onCloseClick()" class="alternativa-css">Chiudi</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>