<mat-dialog-content>
    <p>La password che è stata inserita è diversa da quella salvata.<br> Sei sicuro di voler procedere con la modifica della password?</p>
</mat-dialog-content>
<mat-dialog-actions style="text-align: center;" class="iscritto-duplicato">
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onConfirmClick()" class="alternativa-css">Procedi</button>
            </div>
        </div>
    </div>
    <div class="button-column">
        <div class="button-overlay">
            <div class="button-container">
                <button type="button" (click)="onCloseClick()" class="alternativa-css">Chiudi</button>
            </div>
        </div>
    </div>
</mat-dialog-actions>