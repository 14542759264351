import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ApiBackofficeService } from '../shared/api-backoffice.service';
import { ApiService } from '../shared/api.service';
import { AuthService } from '../shared/auth.service';
import { requiredFileType, requiredMaxFileSize, requiredMinYearType } from '../shared/customValidation';
import { ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
import { Comune, Iscritto, Provincia, Regione } from '../shared/interface';
import { LoadingDialogComponent } from '../shared/loading-dialog/loading-dialog.component';
import { ConfermaIscrizioneDialogComponent } from './conferma-iscrizione-dialog/conferma-iscrizione-dialog.component';
import { EsciAreaPersonaleDialogComponent } from './esci-area-personale-dialog/esci-area-personale-dialog.component';
import { ModificaAreaPersonaleDialogComponent } from './modifica-area-personale-dialog/modifica-area-personale-dialog.component';
import { ModificaConfermaAreaPersonaleComponent } from './modifica-conferma-area-personale/modifica-conferma-area-personale.component';
import { ModificaDocumentiAreaPersonaleComponent } from './modifica-documenti-area-personale/modifica-documenti-area-personale.component';
import { ModificaDocumentiErrorAreaPersonaleComponent } from './modifica-documenti-error-area-personale/modifica-documenti-error-area-personale.component';
import { ModificaPasswordAreaPersonaleComponent } from './modifica-password-area-personale/modifica-password-area-personale.component';
import { RevocaAreaPersonaleDialogComponent } from './revoca-area-personale-dialog/revoca-area-personale-dialog.component';

@Component({
  selector: 'app-area-personale',
  templateUrl: './area-personale.component.html',
  styleUrls: ['./area-personale.component.css']
})
export class AreaPersonaleComponent implements OnInit {
  constructor(private apiService: ApiService, private apiBackofficeService: ApiBackofficeService, private authService: AuthService, private dialog: MatDialog, private titleService: Title) { }

  nazione?: string;
  regioni?: Regione[];
  province?: Provincia[];
  comuni?: Comune[];

  repData?: {id_nazione: number};
  repDataCheck?: {id: number};
  repDataCheckPassword? : {id: number};
  repDataIscritto! : Iscritto;

  fronteCartaPath?: string;
  retroCartaPath?: string;  

  errorInsert?: boolean = false;
  errorDocument?: boolean = false;  

  userForm! : FormGroup;

  password?: boolean;
  estero?: boolean;

  visible?: boolean;
  conferma_cell?: boolean;

  id!: number;
  email!: string;

  error_messages = {
    'nome': [
      { type: 'required', message: 'Inserire un nome per procedere con la modifica dei dati' },              
    ],
    'cognome': [
      { type: 'required', message: 'Inserire un cognome per procedere con la modifica dei dati' },      
    ],
    'email': [
      { type: 'required', message: 'Inserire una email per procedere con la modifica dei dati' },
      { type: 'minlength', message: 'Inserire minimo 6 caratteri.' },      
      { type: 'email', message: 'Inserire una email valida (ex. test@gmail.com)' }
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con la modifica dei dati' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],
    'cell': [
      { type: 'required', message: 'Inserire un numero di cellulare per procedere con la modifica dei dati' },
      { type: 'validatePhoneNumber', message: 'Inserire un numero valido' },            
    ],
    'cell_fisso': [    
      { type: 'validatePhoneNumber', message: 'Inserire un numero valido' },            
    ],
    'codice_fiscale': [
      { type: 'required', message: 'Inserire un codice fiscale per procedere con la modifica dei dati' },
      { type: 'pattern', message: 'Inserire un codice fiscale valido.' },            
    ],
    'data_nascita': [
      { type: 'required', message: 'Inserire una data di nascita per procedere con la modifica dei dati' },  
      { type: 'requiredMinYearType', message: 'Per iscriversi bisogna avere almeno 16 anni'}          
    ],
    'indirizzo': [
      { type: 'required', message: 'Inserire un indirizzo per procedere con la modifica dei dati' },            
    ],
    'nazione': [
      { type: 'required', message: 'Inserire una nazione per procedere con la modifica dei dati' },            
    ],
    'stato': [
      { type: 'required', message: 'Inserire uno stato per procedere con la modifica dei dati' },            
    ],
    'regione': [
      { type: 'required', message: 'Inserire una regione per procedere con la modifica dei dati' },      
    ],
    'provincia': [
      { type: 'required', message: 'Inserire una provincia per procedere con la modifica dei dati' },
    ],
    'comune': [
      { type: 'required', message: 'Inserire una comune per procedere con la modifica dei dati' },
    ],
    'cap': [
      { type: 'required', message: 'Inserire un cap per procedere con la modifica dei dati' },
    ],
    'fronte_carta': [
      { type: 'requiredFileType', message: 'Inserire solo file di tipo JPG, PNG o JPEG'},  
      { type: 'requiredMaxFileSize', message: 'Sono accettati solo file con dimensioni minori di 20MB'},
    ],
    'retro_carta': [
      { type: 'requiredFileType', message: 'Inserire solo file di tipo JPG, PNG o JPEG'},
      { type: 'requiredMaxFileSize', message: 'Sono accettati solo file con dimensioni minori di 20MB'},
    ],
    'precedenti_iscrizioni_text': [
      { type: 'required', message: 'Inserire dei dati per procedere con la modifica dei dati' },
    ],
    'precedenti_mandati_text': [
      { type: 'required', message: 'Inserire dei dati per procedere con la modifica dei dati' },
    ],
    
  }

  ngOnInit(): void {

    this.titleService.setTitle('Alternativa Iscrizione - Area Personale');

    this.userForm = new FormGroup({
      nome: new FormControl(null, Validators.required),
      cognome: new FormControl(null, Validators.required),
      email : new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(6)]),
      indirizzo: new FormControl(null, Validators.required),
      professione: new FormControl(null),
      cell: new FormControl(null, Validators.required),
      cell_fisso: new FormControl(null),
      codice_fiscale: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
      data_nascita: new FormControl(null, [Validators.required, requiredMinYearType()]),
      nazione: new FormControl('', Validators.required),
      stato: new FormControl(null, Validators.required),
      id_regione: new FormControl('', Validators.required),
      regione: new FormControl(null, Validators.required),
      id_provincia: new FormControl('', Validators.required),
      id_comune: new FormControl('', Validators.required),
      comune: new FormControl(null, Validators.required),
      cap: new FormControl(null),      
      fronte_carta: new FormControl(null, [Validators.nullValidator, requiredFileType(), requiredMaxFileSize()]),
      retro_carta: new FormControl(null, [Validators.nullValidator, requiredFileType(), requiredMaxFileSize()]),
      precedenti_iscrizioni: new FormControl(null, Validators.required),
      precedenti_iscrizioni_text: new FormControl(null),
      precedenti_mandati: new FormControl(null),
      precedenti_mandati_text: new FormControl(null),
      flag_no_iscrizione_societa: new FormControl(null, Validators.required),
      flag_privacy: new FormControl(null, Validators.required),
      flag_newsletter: new FormControl(null)
    })    

    this.apiService.getUserData().subscribe(data => {      
      this.id = data.id;
      this.email = data.email;

      this.apiService.getIscritto(this.id).subscribe(data => {
        this.repDataIscritto = data;              
        
        if (this.repDataIscritto) {    
  
          this.setRegione(this.repDataIscritto.nazione);    
          this.setProvincie(this.repDataIscritto.id_regione);
          this.setComuni(this.repDataIscritto.id_provincia);      
          
          this.visible = this.repDataIscritto.stato === 'attesa_verifica' ? true: false;          
          this.conferma_cell = this.repDataIscritto.cell_confermato == 0 ? false : true;
  
          var datePipe = new DatePipe('en-US');
                
          this.userForm.setValue({
            nome: this.repDataIscritto.nome,
            cognome: this.repDataIscritto.cognome,
            email: this.repDataIscritto.email,
            password: '',
            indirizzo: this.repDataIscritto.indirizzo,
            professione: this.repDataIscritto.professione,
            cell: this.repDataIscritto.cell,
            cell_fisso: this.repDataIscritto.cell_fisso,
            codice_fiscale: this.repDataIscritto.codice_fiscale,
            data_nascita: datePipe.transform(this.repDataIscritto.data_nascita, 'yyyy-MM-dd'),
            nazione: this.repDataIscritto.nazione,
            stato: this.repDataIscritto.state,
            id_regione: this.repDataIscritto.id_regione,
            regione: this.estero ? this.repDataIscritto.regione : '',
            id_provincia: this.repDataIscritto.id_provincia,
            id_comune: this.repDataIscritto.id_comune,
            comune: this.estero ? this.repDataIscritto.comune : '',
            cap: this.repDataIscritto.cap,
            fronte_carta: '',
            retro_carta: '',
            precedenti_iscrizioni: this.repDataIscritto.precedenti_iscrizioni ? true : false,          
            precedenti_iscrizioni_text: this.repDataIscritto.precedenti_iscrizioni,
            precedenti_mandati: this.repDataIscritto.precedenti_mandati ? true : false,
            precedenti_mandati_text: this.repDataIscritto.precedenti_mandati,
            flag_no_iscrizione_societa: this.repDataIscritto.iscrizione_societa_segrete,     
            flag_privacy: '',     
            flag_newsletter: this.repDataIscritto.newsletter,
          })
          
        }
  
      })
    })
  }

  onRevoca() {     
    let dialogRef;      

    dialogRef = this.dialog.open(RevocaAreaPersonaleDialogComponent);       
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {        

        this.apiService.deleteIscritto(this.id, this.email).subscribe(data=> {
          this.authService.Logout();
        })

      }
    })    
  }

  setEstero(codice_nazione: string) {

    this.userForm.patchValue({
      id_regione: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.id_regione,
      id_provincia: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.id_provincia,
      id_comune: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.id_comune,
      stato: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.state,
      cap: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.cap,
      regione: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.regione,
      comune: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.comune,
      cell: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.cell,
      cell_fisso: codice_nazione != this.repDataIscritto.nazione ? '' : this.repDataIscritto.cell_fisso
    })

    if (codice_nazione == 'IT') {
      this.estero = false;

      this.userForm.get('id_regione')?.setValidators(Validators.required);
      this.userForm.get('id_provincia')?.setValidators(Validators.required);
      this.userForm.get('id_comune')?.setValidators(Validators.required);
      this.userForm.get('stato')?.clearValidators();      
      this.userForm.get('regione')?.clearValidators();
      this.userForm.get('comune')?.clearValidators();
    } else {
      this.estero = true;

      this.userForm.get('id_regione')?.clearValidators();
      this.userForm.get('id_provincia')?.clearValidators();
      this.userForm.get('id_comune')?.clearValidators();      
      this.userForm.get('stato')?.setValidators(Validators.required);      
      this.userForm.get('regione')?.setValidators(Validators.required);
      this.userForm.get('comune')?.setValidators(Validators.required);      
    }

    this.userForm.get('id_regione')?.updateValueAndValidity();
    this.userForm.get('id_provincia')?.updateValueAndValidity();
    this.userForm.get('id_comune')?.updateValueAndValidity();
    this.userForm.get('stato')?.updateValueAndValidity();
    this.userForm.get('regione')?.updateValueAndValidity();
    this.userForm.get('cap')?.updateValueAndValidity();
    this.userForm.get('comune')?.updateValueAndValidity();
    this.userForm.get('cell')?.updateValueAndValidity();
    this.userForm.get('cell_fisso')?.updateValueAndValidity();
  }

  setRegione(codice_nazione: string) {      
    this.setEstero(codice_nazione);           
    this.apiService.getNazione(codice_nazione).subscribe(data => {      
      this.repData = data;
      if (this.repData) {                
        this.apiService.getRegioni(this.repData.id_nazione).subscribe(data => {                  
          this.regioni = data;          
        })       
      }
    });    
  }

  setProvincie(id_regione: number) {    
    this.apiService.getProvince(id_regione).subscribe(data => {
      this.province = data;
    })
  }

  setComuni(id_provincia: number) {    
    this.apiService.getComuni(id_provincia).subscribe(data => {      
      this.comuni = data;
    })
  }


  saveFronteCarta(e: any) {
    const file: any = (e.target as HTMLInputElement)?.files?.[0];

    if (file) {
      this.userForm.patchValue({
        fronte_carta: file
      });
    
      this.userForm.get('fronte_carta')?.updateValueAndValidity()
    
      const reader = new FileReader();
      reader.onload = () => {
        this.fronteCartaPath = reader.result as string;
      }
      reader.readAsDataURL(file);
    }
  }

  saveRetroCarta(e: any) {
    const file: any = (e.target as HTMLInputElement)?.files?.[0];

    if (file) {
      this.userForm.patchValue({
        retro_carta: file
      });

      this.userForm.get('retro_carta')?.updateValueAndValidity()

      const reader = new FileReader();
      reader.onload = () => {
        this.retroCartaPath = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }
  
  openDialog(type: string) {
    let dialogRef;
    
    this.dialog.closeAll();

    if (type === 'revoca') {
      dialogRef = this.dialog.open(RevocaAreaPersonaleDialogComponent);
    } else if (type === 'modifica') {
      dialogRef = this.dialog.open(ModificaAreaPersonaleDialogComponent);
    } else if (type === 'exit') {
      dialogRef = this.dialog.open(EsciAreaPersonaleDialogComponent);
    } else if (type === 'doc') {
      dialogRef = this.dialog.open(ModificaDocumentiAreaPersonaleComponent);
    } else if (type === 'pass') {
      dialogRef = this.dialog.open(ModificaPasswordAreaPersonaleComponent);
    } else if (type === 'err_doc') {
      dialogRef = this.dialog.open(ModificaDocumentiErrorAreaPersonaleComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }

  onSubmit() {    
    
    let dialogRef;    

    var nome = this.userForm.get('nome')?.value;
    var cognome = this.userForm.get('cognome')?.value;
    var email = this.userForm.get('email')?.value;
    var password = this.userForm.get('password')?.value;
    var indirizzo = this.userForm.get('indirizzo')?.value;
    var professione = this.userForm.get('professione')?.value;
    var cell = this.userForm.get('cell')?.value;
    var cell_fisso = this.userForm.get('cell_fisso')?.value;
    var codice_fiscale = this.userForm.get('codice_fiscale')?.value;
    var data_nascita = this.userForm.get('data_nascita')?.value;
    var nazione = this.userForm.get('nazione')?.value;
    var stato = this.userForm.get('stato')?.value;
    var id_regione = this.userForm.get('id_regione')?.value;
    var regione = this.userForm.get('regione')?.value;
    var id_provincia = this.userForm.get('id_provincia')?.value;
    var id_comune = this.userForm.get('id_comune')?.value;    
    var comune = this.userForm.get('comune')?.value;
    var cap = this.userForm.get('cap')?.value;
    var retro_carta_name = this.userForm.get('retro_carta')?.value.name;          
    var fronte_carta_name = this.userForm.get('fronte_carta')?.value.name;    
    var precedenti_iscrizioni = this.userForm.get('precedenti_iscrizioni')?.value === true ? this.userForm.get('precedenti_iscrizioni_text')?.value : '';
    var precedenti_mandati = this.userForm.get('precedenti_mandati')?.value === true ? this.userForm.get('precedenti_mandati_text')?.value : '';
    var flag_no_iscrizione_societa = this.userForm.get('flag_no_iscrizione_societa')?.value;
    var flag_newsletter = this.userForm.get('flag_newsletter')?.value;    

    var retro_carta_estensione = retro_carta_name? retro_carta_name.split('.').pop() : '';  
    var fronte_carta_estensione = fronte_carta_name? fronte_carta_name.split('.').pop(): '';            

    // Controllo se la password è stata cambiata
    this.apiService.checkPassword(password, email).subscribe(data => {
      this.repDataCheckPassword = data;      

      if (!this.repDataCheckPassword) {        

        // Se è stata modificata chiedo se vuole procedere
        dialogRef = this.dialog.open(ModificaPasswordAreaPersonaleComponent);
        dialogRef?.afterClosed().subscribe((confirmed: boolean) => {          
          if (confirmed) {
            
            // Aggiorno la password
            this.apiService.updatePassword(password, this.id).subscribe(data => {
                  
              // Se sono stati aggiunti dei file procedo
              if (this.retroCartaPath && this.fronteCartaPath) {                                
              
                // Chiedo se vuole modificare i file con quelli aggiunti, in caso di modifica cambio di stato
                dialogRef = this.dialog.open(ModificaDocumentiAreaPersonaleComponent);
                dialogRef?.afterClosed().subscribe((confirmed: boolean) => {          
                  if (confirmed) {   
                    
                    this.dialog.open(LoadingDialogComponent);

                    // Salvo i nuovi documenti
                    this.apiService.saveDocuments(nome, cognome, fronte_carta_estensione, this.fronteCartaPath, retro_carta_estensione, this.retroCartaPath, this.id).subscribe(data => {

                      // Aggiorno i dati relativi ai documenti
                      this.apiService.updateDocumenti(nome, cognome, fronte_carta_estensione, retro_carta_estensione, this.repDataIscritto.stato, this.id).subscribe(data => {                        
                      
                        // Controllo se è stato selezionato estero come nazione
                        if (!this.estero) {
                          // Recupero il nome delle localita selezionate
                          this.apiService.getLocalita(id_regione, id_provincia, id_comune).subscribe(data => { 

                            // Aggiorno il record con i dati modificati
                            this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, null, id_regione, data.regione, id_provincia, data.provincia, id_comune, data.comune, null, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                              this.openDialog('modifica');
                            }, error => {
                              this.openDialog('err');
                            })

                          }, errLoc => {
                            this.openDialog('err');
                          })
                        } else {
                          // Aggiorno il record con i dati modificati
                          this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, stato, null, regione, null, null, null, comune, cap, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                            this.openDialog('modifica');
                          }, error => {
                            this.openDialog('err');
                          })
                        }
                      
                      }, error => {
                        this.openDialog('err');
                      });
                    
                    }, error => {
                      this.openDialog('err');
                    })
                  
                  }
                })
              
                // I documenti devono essere caricati entrambi
              } else if ((this.retroCartaPath && !this.fronteCartaPath) || (!this.retroCartaPath && this.fronteCartaPath)) {                          
                this.openDialog('err_doc');
              } else {

                // Se non sono stati caricati documenti chiedo se vuole procedere con la modifica dei dati                
                dialogRef = this.dialog.open(ModificaConfermaAreaPersonaleComponent);
                dialogRef?.afterClosed().subscribe((confirmed: boolean) => {  
                  if (confirmed) {
                  
                    this.dialog.open(LoadingDialogComponent);

                      // Controllo se è stato selezionato estero come nazione
                      if (!this.estero) {

                        // Recupero il nome delle localita selezionate
                        this.apiService.getLocalita(id_regione, id_provincia, id_comune).subscribe(data => { 

                          // Aggiorno il record con i dati modificati
                          this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, null, id_regione, data.regione, id_provincia, data.provincia, id_comune, data.comune, null, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                            this.openDialog('modifica');
                          }, error => {
                            this.openDialog('err');
                          })
                        }, errLoc => {
                          this.openDialog('err');
                        })
                        
                      } else {
                        // Aggiorno il record con i dati modificati
                        this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, stato, null, regione, null, null, null, comune, cap, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                          this.openDialog('modifica');
                        }, error => {
                          this.openDialog('err');
                        })
                      }                  
                  }
                })
              
              }          
            }, error => {
              this.openDialog('err');
            })  
          }   
        });
        
      } else {

        // Se la password non è stata modificata controllo se sono stati aggiunti i documenti
        if (this.retroCartaPath && this.fronteCartaPath) {                           

          // Chiedo se vuole modificare i file con quelli aggiunti, in caso di modifica cambio di stato
          dialogRef = this.dialog.open(ModificaDocumentiAreaPersonaleComponent);
          dialogRef?.afterClosed().subscribe((confirmed: boolean) => {          
            if (confirmed) {      

              this.dialog.open(LoadingDialogComponent);
              
              // Salvo i nuovi documenti
              this.apiService.saveDocuments(nome, cognome, fronte_carta_estensione, this.fronteCartaPath, retro_carta_estensione, this.retroCartaPath, this.id).subscribe(data => {
                
                // Aggiorno i dati relativi ai documenti
                this.apiService.updateDocumenti(nome, cognome, fronte_carta_estensione, retro_carta_estensione, this.repDataIscritto.stato, this.id).subscribe(data => {                  

                  // Controllo se è stato selezionato estero come nazione
                  if (!this.estero) {

                    // Recupero il nome delle localita selezionate
                    this.apiService.getLocalita(id_regione, id_provincia, id_comune).subscribe(data => {  

                      // Aggiorno il record con i dati modificati
                      this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, null, id_regione, data.regione, id_provincia, data.provincia, id_comune, data.comune, null, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                        this.openDialog('modifica');
                      }, error => {
                        this.openDialog('err');
                      }) 

                    }, errLoc => {
                      this.openDialog('err');
                    })

                  } else {

                    // Aggiorno il record con i dati modificati
                    this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, stato, null, regione, null, null, null, comune, cap, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                      this.openDialog('modifica');
                    }, error => {
                      this.openDialog('err');
                    })
                  }

                }, error => {
                  this.openDialog('err');
                });

              }, error => {
                this.openDialog('err');
              })

            }
          })

          // I documenti devono essere caricati entrambi
        } else if ((this.retroCartaPath && !this.fronteCartaPath) || (!this.retroCartaPath && this.fronteCartaPath)) {
          this.openDialog('err_doc');
        } else {

          // Se non sono stati caricati documenti chiedo se vuole procedere con la modifica dei dati                
          dialogRef = this.dialog.open(ModificaConfermaAreaPersonaleComponent);
          dialogRef?.afterClosed().subscribe((confirmed: boolean) => {  
            if (confirmed) {

              this.dialog.open(LoadingDialogComponent);
            
                // Controllo se è stato selezionato estero come nazione
                if (!this.estero) {

                  // Recupero il nome delle localita selezionate
                  this.apiService.getLocalita(id_regione, id_provincia, id_comune).subscribe(data => { 

                    // Aggiorno il record con i dati modificati
                    this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, null, id_regione, data.regione, id_provincia, data.provincia, id_comune, data.comune, null, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                      this.openDialog('modifica');
                    }, error => {
                      this.openDialog('err');
                    })
                  }, errLoc => {
                    this.openDialog('err');
                  })

                } else {
                  // Aggiorno il record con i dati modificati
                  this.apiService.updateIscritto(nome, cognome, email, cell, cell_fisso, codice_fiscale, data_nascita, indirizzo, professione, nazione, stato, null, regione, null, null, null, comune, cap, precedenti_iscrizioni, precedenti_mandati, flag_no_iscrizione_societa, flag_newsletter, this.id).subscribe(data => {
                    this.openDialog('modifica');
                  }, error => {
                    this.openDialog('err');
                  })
                }   
            
            }
          })

        }
      }
    }, error => {
      this.openDialog('err')
    })          
  }

  onLogout() {
    this.openDialog('exit')
  }

  onRadio(radio: boolean, type: string) {
    var field = type === 'iscrizioni' ? 'precedenti_iscrizioni_text' : 'precedenti_mandati_text';
                
    if(radio) {
      this.userForm.get(field)?.setValidators(Validators.required)
    } else {
      this.userForm.get(field)?.clearValidators();
    }
    
    this.userForm.get(field)?.updateValueAndValidity();
  }

  onConferma() {
    let dialogRef;
    dialogRef = this.dialog.open(ConfermaIscrizioneDialogComponent);
    dialogRef?.afterClosed().subscribe((confirmed: boolean) => {          
      if (confirmed) {
        this.apiBackofficeService.sendEmailConferma(this.repDataIscritto.id, this.repDataIscritto.email, this.repDataIscritto.cell).subscribe(data => {
          
        }, err => {
          this.openDialog('err');
        })
      }
    })
  }

}
