import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-esci-area-personale-dialog',
  templateUrl: './esci-area-personale-dialog.component.html',
  styleUrls: ['./esci-area-personale-dialog.component.css']
})
export class EsciAreaPersonaleDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EsciAreaPersonaleDialogComponent>, private authService: AuthService) {}

  onConfirmClick(): void {
    this.dialogRef.close(true);        
    this.authService.Logout();
  }

  onCloseClick() :void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
      
  }
}
