import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modifica-password-area-personale',
  templateUrl: './modifica-password-area-personale.component.html',
  styleUrls: ['./modifica-password-area-personale.component.css']
})
export class ModificaPasswordAreaPersonaleComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModificaPasswordAreaPersonaleComponent>) { }  

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  onCloseClick() {
    this.dialogRef.close(false);    
  }
}
