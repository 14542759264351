import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../shared/api.service'
import { ErrorDialogComponent } from '../shared/error-dialog/error-dialog.component';
import { SuccessDialogComponent } from '../shared/success-dialog/success-dialog.component';
import { FormResetPasswordDialogComponent } from './form-reset-password-dialog/form-reset-password-dialog.component';

@Component({
  selector: 'app-form-reset-password',
  templateUrl: './form-reset-password.component.html',
  styleUrls: ['./form-reset-password.component.css']
})
export class FormResetPasswordComponent implements OnInit {

  constructor(private titleService: Title, private apiService: ApiService, private dialog: MatDialog, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder ) { 

    this.resetEmailForm = this.formBuilder.group({
      email: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(6)
      ])),
    })

    this.resetForm = this.formBuilder.group({
      password: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])),
      confirm_password: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30),        
      ])),
    }, { 
      validators: this.password.bind(this)
    });
  }

  resetEmailForm!: FormGroup;
  resetForm!: FormGroup;

  fieldTextType!: boolean;
  repeatFieldTextType!: boolean;

  id!: number;    
  email!: string;

  repDataReset! : {code: number};
  repDataCheck! : {id: number};
  
  errorEmail?: string;

  token?: string;

  error_messages = {
    'email': [
      { type: 'required', message: 'Inserire una email per procedere con la richiesta di reset password' },
      { type: 'minlength', message: 'Inserire minimo 6 caratteri.' },      
      { type: 'email', message: 'Inserire una email valida (ex. test@gmail.com)' }
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per procedere con la richiesta di reset' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],
    'confirm_password': [
      { type: 'required', message: 'Inserire una password per procedere con la richiesta di reset' },
      { type: 'minlength', message: 'Inserire minino 6 caratteri.' },
      { type: 'maxlength', message: 'Inserire massimo 30 caratteri' },      
    ],
  }

  ngOnInit(): void {
    this.titleService.setTitle('Alternativa Reset Password');
    
    this.route.queryParams.subscribe(params => {                                
      this.token = params['token'];
      if (this.token) { 
        this.apiService.checkTokenReset(this.token).subscribe(data => {          
          if (!data.id && !data.email) {
            this.router.navigate(['..'], {relativeTo: this.route});
          } else {
            this.id = data.id;
            this.email = data.email;
          }
        })
      }
    })    

  }

  onSubmitEmail() {    
    var email = this.resetEmailForm.get('email')?.value;
    
    this.apiService.sendResetPasswordEmail(email).subscribe(data => {
      this.repDataReset = data;
      if (this.repDataReset) {
        if (this.repDataReset.code === 500) {
          this.errorEmail = 'La email inserita non è collegata a nessun account da noi registrato!';
        } else {
          this.errorEmail = '';
          this.openDialog('email');
          this.resetEmailForm.reset();
        }
      }
    }, err => {
      this.openDialog('err');
    })
  }

  onSubmit() {
    let dialogRef;
    var password = this.resetForm.get('password')?.value;

    this.apiService.updatePassword(password, this.id).subscribe(data => {
      dialogRef = this.dialog.open(SuccessDialogComponent);
      dialogRef?.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.router.navigate(['/']);
        }
      });
    }, err => {
      this.openDialog('err');
    })
  }

  onExit() {
    if (!this.id) {
      this.router.navigate(['..'], {relativeTo: this.route})
    } else {
      this.router.navigate(['/']);
    }
  }

  openDialog(type: string) {
    let dialogRef;
    if (type === 'email') {
      dialogRef = this.dialog.open(FormResetPasswordDialogComponent);
    } else if (type === 'success') {
      dialogRef = this.dialog.open(SuccessDialogComponent);
    } else {
      dialogRef = this.dialog.open(ErrorDialogComponent);
    }    
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
  }

  password(formGroup: FormGroup) {
    const { value: password }: any = formGroup.get('password');
    const { value: confirm_password } : any = formGroup.get('confirm_password');    
    return password === confirm_password ? null : { passwordNotMatch: true };
  }
}
