<div class="container-iscritti">
    <div class="col-xs-12">
        <div class="row">

            <mat-toolbar>
                <mat-toolbar-row>
                    <span style="font-weight: 900;">GESTIONE LOGS</span>
                    <span class="spacer"></span>
                    <button mat-icon-button class="icon" aria-label="icon-button with menu icon">
                        <mat-icon [matMenuTriggerFor]="menuIscritti">menu
                        </mat-icon>
                        <mat-menu #menuIscritti="matMenu">
                            <button mat-menu-item (click)="onExport(exporter)">
                                <mat-icon>import_export</mat-icon>
                                <span>Esporta log</span>
                            </button>
                    </mat-menu>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>

        </div>

        <div class="filtri-log">
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field>
                        <input matInput class="form-field" [formControl]="utenteFilter" placeholder="Cerca Utente">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <mat-label>Cerca Tipo</mat-label>
                        <mat-select [formControl]="tipoFilter">
                            <mat-option [value]="'Attivazione'">Attivazione Iscritto</mat-option>
                            <mat-option [value]="'Eliminazione Documenti'">Eliminazione Documenti</mat-option>
                            <mat-option [value]="'Modifica iscritto'">Modifica Iscritto</mat-option>
                            <mat-option [value]="'Revoca'">Revoca Iscrizione</mat-option>
                            <mat-option [value]="'Nuovo'">Nuovo Utente</mat-option>
                            <mat-option [value]="'Modifica utente'">Modifica Utente</mat-option>
                            <mat-option [value]="'Eliminazione utente'">Eliminazione Utente</mat-option>
                            <mat-option [value]="'Invio comunicazioni email'">Invio email</mat-option>
                            <mat-option [value]="'Invio comunicazioni sms'">Invio sms</mat-option>
                            <mat-option [value]="'Re-invio email'">Re-invio conferma</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field>
                        <input matInput class="form-field" [formControl]="iscrittoFilter" placeholder="Cerca Iscritto">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="buttons row">
            <div class="col-xs-12 text-right">
                <button mat-raised-button color="warn" (click)="onClearFilter()">Pulisci Ricerca</button>
            </div>
        </div>
        <div class="elenco-log">
            <mat-table #table matSort [dataSource]="dataSource" matTableExporter #exporter="matTableExporter">

                <!-- Utente Column -->
                <ng-container matColumnDef="utente">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Utente"> Utente </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="utente"> {{element.utente}} </mat-cell>
                </ng-container>

                <!-- Tipo Column -->
                <ng-container matColumnDef="tipo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Tipo"> Tipo </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="tipo"> {{element.tipo}} </mat-cell>
                </ng-container>

                <!-- Iscritto Column -->
                <ng-container matColumnDef="iscritto">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Iscrito"> Iscritto </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="iscritto"> {{element.iscritto}} </mat-cell>
                </ng-container>

                <!-- Data Column -->
                <ng-container matColumnDef="data">
                    <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Data"> Data </mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="data"> {{element.data | date: 'dd-MM-yyyy HH:mm'}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>
        </div>

    </div>

</div>