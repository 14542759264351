import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthBackofficeService } from 'src/app/shared/auth-backoffice.service';
import { UserBackoffice } from 'src/app/shared/interface';


@Component({
  selector: 'app-login-backoffice',
  templateUrl: './login-backoffice.component.html',
  styleUrls: ['./login-backoffice.component.css']
})
export class LoginBackofficeComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private authBackofficeService: AuthBackofficeService, private titleService: Title) { }

  loginForm!: FormGroup;
  repData? : UserBackoffice;
  success: boolean = true;
  error?: string;

  ngOnInit(): void {

    this.titleService.setTitle('Alternativa Backoffice');

    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    })
  }

  onSubmit() {
    var username = this.loginForm.get('username')?.value;
    var password = this.loginForm.get('password')?.value;

    this.authBackofficeService.login(username, password).subscribe(data => {      
      this.repData = data;      
      if (this.repData!.token) {                                                                       
        this.router.navigate(['homepage'], {relativeTo: this.route});
      } else {        
        this.error= 'Username o Password errati';
      }
    }, errorRes => {      
      this.error = errorRes.message;
    })
  }

}
