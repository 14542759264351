<div class="container-iscritti">
    <div class="col-xs-12">
        <mat-toolbar>
            <mat-toolbar-row>
                <span style="font-weight: 900;">MODIFICA UTENTE</span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="onGoBack()">
                    <mat-icon>backspace</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="edit-utente">
            <div class="row">
                <form [formGroup]="utenteForm" (ngSubmit)="onSubmit()">
                    <div class="col-xs-12 form">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Username</mat-label>
                                    <input matInput placeholder="Ex. Mario" formControlName="username" required>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.username">
                                        <div class="error-message" *ngIf="utenteForm.get('username')?.hasError(error.type) && (utenteForm.get('username')?.dirty || utenteForm.get('username')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" autocomplete>
                                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.password">
                                        <div class="error-message" *ngIf="utenteForm.get('password')?.hasError(error.type) && (utenteForm.get('password')?.dirty || utenteForm.get('password')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo</mat-label>
                                    <mat-select formControlName="tipo" required>
                                        <mat-option [value]="'Operatore'">Operatore</mat-option>
                                        <mat-option [value]="'Coordinatore'">Coordinatore</mat-option>
                                        <mat-option [value]="'Supervisore'">Supervisore</mat-option>
                                        <mat-option [value]="'Amministratore'">Amministratore</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.tipo">
                                        <div class="error-message" *ngIf="utenteForm.get('tipo')?.hasError(error.type) && (utenteForm.get('tipo')?.dirty || utenteForm.get('tipo')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="utenteForm.get('tipo')?.value == 'Coordinatore'">
                            <div class="col-xs-12">
                                <mat-form-field appearance="fill">
                                    <mat-label>Regioni</mat-label>
                                    <mat-select formControlName="regione">
                                        <mat-option [value]="regione.id_regione" *ngFor="let regione of regioni">{{regione.regione}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col xs-12">
                        <div class="button-row text-center">
                            <button mat-raised-button type="submit" color="success" [disabled]="!utenteForm.valid">Modifica i dati</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>