import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-revoca-area-personale-dialog',
  templateUrl: './revoca-area-personale-dialog.component.html',
  styleUrls: ['./revoca-area-personale-dialog.component.css']
})
export class RevocaAreaPersonaleDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RevocaAreaPersonaleDialogComponent>) { }

  ngOnInit(): void {
  }
  onConfirmClick() {
    this.dialogRef.close(true);      
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }

}
