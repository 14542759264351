<div class="container-iscritti">
    <div class="col-xs-12">
        <mat-toolbar>
            <mat-toolbar-row>
                <span style="font-weight: 900;">GESTIONE COMUNICAZIONE </span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="onGoBack()">
                    <mat-icon>backspace</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="comunicazione">
            <div class="row">
                <form [formGroup]="commsForm">
                    <div class="col-xs-12 form">

                        <div class="row">
                            <div class="col-xs-12">
                                <mat-form-field appearance="fill">
                                    <mat-label>Utente</mat-label>
                                    <input matInput formControlName="username" readonly>
                                </mat-form-field>

                            </div>
                        </div>

                        <br>
                        <div class="row">
                            <div class="col-xs-12">
                                <mat-form-field appearance="fill" *ngIf="tipo === 'Email'">
                                    <mat-label>Oggetto Email</mat-label>
                                    <input matInput formControlName="oggetto" readonly>
                                </mat-form-field>
                                <mat-form-field appearance="fill" *ngIf="tipo === 'Sms'">
                                    <mat-label>Messaggio SMS</mat-label>
                                    <textarea matInput formControlName="messaggio" rows="10" readonly></textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <br>

                        <mat-accordion>
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        Destinatari
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="destinatari" (click)="onIscritto(destinatario.id)" *ngFor="let destinatario of destinatari;">{{ destinatario.nome}} {{destinatario.cognome}} - {{ tipo === 'Email' ? destinatario.email : destinatario.cell }}</p>
                            </mat-expansion-panel>
                        </mat-accordion>

                    </div>
                    <div class="col-xs-12" style="padding: 20px 0px;" *ngIf="tipo === 'Email'">
                        <div class="button-row text-center">
                            <button mat-raised-button type="button" color="success" (click)="onHtml()">Apri Email</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>