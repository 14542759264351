import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CheckResponse, CheckToken, CheckTokenConferma, Comune, Iscritto, IscrizioneResponse, Localita, Nazione, Provincia, Regione, SendEmailReset, StatoIscritto, Token, UltimoIscritto, UserResponse } from './interface';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private http: HttpClient) { }
  
  url_database: string = environment.apiHost;

  token = new BehaviorSubject<string | null>(null);  

  setToken() {
    this.http.post<Token>(this.url_database + 'api/token', {name: environment.apiName, password: environment.apiPass}).subscribe(data => {
      this.token.next(data.token);        
      localStorage.setItem('token', data.token);      
    })
  }

  getUserData() {
    var token_fe: string = localStorage.getItem('Auth') || '';
    return this.http.post<UserResponse>(this.url_database + 'api/v1.0/data', {user_token: token_fe});
  }

  getRegioni(nazione: number) {
    return this.http.get<Regione[]>(this.url_database + 'api/v1.0/regioni', { params: {nazione: nazione}});
  }

  getProvince(regione: number) {
    return this.http.get<Provincia[]>(this.url_database + 'api/v1.0/province', { params: {regione: regione}});
  }

  getComuni(provincia: number) {
    return this.http.get<Comune[]>(this.url_database + 'api/v1.0/comuni', { params: {provincia: provincia}});
  }

  getNazione(codice_nazione: string) {
    return this.http.get<Nazione>(this.url_database + 'api/v1.0/nazione', { params: {codice_nazione: codice_nazione }});
  }

  checkEsistenzaUtente(email: string) {
    return this.http.post<CheckResponse>(this.url_database + 'api/v1.0/check_esistenza', {email: email});        
  }

  checkValiditaIscritto(email: string) {
    return this.http.post<CheckResponse>(this.url_database + 'api/v1.0/check_iscritto_valido', {email: email});
  }

  checkPassword(password: string, email: string) {
    return this.http.post<CheckResponse>(this.url_database + 'api/v1.0/check_password', {password: password, email: email});
  }

  saveDocuments(nome: string, cognome: string, fronte_estensione: string, fronte_file: string | undefined, retro_estensione: string, retro_file: string | undefined, id: number) {
    return this.http.post(this.url_database + 'api/v1.0/documenti', {nome: nome, cognome: cognome, fronte_estensione: fronte_estensione, fronte_file: fronte_file, retro_estensione: retro_estensione, retro_file: retro_file, id: id});
  }

  getLocalita(regione: number, provincia: number, comune: number) {
    return this.http.post<Localita>(this.url_database + 'api/v1.0/localita', {regione: regione, provincia: provincia, comune: comune});
  }

  addIscrizione(nome: string, cognome: string, email: string, password: string, cell: string, cell_fisso: string, codice_fiscale: string, data_nascita: string, indirizzo: string, professione: string, nazione: string, stato: string | null, id_regione: number | null, regione: string, id_provincia: number | null, provincia: string | null, id_comune: number | null, comune: string, cap: string | null, fronte_carta: string, retro_carta: string, precedenti_iscrizioni: string, precedenti_mandati: string, flag_no_iscrizione_societa: boolean, flag_newsletter: string ) {    
    return this.http.post<IscrizioneResponse>(this.url_database + 'api/v1.0/iscritto', { nome: nome, cognome: cognome, email: email, password: password, cell: cell, cell_fisso: cell_fisso, codice_fiscale: codice_fiscale, data_nascita: data_nascita ,indirizzo: indirizzo, professione: professione, nazione: nazione, stato: stato, id_regione: id_regione, regione: regione, id_provincia: id_provincia, provincia: provincia, id_comune: id_comune, comune: comune, cap: cap, fronte_carta: fronte_carta, retro_carta: retro_carta, precedenti_iscrizioni: precedenti_iscrizioni,precedenti_mandati: precedenti_mandati, iscrizione_societa: flag_no_iscrizione_societa, newsletter: flag_newsletter});              
  }

  getIscritto(id: number) {
    return this.http.get<Iscritto>(this.url_database + 'api/v1.0/iscritto/' + id);
  }

  deleteIscritto(id: number, email: string) {
    return this.http.delete(this.url_database + 'api/v1.0/iscritto/' + id, {body: {email: email}});
  }

  updateIscritto(nome: string, cognome: string, email: string, cell: string, cell_fisso: string, codice_fiscale: string, data_nascita: string, indirizzo: string, professione: string, nazione: string, stato: string | null, id_regione: number | null, regione: string, id_provincia: number | null, provincia: string | null, id_comune: number | null, comune: string, cap: string | null, precedenti_iscrizioni: string, precedenti_mandati: string, flag_no_iscrizione_societa: boolean, flag_newsletter: string, id: number) {    
    return this.http.put(this.url_database + 'api/v1.0/iscritto/' + id, { nome: nome, cognome: cognome, email: email, cell: cell, cell_fisso: cell_fisso, codice_fiscale: codice_fiscale, data_nascita: data_nascita ,indirizzo: indirizzo, professione: professione, nazione: nazione, stato: stato, id_regione: id_regione, regione: regione, id_provincia: id_provincia, provincia: provincia, id_comune: id_comune , comune: comune, cap: cap, precedenti_iscrizioni: precedenti_iscrizioni, precedenti_mandati: precedenti_mandati, iscrizione_societa: flag_no_iscrizione_societa, newsletter: flag_newsletter});
  }

  updateIscrittoInvalido(nome: string, cognome: string, email: string, password: string, cell: string, cell_fisso: string, codice_fiscale: string, data_nascita: string, indirizzo: string, professione: string, nazione: string, stato: string | null, id_regione: number | null, regione: string, id_provincia: number | null, provincia: string | null, id_comune: number | null, comune: string, cap: string | null, fronte_carta_estensione: string, retro_carta_estensione: string, precedenti_iscrizioni: string, precedenti_mandati: string, flag_no_iscrizione_societa: boolean, flag_newsletter: string, id: number) {    
    return this.http.put(this.url_database + 'api/v1.0/iscritto_scaduto/' + id, { nome: nome, cognome: cognome, email: email, password: password, cell: cell, cell_fisso: cell_fisso, codice_fiscale: codice_fiscale, data_nascita: data_nascita ,indirizzo: indirizzo, professione: professione, nazione: nazione, stato: stato, id_regione: id_regione, regione: regione, id_provincia: id_provincia, provincia: provincia, id_comune: id_comune , comune: comune, cap: cap, fronte_carta: fronte_carta_estensione, retro_carta: retro_carta_estensione, precedenti_iscrizioni: precedenti_iscrizioni, precedenti_mandati: precedenti_mandati, iscrizione_societa: flag_no_iscrizione_societa, newsletter: flag_newsletter});
  }

  updatePassword(password: string, id: number, utente?: boolean) {
    return this.http.put(this.url_database + 'api/v1.0/password/' + id, {password: password, utente: utente});
  }

  updateDocumenti(nome: string, cognome: string, fronte_estensione: string, retro_estensione: string, stato: string, id: number) {
    return this.http.put(this.url_database + 'api/v1.0/documenti/' + id, {nome: nome, cognome: cognome, fronte_carta: fronte_estensione, retro_carta: retro_estensione, stato: stato})
  }

  updateIscrittoConfermato(id: number, email: string, stato: string) {      
    return this.http.put(this.url_database + 'api/v1.0/conferma/' + id, {email: email, stato: stato});
  }

  checkStatoIscritto(email: string, password: string) {    
    return this.http.post<StatoIscritto>(this.url_database + 'api/v1.0/check_stato_iscritto', {email: email, password: password});
  }

  checkEmailIscritto(email: string) {    
    return this.http.post<CheckResponse>(this.url_database + 'api/v1.0/check_email_iscritto', {email: email});
  }

  sendResetPasswordEmail(email: string) {
    return this.http.post<SendEmailReset>(this.url_database + 'api/v1.0/send_email_reset', {email: email});
  }

  checkToken(token_email: string) {
    return this.http.post<CheckTokenConferma>(this.url_database + 'api/check_token', {token_email: token_email});
  }
  checkTokenReset(token_reset: string) {
    return this.http.post<CheckToken>(this.url_database + 'api/check_token_reset', {token_reset: token_reset});
  }

  checkLastIscritto() {
    return this.http.get<UltimoIscritto>(this.url_database + 'api/v1.0/check_last_iscrizione');
  }
}
