import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modifica-documenti-error-area-personale',
  templateUrl: './modifica-documenti-error-area-personale.component.html',
  styleUrls: ['./modifica-documenti-error-area-personale.component.css']
})
export class ModificaDocumentiErrorAreaPersonaleComponent implements OnInit {

  constructor(private dialogRef : MatDialogRef<ModificaDocumentiErrorAreaPersonaleComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

}
