import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiBackofficeService } from 'src/app/shared/api-backoffice.service';
import { Log } from 'src/app/shared/interface';

@Component({
  selector: 'app-logs-backoffice',
  templateUrl: './logs-backoffice.component.html',
  styleUrls: ['./logs-backoffice.component.css']
})
export class LogsBackofficeComponent implements OnInit {

  constructor(private apiBackofficeService: ApiBackofficeService, private router: Router, private route: ActivatedRoute, private titleService: Title) { }

  displayedColumns = ['utente', 'tipo', 'iscritto', 'data'];
  dataSource!: MatTableDataSource<Log>;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;   

  utenteFilter = new FormControl();
  tipoFilter = new FormControl();
  iscrittoFilter = new FormControl();

  id_iscritto?: number;
  id_utente?: number;

  globalFilter = '';

  filteredValues = {
    utente: '',  tipo: '', iscritto: ''
  };

  ngOnInit(): void {
    this.titleService.setTitle('Alternativa Backoffice - Gestione Logs');

    this.route.queryParams.subscribe(params => {
    
      this.id_iscritto = params.iscritto;        
      this.id_utente = params.utente;

      this.apiBackofficeService.getUserData().subscribe(data => {
        if (data.tipo != 'Amministratore') {        
          this.router.navigate(['..'], {relativeTo: this.route});
        }

        this.apiBackofficeService.getLogs(this.id_utente, this.id_iscritto).subscribe(data => {      
          this.dataSource = new MatTableDataSource<Log>(data);
            this.paginator._intl.itemsPerPageLabel = 'Log per pagina';
            this.paginator._intl.nextPageLabel = 'Pagina successiva';
            this.paginator._intl.previousPageLabel = 'Pagina precedente';
            this.dataSource.paginator = this.paginator;   
            this.dataSource.sort = this.sort;                        
        
            this.utenteFilter.valueChanges.subscribe((utenteFlterValue) => {          
              this.filteredValues['utente'] =  utenteFlterValue;
              this.dataSource.filter = JSON.stringify(this.filteredValues);
            });
          
            this.tipoFilter.valueChanges.subscribe((tipoFilterValue) => {
              this.filteredValues['tipo'] = tipoFilterValue;
              this.dataSource.filter = JSON.stringify(this.filteredValues);
            });
          
            this.iscrittoFilter.valueChanges.subscribe((iscrittoFilterValue) => {
              this.filteredValues['iscritto'] = iscrittoFilterValue;
              this.dataSource.filter = JSON.stringify(this.filteredValues);
            });
          
            this.dataSource.filterPredicate = this.customFilterPredicate();
        })
      })

    })    
    
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: Log, filter: string): boolean => {
      var globalMatch = !this.globalFilter;        
      let searchString = JSON.parse(filter);       

      if (this.globalFilter) {                
        globalMatch = data.utente.toString().trim().toLowerCase().indexOf(this.globalFilter.toLowerCase()) !== -1;        
      }          

      if (!globalMatch) {
        return false;
      }
                 
      return data.utente.toString().trim().toLowerCase().indexOf(searchString.utente.toLowerCase()) !== -1 &&
        data.tipo.toString().trim().toLowerCase().indexOf(searchString.tipo.toLowerCase()) !== -1  &&
        data.iscritto?.toString().trim().toLowerCase().indexOf(searchString.iscritto.toLowerCase()) !== -1;
    }
    return myFilterPredicate;
  }

  onGoBack() {
    this.router.navigate(['..'], {relativeTo: this.route});
  }

  onClearFilter() {
    this.dataSource.filter = '';
    this.utenteFilter.setValue('');
    this.tipoFilter.setValue('');
    this.iscrittoFilter.setValue('');
  }

  onExport(exporter: any) {
    exporter.exportTable('xlsx', {fileName:'export_log', sheet: 'Log', Props: {Author: 'Ipsnet'}, columnWidths: [20, 40, 40, 40], })    
  }

}
