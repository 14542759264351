<div class="body">
    <div class="container-iscritti-front">
        <div class="row">
            <div class="modulo-iscrizione col-xs-12">
                <h1>DATI<br/> <span style="color: #ef6c00;">PERSONALI</span></h1>
                <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                    <div class="col-xs-12 form">
                        <div class="verifica" *ngIf="visible">
                            <p class="alert alert-danger">Il tuo account è in attesa di validazione, puoi comunque modificare i tuoi dati in caso di errori/omissioni</p>
                        </div>
                        <div class="conferma_cell" *ngIf="!conferma_cell">
                            <p class="alert alert-danger">Conferma il tuo numero di cellulare<span class="spacer-flex"></span><button type="button" color="warn" mat-raised-button (click)="onConferma()">Conferma</button></p>
                        </div>
                        <p class="desc">Tutti i campi con * sono obbligatori</p>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="nome">* Nome</label>
                                    <input type="text" id="nome" class="form-control" formControlName="nome">
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.nome">
                                        <div class="error-message" *ngIf="userForm.get('nome')?.hasError(error.type) && (userForm.get('nome')?.dirty || userForm.get('nome')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="cognome">* Cognome</label>
                                    <input type="text" id="cognome" class="form-control" formControlName="cognome">
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.cognome">
                                        <div class="error-message" *ngIf="userForm.get('cognome')?.hasError(error.type) && (userForm.get('cognome')?.dirty || userForm.get('cognome')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="row">
                            <div [ngClass]="estero ? 'col-md-12': 'col-md-6'">
                                <div class="form-group">
                                    <label for="nazione">* Nazione</label>
                                    <br>
                                    <mat-form-field appearance="standard" class="material-select">
                                        <mat-select formControlName="nazione" required class="form-control" (valueChange)="setRegione($event)" placeholder="Seleziona una nazione">
                                            <mat-option [value]="" disabled> Seleziona una nazione</mat-option>
                                            <mat-option [value]="'IT'">Italia</mat-option>
                                            <mat-option [value]="'EU'">Estero</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.nazione">
                                        <div class="error-message" *ngIf="userForm.get('nazione')?.hasError(error.type) && (userForm.get('nazione')?.dirty || userForm.get('nazione')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="!estero">
                                <div class="form-group">
                                    <label for="regione">* Regione</label>
                                    <br>
                                    <mat-form-field appearance="standard" class="material-select">
                                        <mat-select formControlName="id_regione" required class="form-control" (valueChange)="setProvincie($event)" placeholder="Seleziona una regione">
                                            <mat-option [value]="" disabled> Seleziona una regione</mat-option>
                                            <mat-option *ngFor="let regione of regioni" [value]="regione.id_regione">{{regione.regione}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.regione">
                                        <div class="error-message" *ngIf="userForm.get('id_regione')?.hasError(error.type) && (userForm.get('id_regione')?.dirty || userForm.get('id_regione')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="estero" *ngIf="estero">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="stato">* Stato</label>
                                        <input type="text" id="stato" class="form-control" formControlName="stato">
                                    </div>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.stato">
                                            <div class="error-message" *ngIf="userForm.get('stato')?.hasError(error.type) && (userForm.get('stato')?.dirty || userForm.get('stato')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="regione">* Regione</label>
                                        <input type="text" id="regione" class="form-control" formControlName="regione">
                                    </div>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.regione">
                                            <div class="error-message" *ngIf="userForm.get('regione')?.hasError(error.type) && (userForm.get('regione')?.dirty || userForm.get('regione')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="comune">* Comune</label>
                                        <input type="text" id="comune" class="form-control" formControlName="comune">
                                    </div>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.comune">
                                            <div class="error-message" *ngIf="userForm.get('comune')?.hasError(error.type) && (userForm.get('comune')?.dirty || userForm.get('comune')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="cap">Cap</label>
                                        <input type="text" id="cap" class="form-control" formControlName="cap">
                                    </div>
                                    <div class="error-messages">
                                        <ng-container *ngFor="let error of error_messages.cap">
                                            <div class="error-message" *ngIf="userForm.get('cap')?.hasError(error.type) && (userForm.get('cap')?.dirty || userForm.get('cap')?.touched)">
                                                <p class="alert alert-danger">{{error.message}}</p>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="!estero">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="province">* Provincia</label>
                                    <br>
                                    <mat-form-field appearance="standard" class="material-select">
                                        <mat-select formControlName="id_provincia" required class="form-control" (valueChange)="setComuni($event)" placeholder="Seleziona una provincia">
                                            <mat-option [value]="" disabled> Seleziona una provincia</mat-option>
                                            <mat-option *ngFor="let provincia of province" [value]="provincia.id_provincia">{{provincia.provincia}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.provincia">
                                        <div class="error-message" *ngIf="userForm.get('id_provincia')?.hasError(error.type) && (userForm.get('id_provincia')?.dirty || userForm.get('id_provincia')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="comune">* Comune</label>
                                    <br>
                                    <mat-form-field appearance="standard" class="material-select">
                                        <mat-select formControlName="id_comune" required class="form-control" placeholder="Seleziona un comune">
                                            <mat-option [value]="" disabled> Seleziona un comune</mat-option>
                                            <mat-option *ngFor="let comune of comuni" [value]="comune.id_comune">{{comune.comune}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.comune">
                                        <div class="error-message" *ngIf="userForm.get('id_comune')?.hasError(error.type) && (userForm.get('id_comune')?.dirty || userForm.get('id_comune')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <br>
                        <br>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="codice_fiscale">* Codice fiscale</label>
                                    <input type="text" id="codice_fiscale" class="form-control" maxlength="16" formControlName="codice_fiscale">
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.codice_fiscale">
                                        <div class="error-message" *ngIf="userForm.get('codice_fiscale')?.hasError(error.type) && (userForm.get('codice_fiscale')?.dirty || userForm.get('codice_fiscale')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="date">* Data di nascita</label>
                                    <input type="date" id="date" class="form-control" formControlName="data_nascita" min="1900-12-31" max="9999-12-31">
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.data_nascita">
                                        <div class="error-message" *ngIf="userForm.get('data_nascita')?.hasError(error.type) && (userForm.get('data_nascita')?.dirty || userForm.get('data_nascita')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="address">* Indirizzo di residenza</label>
                                    <input type="text" id="address" class="form-control" formControlName="indirizzo">
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.indirizzo">
                                        <div class="error-message" *ngIf="userForm.get('indirizzo')?.hasError(error.type) && (userForm.get('indirizzo')?.dirty || userForm.get('indirizzo')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="job">Professione (opzionale)</label>
                                    <input type="text" id="job" class="form-control" formControlName="professione">
                                </div>
                            </div>
                        </div>

                        <br>
                        <br>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="email">* Email</label>
                                    <input type="email" id="email" class="form-control" formControlName="email">
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.email">
                                        <div class="error-message" *ngIf="userForm.get('email')?.hasError(error.type) && (userForm.get('email')?.dirty || userForm.get('email')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="password">* Password</label>
                                <div class="form-group flex-button">
                                    <input [type]="password ? 'text' : 'password'" id="password" class="form-control" formControlName="password" autocomplete>
                                    <button mat-icon-button type="button" (click)="password = !password">
                                        <mat-icon *ngIf="password">visibility_off</mat-icon>
                                        <mat-icon *ngIf="!password">visibility</mat-icon>
                                    </button>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.password">
                                        <div class="error-message" *ngIf="userForm.get('password')?.hasError(error.type) && (userForm.get('password')?.dirty || userForm.get('password')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-width">

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="phone">* Cellulare</label>
                                    <mat-form-field class="material-select" appearance="standard" *ngIf="!estero">
                                        <ngx-mat-intl-tel-input formControlName="cell" [onlyCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="false" name="phone" class="form-control" #phone></ngx-mat-intl-tel-input>
                                    </mat-form-field>
                                    <mat-form-field class="material-select" appearance="standard" *ngIf="estero">
                                        <ngx-mat-intl-tel-input formControlName="cell" [preferredCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="true" name="phone" class="form-control" #phone [searchPlaceholder]="'Cerca'"></ngx-mat-intl-tel-input>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.cell">
                                        <div class="error-message" *ngIf="userForm.get('cell')?.hasError(error.type) && (userForm.get('cell')?.dirty || userForm.get('cell')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="phone2">Numero di telefono fisso (opzionale)</label>
                                    <mat-form-field class="material-select" appearance="standard" *ngIf="!estero">
                                        <ngx-mat-intl-tel-input formControlName="cell_fisso" [onlyCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="false" name="phone" class="form-control" #phone></ngx-mat-intl-tel-input>
                                    </mat-form-field>
                                    <mat-form-field class="material-select" appearance="standard" *ngIf="estero">
                                        <ngx-mat-intl-tel-input formControlName="cell_fisso" [preferredCountries]="['it']" [enablePlaceholder]="true" [enableSearch]="true" name="phone" class="form-control" #phone [searchPlaceholder]="'Cerca'"></ngx-mat-intl-tel-input>
                                    </mat-form-field>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.cell_fisso">
                                        <div class="error-message" *ngIf="userForm.get('cell_fisso')?.hasError(error.type) && (userForm.get('cell_fisso')?.dirty || userForm.get('cell_fisso')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <br>
                    </div>
                    <br>
                    <h2>DOCUMENTI</h2>
                    <div class="col-xs-12 form">
                        <p class="desc">Tutti i campi con * sono obbligatori</p>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="carta_fronte">Fronte documento di Identità (Carta d'identità / Patente di guida / Passaporto)<br>
                                        <span class="desc-carta">(sono accettati formati di tipo immagine, es. .jpg, png, ecc...)</span>
                                    </label>
                                    <input type="file" id="carta_fronte" class="form-control" accept="image/*" (change)="saveFronteCarta($event)">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="carta_retro">Retro documento di Identità (Carta d'identità / Patente di guida / Passaporto)<br>
                                        <span class="desc-carta">(sono accettati formati di tipo immagine, es. .jpg, png, ecc...)</span>
                                    </label>
                                    <input type="file" id="carta_retro" class="form-control" accept="image/*" (change)="saveRetroCarta($event)">
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                        <div class="row">
                            <div [ngClass]="{'col-xs-12': userForm.get('precedenti_iscrizioni')?.value == false || !userForm.get('precedenti_iscrizioni')?.touched, 'col-md-6': userForm.get('precedenti_iscrizioni')?.value == true}">
                                <label>* Sei stato precedentemente iscritto a partiti, movimenti o associazioni politiche?</label>
                                <div class="form-group">
                                    <span class="spacer"></span>
                                    <input type="radio" id="prec_iscrizioni_si" name="precedenti_iscrizioni" [value]="true" formControlName="precedenti_iscrizioni" (click)="onRadio(true, 'iscrizioni')">
                                    <label for="prec_iscrizioni_si" class="radio-label">SI</label>
                                    <span class="spacer"></span>
                                    <input type="radio" id="prec_iscrizioni_no" name="precedenti_iscrizioni" [value]="false" formControlName="precedenti_iscrizioni" (click)="onRadio(false, 'iscrizioni')">
                                    <label for="prec_iscrizioni_no" class="radio-label">NO</label>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="userForm.get('precedenti_iscrizioni')?.value == true">
                                <div class="form-group">
                                    <label for="iscrizioni_partiti">Se sì, quali? (inserisci nel campo sottostante)</label>
                                    <textarea id="iscrizioni_partiti" rows="3" class="form-control" style="resize: none;" formControlName="precedenti_iscrizioni_text"></textarea>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.precedenti_iscrizioni_text">
                                        <div class="error-message" *ngIf="userForm.get('precedenti_iscrizioni_text')?.hasError(error.type) && (userForm.get('precedenti_iscrizioni_text')?.dirty || userForm.get('precedenti_iscrizioni_text')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div [ngClass]="{'col-xs-12': userForm.get('precedenti_mandati')?.value == false || !userForm.get('precedenti_mandati')?.touched, 'col-md-6': userForm.get('precedenti_mandati')?.value == true}">
                                <label>* Hai avuto precedenti mandati elettorali o nomine in assessorati o ministeri?</label>
                                <div class="form-group">
                                    <span class="spacer"></span>
                                    <input type="radio" id="mandati_ele_si" name="precedenti_mandati" formControlName="precedenti_mandati" [value]="true" (click)="onRadio(true, 'mandati')">
                                    <label for="mandati_ele_si" class="radio-label">SI</label>
                                    <span class="spacer"></span>
                                    <input type="radio" id="mandati_ele_no" name="precedenti_mandati" formControlName="precedenti_mandati" [value]="false" (click)="onRadio(false, 'mandati')">
                                    <label for="mandati_ele_no" class="radio-label">NO</label>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="userForm.get('precedenti_mandati')?.value == true">
                                <div class="form-group">
                                    <label for="mandati_elettorali">Se sì, quali? (inserisci nel campo sottostante)</label>
                                    <textarea id="mandati_elettorali" rows="3" class="form-control" style="resize: none;" formControlName="precedenti_mandati_text"></textarea>
                                </div>
                                <div class="error-messages">
                                    <ng-container *ngFor="let error of error_messages.precedenti_mandati_text">
                                        <div class="error-message" *ngIf="userForm.get('precedenti_mandati_text')?.hasError(error.type) && (userForm.get('precedenti_mandati_text')?.dirty || userForm.get('precedenti_mandati_text')?.touched)">
                                            <p class="alert alert-danger">{{error.message}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <input type="checkbox" id="no_associazioni" formControlName="flag_no_iscrizione_societa">
                                    <label for="no_associazioni">* Auto-dichiarazione di non essere iscritto a società od associazioni segrete</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <input type="checkbox" id="policy" formControlName="flag_privacy">
                                    <label for="policy">* Dichiaro di aver letto e compreso la <a href="https://www.alternativa.it/privacy-policy-iscrizioni/" target="_blank">Privacy Policy (clicca qui per leggerla)</a></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="form-group">
                                    <input type="checkbox" id="newsletter" formControlName="flag_newsletter">
                                    <label for="newsletter">Presto il consenso all'invio della Newsletter da parte di Alternativa per rimanere sempre aggiornato sulle attività dell'Associazione!</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 row flex">
                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <div matTooltip="Per procedere con l`iscrizione valorizzare prima i campi obbligatori" [matTooltipDisabled]="userForm.valid" matTooltipClass="custom-tooltip">
                                        <button type="submit" id="modifica_dati" name="modifica_dati" class="alternativa-css" [disabled]="!userForm.valid">MODIFICA DATI</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <button type="button" id="logout" name="logout" class="alternativa-css" (click)="onLogout()">ESCI</button>
                                </div>
                            </div>
                        </div>

                        <div class="button-column">
                            <div class="button-overlay">
                                <div class="button-container">
                                    <button type="button" id="revoca_isc" name="revoca_isc" (click)="onRevoca()" class="alternativa-css">REVOCA ISCRIZIONE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>