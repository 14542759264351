import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-form-send-email-backoffice-dialog',
  templateUrl: './form-send-email-backoffice-dialog.component.html',
  styleUrls: ['./form-send-email-backoffice-dialog.component.css']
})
export class FormSendEmailBackofficeDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<FormSendEmailBackofficeDialogComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true)
  }

  onCloseClick() {
    this.dialogRef.close(false);
  }

}
