<div class="container-iscritti">
    <div class="col-xs-12">
        <mat-toolbar>
            <mat-toolbar-row>
                <span style="font-weight: 900;">GESTIONE DOCUMENTI</span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="onGoBack()">
                    <mat-icon>backspace</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="documenti-iscritto">
            <div class="row">
                <form [formGroup]="docForm">
                    <div class="col-xs-12 form">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nome</mat-label>
                                    <input matInput formControlName="nome" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Cognome</mat-label>
                                    <input matInput formControlName="cognome" readonly>
                                </mat-form-field>

                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Codice fiscale</mat-label>
                                    <input matInput maxlength="16" formControlName="codice_fiscale" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Data di nascita</mat-label>
                                    <input matInput [matDatepicker]="picker" formControlName="data_nascita" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker" disabled></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Indirizzo di residenza</mat-label>
                                    <input matInput formControlName="indirizzo" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Professione</mat-label>
                                    <input matInput formControlName="professione" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nazione</mat-label>
                                    <input matInput formControlName="nazione" readonly>
                                </mat-form-field>

                            </div>
                            <div class="col-md-6" *ngIf="!estero">
                                <mat-form-field appearance="fill">
                                    <mat-label>Regione</mat-label>
                                    <input matInput formControlName="regione" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6" *ngIf="estero">
                                <mat-form-field appearance="fill">
                                    <mat-label>Stato</mat-label>
                                    <input matInput formControlName="stato" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6" *ngIf="!estero">
                                <mat-form-field appearance="fill">
                                    <mat-label>Provincia</mat-label>
                                    <input matInput formControlName="provincia" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4" *ngIf="estero">
                                <mat-form-field appearance="fill">
                                    <mat-label>Regione</mat-label>
                                    <input matInput formControlName="regione" readonly>
                                </mat-form-field>
                            </div>
                            <div [ngClass]="estero ? 'col-md-4' : 'col-md-6'">
                                <mat-form-field appearance="fill">
                                    <mat-label>Comune</mat-label>
                                    <input matInput formControlName="comune" readonly>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4" *ngIf="estero">
                                <mat-form-field appearance="fill">
                                    <mat-label>Cap</mat-label>
                                    <input matInput formControlName="cap" readonly>
                                </mat-form-field>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-xs-12">
                                <mat-tab-group>
                                    <mat-tab label="Fronte documento">
                                        <div class="image" *ngIf="!attivo && fileFronte">
                                            <img [src]="fileFronte" alt="fronte documento d'identità">
                                        </div>
                                        <p *ngIf="attivo || !fileFronte">Nessun file disponibile</p>
                                    </mat-tab>
                                    <mat-tab label="Retro documento">
                                        <div class="image" *ngIf="!attivo && fileRetro">
                                            <img [src]="fileRetro" alt="retro documento d'identità">
                                        </div>
                                        <p *ngIf=" attivo || !fileRetro">Nessun file disponibile</p>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>

                    </div>
                    <div class="col-xs-12" style="padding: 20px 0px;">
                        <div class="button-row text-center">
                            <button mat-raised-button type="button" color="success" [disabled]="attivo" (click)="onAttivazione()">Attiva l'iscrizione</button>
                            <button mat-raised-button type="button" color="warn" (click)="onRevoca()">Revoca l'iscrizione</button>
                            <button mat-raised-button type="button" color="warn" (click)="onEliminaDocs()" [disabled]="!fileFronte || !fileRetro">Elimina documenti</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>