import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-iscritto-dialog',
  templateUrl: './new-iscritto-dialog.component.html',
  styleUrls: ['./new-iscritto-dialog.component.css']
})
export class NewIscrittoDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<NewIscrittoDialogComponent>, private router: Router) {}

  onConfirmClick(): void {
    this.dialogRef.close(true);
    this.router.navigate(['/'])
  }

  ngOnInit(): void {
      
  }
}
